//Auth module
import PrivateRoute from "routes/PrivateRoute";
import OpenRoute from "routes/OpenRoute";

import React, { Component } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
// pages
import Login from "pages/auth/Login";
import Dashboard from "pages/Dashboard";

import Announcements from "pages/announcement/Announcements";
import CreateAnnouncement from "pages/announcement/CreateAnnouncement";

import Employees from "pages/employees/Employees";
import CreateEmployee from "pages/employees/CreateEmployee";
import EditEmployee from "pages/employees/EditEmployee";
import Profile from "pages/auth/Profile";

import Notebooks from "pages/notebook/Notebooks";
import NotebookCreate from "pages/notebook/NotebookCreate";

import Teams from "pages/team/Teams";
import CreateTeam from "pages/team/CreateTeam";
import EditTeam from "pages/team/EditTeam";

import Roles from "pages/roles/Roles";
import CreateRoles from "pages/roles/CreateRoles";
import EditRoles from "pages/roles/EditRoles";

import Leaves from "pages/employees/leaves/Leaves";
import CreateLeaves from "pages/employees/leaves/CreateLeaves";
import EditLeaves from "pages/employees/leaves/EditLeaves";
import LeaveActions from "pages/employees/leaves_action/LeaveActions";

// salary

import SalaryAdvances from "../pages/employees/salary/SalaryAdvances";
import CreateSalaryAdvance from "../pages/employees/salary/CreateSalaryAdvance";
import SalaryAdvanceAction from "../pages/employees/salary_action/SalaryAdvanceAction";

// payroll

import Payrolls from "../pages/payroll/Payrolls";
import PayrollDetails from "../pages/payroll/PayrollDetails";
import CreatePayroll from "../pages/payroll/CreatePayroll";
import EditPayroll from "../pages/payroll/EditPayroll";

import ImportList from "pages/settings/ImportList";
import Instructions from "../pages/settings/Instructions";

function OpenRoutes() {
  return (
    <BrowserRouter>
      <Switch>
        <OpenRoute exact path="/login" component={Login}></OpenRoute>
        <Redirect path="*" to="/login" />
      </Switch>
    </BrowserRouter>
  );
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <PrivateRoute
            exact
            path="/dashboard"
            component={Dashboard}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/announcements/:id?"
            component={Announcements}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/announcements-create"
            component={CreateAnnouncement}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/employees"
            component={Employees}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/employees-create"
            component={CreateEmployee}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/employees-edit/:id?"
            component={EditEmployee}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/notebooks/:id?"
            component={Notebooks}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/notebooks-create"
            component={NotebookCreate}
          ></PrivateRoute>

          <PrivateRoute exact path="/teams" component={Teams}></PrivateRoute>
          <PrivateRoute
            exact
            path="/teams-create"
            component={CreateTeam}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/teams-edit/:id?"
            component={EditTeam}
          ></PrivateRoute>

          <PrivateRoute exact path="/roles" component={Roles}></PrivateRoute>
          <PrivateRoute
            exact
            path="/roles-create"
            component={CreateRoles}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/roles-edit/:id?"
            component={EditRoles}
          ></PrivateRoute>

          <PrivateRoute exact path="/leaves" component={Leaves}></PrivateRoute>
          <PrivateRoute
            exact
            path="/leaves-create"
            component={CreateLeaves}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/leaves-edit/:id?"
            component={EditLeaves}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/leaves-actions/:id?"
            component={LeaveActions}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/salary-advances"
            component={SalaryAdvances}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/salary-advances-create"
            component={CreateSalaryAdvance}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/salary-advances-action"
            component={SalaryAdvanceAction}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/payrolls"
            component={Payrolls}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/payrolls-create"
            component={CreatePayroll}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/payrolls-edit/:id?"
            component={EditPayroll}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/payrolls-details/:id?/:download?"
            component={PayrollDetails}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/import-list"
            component={ImportList}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/how-to-import"
            component={Instructions}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/profile"
            component={Profile}
          ></PrivateRoute>
          <Redirect path="*" to="/dashboard" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
