import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import api from "services/api";
import SlimSelect from "slim-select";
import "slim-select/dist/slimselect.css";
import Spinner from "../../elements/Spinner";
import moment from "moment";

export default function CreateEmployee(props) {
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);

  const [referralBlock, setReferralBlock] = useState(false);

  const [jobTypeModal, setJobTypeModal] = useState(false);

  const [jobTopic, setJobTopic] = useState("");

  const openJobModal = (option) => {
    setJobTopic(option);
    setJobTypeModal(true);
  };
  const closeJobModal = () => {
    setJobTypeModal(false);
  };

  const toggleReferral = () => {
    if (referralBlock) {
      setReferralBlock(false);
    } else {
      setReferralBlock(true);
    }
  };

  // position

  const [positionForm, setPositionForm] = useState({
    code: "",
    title: "",
  });

  const [positionErrors, setPositionErrors] = useState({});

  const positionFormChange = (ev) => {
    setPositionForm({
      ...positionForm,
      [ev.target.name]: ev.target.value,
    });
  };

  const submitPosition = async () => {
    var response = await api.post("/positions-create", positionForm);
    if (response.status === 200 && response.data) {
      getPositions();
      setPositionModal(false);
    } else {
      setPositionErrors(response.data.errors);
    }
  };

  const [positionModal, setPositionModal] = useState(false);
  const closePositionModal = () => {
    setPositionModal(false);
  };

  // working hours

  const [shiftForm, setShiftForm] = useState({
    title: "",
    from_time: "",
    to_time: "",
  });
  const [shiftErrors, setShiftErrors] = useState({});

  const shiftFormChange = (ev) => {
    setShiftForm({
      ...shiftForm,
      [ev.target.name]: ev.target.value,
    });
  };

  const submitShift = async () => {
    var response = await api.post("/shifts-create", shiftForm);
    if (response.status === 200 && response.data) {
      getShifts();
      setWorkingHoursModal(false);
    } else {
      setShiftErrors(response.data.errors);
    }
  };

  const [workingHoursModal, setWorkingHoursModal] = useState(false);
  const closeWorkingHoursModal = () => {
    setWorkingHoursModal(false);
  };

  // retrive data

  const [positions, setPositions] = useState([]);
  const getPositions = async () => {
    var response = await api.get("/positions");
    if (response.status === 200 && response.data) {
      setPositions(response.data);
    }
  };
  const [roles, setRoles] = useState([]);
  const getRoles = async () => {
    var response = await api.get("/roles");
    if (response.status === 200 && response.data) {
      setRoles(response.data);
    }
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const [employees, setEmployees] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(0);
  const getEmployees = async () => {
    var response = await api.post("/employees");
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
      setEmployeeCount(zeroPad(response.data?.data.length + 1, 3));
      // setFormData({
      //   ...formData,
      //   employee_id: zeroPad(response.data?.data.length + 1, 3),
      // });
    }
  };
  const [shifts, setShifts] = useState([]);
  const getShifts = async () => {
    var response = await api.get("/shifts");
    if (response.status === 200 && response.data) {
      setShifts(response.data);
    }
  };

  const [teams, setTeams] = useState([]);
  const getTeams = async () => {
    var response = await api.post("/teams");
    if (response.status === 200 && response.data) {
      setTeams(response.data.data);
    }
  };

  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setCountries(response.data);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };

  useEffect(async () => {
    getPositions();
    getRoles();
    getEmployees();
    getTeams();
    getShifts();
    getCountries();
    getCurrencies();
  }, []);

  const selectRef = useRef(null);
  useEffect(() => {
    if (selectRef.current) {
      new SlimSelect({
        select: selectRef.current,
        placeholder: "Select Report to",
      });
    }
  }, [selectRef]);

  const [formDataSet, setFormDataSet] = useState({
    // personal_info
    full_name: "",
    short_name: "",
    login_id: "",
    password: "",
    nationality: "",
    employee_id: "",
    gender: "",
    date_of_birth: "",
    race: "",
    religion: "",
    marital_status: "",
    ic_num: "",
    ppt_expired: "",

    // contact info
    phone_code: "",
    emergency_person: "",
    emergency_contact: "",
    email: "",
    mobile_number: "",
    house_number: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
    emergency_relation: "",
    emergency_phone_code: "",

    // emploment_info

    date_joined: "",

    role_permission: "",
    report_to: "",
    position: "",
    position_grade: "",
    team: "",
    working_hours: "",
    work_location: "",
    branch_office: "",
    job_status: "",
    job_type: "",

    visa_no: "",
    visa_issue_date: "",
    visa_expired_date: "",
    referral: "",
    own_employee: "",
    referral_name: "",
    referral_by_team: "",
    referral_contact: "",
    job_type_start: "",
    job_type_end: "",
    job_type_remark: "",

    // leave data

    rest_day: "",
    annual_leave: "",
    al_start_from: "",
    al_expired_on: "",
    eligible_start_from: "",
    eligible_expired_on: "",
    flight_allowance: "",
    medical_eligible_start_from: "",
    medical_eligible_expired_on: "",
    flight_allowance_currency: "",
    meals_allowance: "",
    meals_allowance_currency: "",
    medical_allowance: "",
    medical_allowance_currency: "",

    // finance data

    currency: "",
    beneficiary: "",
    bank: "",
    account_number: "",
    basic_salary: "",
    kpi_bonus: "",
  });
  const [errors, setErrors] = useState({});
  const [icCopy, setIcCopy] = useState(null);
  const [pptCopy, setPptCopy] = useState(null);
  const [employeePhoto, setEmployeePhoto] = useState(null);
  const [offerLetter, setOfferLetter] = useState(null);
  const [workingPermit, setWorkingPermit] = useState(null);
  const handleChange = (ev) => {
    var inputName = ev.target.name;

    if (inputName == "icCopy") {
      setIcCopy(ev.target.files[0]);
    } else if (inputName == "pptCopy") {
      setPptCopy(ev.target.files[0]);
    } else if (inputName == "employeePhoto") {
      setEmployeePhoto(ev.target.files[0]);
      handleFileChange(ev);
    } else if (inputName == "offerLetter") {
      setOfferLetter(ev.target.files[0]);
    } else if (inputName == "workingPermit") {
      setWorkingPermit(ev.target.files[0]);
    } else if (inputName == "referral") {
      var checked = ev.target.checked;
      if (checked) {
        setFormDataSet({
          ...formDataSet,
          referral: 1,
        });
      } else {
        setFormDataSet({
          ...formDataSet,
          referral: 0,
        });
      }
    } else if (inputName == "own_employee") {
      var checked = ev.target.checked;
      if (checked) {
        setFormDataSet({
          ...formDataSet,
          own_employee: 1,
        });
      } else {
        setFormDataSet({
          ...formDataSet,
          own_employee: 0,
        });
      }
    } else if (inputName == "nationality") {
      setFormDataSet({
        ...formDataSet,
        employee_id: ev.target.value + employeeCount,
        nationality: ev.target.value,
      });
    } else if (inputName == "job_type") {
      const textValue = ev.target.value;
      if (textValue == "Contract") {
        openJobModal(textValue);
      } else if (textValue == "Probation") {
        openJobModal(textValue);
      } else {
        console.log("JobType", textValue);
      }
      setFormDataSet({
        ...formDataSet,
        job_type: textValue,
      });
    } else {
      setFormDataSet({
        ...formDataSet,
        [ev.target.name]: ev.target.value,
      });
    }
  };
  const validateForm = () => {
    let formErrors = {};
    // personal info
    if (!formDataSet.full_name) {
      formErrors.full_name = "Full Name is required";
    }
    if (!formDataSet.short_name) {
      formErrors.short_name = "Short Name is required";
    }
    if (!formDataSet.login_id) {
      formErrors.login_id = "User ID is required";
    }
    if (!formDataSet.password) {
      formErrors.password = "Password is required";
    }
    if (!formDataSet.nationality) {
      formErrors.nationality = "Nationality is required";
    }
    if (!formDataSet.employee_id) {
      formErrors.employee_id = "Employee ID is required";
    }
    if (!formDataSet.gender) {
      formErrors.gender = "Gender is required";
    }
    if (!formDataSet.date_of_birth) {
      formErrors.date_of_birth = "Date of Birth is required";
    }
    if (!formDataSet.race) {
      formErrors.race = "Race is required";
    }
    if (!formDataSet.religion) {
      formErrors.religion = "Religion is required";
    }
    if (!formDataSet.marital_status) {
      formErrors.marital_status = "Marital Status is required";
    }

    // contact info

    if (!formDataSet.phone_code) {
      formErrors.phone_code = "Phone Code is required";
    }
    if (!formDataSet.mobile_number) {
      formErrors.mobile_number = "Mobile Number is required";
    }

    // employment_data

    if (!formDataSet.date_joined) {
      formErrors.date_joined = "Date Joined is required";
    }

    if (!formDataSet.role_permission) {
      formErrors.role_permission = "Role Permission is required";
    }
    if (!formDataSet.report_to) {
      formErrors.report_to = "Report to is required";
    }
    if (!formDataSet.position) {
      formErrors.position = "Position is required";
    }

    if (!formDataSet.team) {
      formErrors.team = "Team  is required";
    }
    if (!formDataSet.working_hours) {
      formErrors.working_hours = "Working Hours is required";
    }
    if (!formDataSet.work_location) {
      formErrors.work_location = "Work Location to is required";
    }
    if (!formDataSet.branch_office) {
      formErrors.branch_office = "Branch Office is required";
    }
    if (!formDataSet.job_status) {
      formErrors.job_status = "Job Status is required";
    }
    if (!formDataSet.job_type) {
      formErrors.job_type = "Job Type is required";
    }
    if (formDataSet.referral) {
      if (!formDataSet.referral_name) {
        formErrors.referral_name = "Referral name is required";
      }

      // if (!formDataSet.referral_by_team) {
      //   formErrors.referral_by_team = "Referral Team is required";
      // }

      if (!formDataSet.referral_contact) {
        formErrors.referral_contact = "Referral Contact is required";
      }
    }

    // leave data

    if (!formDataSet.rest_day) {
      formErrors.rest_day = "Rest Day is required";
    }
    if (!formDataSet.annual_leave) {
      formErrors.annual_leave = "Annual Leave is required";
    }
    if (!formDataSet.al_start_from) {
      formErrors.al_start_from = "AL Start From is required";
    }
    if (!formDataSet.al_expired_on) {
      formErrors.al_expired_on = "Al Expired On is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const [employeeTab, setEmployeeTab] = useState("personal_info");

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      var data = new FormData();
      // data.append("employeeData", formDataSet);
      data.append("ic_copy", icCopy);
      data.append("ppt_copy", pptCopy);
      data.append("photo", employeePhoto);
      data.append("offer_letter", offerLetter);
      data.append("work_permit", workingPermit);
      console.log("formDataformDataformDataformData", formDataSet);

      {
        Object.keys(formDataSet).map((key) =>
          data.append(key, formDataSet[key])
        );
      }

      console.log(data);
      setSpinner(true);
      var response = await api.post("/employees-create", data);
      if (response.status === 200 && response.data) {
        history.push("/employees");
      } else {
        console.log(response.data.errors);
        setErrors(response.data.errors);
      }
      setSpinner(false);
    }
  };

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <form onSubmit={handleSubmit}>
        <div className="create_page_heading">
          <div className="page_name">Add Employee</div>
          <div className="actions">
            <button type="supmit" className="publish_btn btn btn-success">
              Save
            </button>
            <Link to="/employees" className="btn btn-danger rounded-circle">
              <i className="fal fa-times"></i>
            </Link>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="create_employee_tabs">
            <Link
              className={
                employeeTab === "personal_info"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setEmployeeTab("personal_info")}
            >
              Personal Information
            </Link>
            <Link
              className={
                employeeTab === "contact"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setEmployeeTab("contact")}
            >
              Contact
            </Link>
            <Link
              className={
                employeeTab === "employement"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setEmployeeTab("employement")}
            >
              Employment
            </Link>
            <Link
              className={
                employeeTab === "leave"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setEmployeeTab("leave")}
            >
              Leave & Allowance
            </Link>
            <Link
              className={
                employeeTab === "finance"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setEmployeeTab("finance")}
            >
              Finance
            </Link>
          </div>
          <hr></hr>
          <br />

          {employeeTab === "personal_info" && (
            <div className="personal_data">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Full Name <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="full_name"
                      value={formDataSet.full_name}
                      onChange={handleChange}
                    />
                    {errors.full_name && (
                      <div className="errorMsg">{errors.full_name}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Short Name <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="short_name"
                      value={formDataSet.short_name}
                      onChange={handleChange}
                    />
                    {errors.short_name && (
                      <div className="errorMsg">{errors.short_name}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      User ID <sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="login_id"
                      value={formDataSet.login_id}
                      onChange={handleChange}
                    />
                    {errors.login_id && (
                      <div className="errorMsg">{errors.login_id}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Password <sup>*</sup>
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={formDataSet.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <div className="errorMsg">{errors.password}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Nationality<sup>*</sup>
                    </label>
                    <select
                      className="form-select"
                      name="nationality"
                      value={formDataSet.nationality}
                      onChange={handleChange}
                    >
                      <option value="">Select Nationality</option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.nicename}
                        </option>
                      ))}
                    </select>

                    {errors.nationality && (
                      <div className="errorMsg">{errors.nationality}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Employee ID<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="employee_id"
                      value={formDataSet.employee_id}
                      onChange={handleChange}
                      readOnly
                    />
                    {errors.employee_id && (
                      <div className="errorMsg">{errors.employee_id}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Gender <sup>*</sup>
                    </label>
                    <select
                      className="form-select"
                      name="gender"
                      value={formDataSet.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>

                    {errors.gender && (
                      <div className="errorMsg">{errors.gender}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Date of Birth <sup>*</sup>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date_of_birth"
                      value={formDataSet.date_of_birth}
                      onChange={handleChange}
                    />
                    {errors.date_of_birth && (
                      <div className="errorMsg">{errors.date_of_birth}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Race<sup>*</sup>
                    </label>
                    <select
                      className="form-select"
                      name="race"
                      value={formDataSet.race}
                      onChange={handleChange}
                    >
                      <option value="">Select Race</option>
                      <option value="Chinese">Chinese</option>
                      <option value="India">India</option>
                      <option value="Malay">Malay</option>
                      <option value="Filipino">Filipino</option>
                      <option value="Indonesian">Indonesian</option>
                      <option value="Cambodia">Cambodia</option>
                    </select>

                    {errors.race && (
                      <div className="errorMsg">{errors.race}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Religion<sup>*</sup>
                    </label>
                    <select
                      className="form-select"
                      name="religion"
                      value={formDataSet.religion}
                      onChange={handleChange}
                    >
                      <option value="">Select Religion</option>
                      <option value="Islam">Islam</option>
                      <option value="Hindu">Hindu</option>
                      <option value="Christian">Christian</option>
                      <option value="Buddhist">Buddhist</option>
                      <option value="Others">Others</option>
                    </select>

                    {errors.religion && (
                      <div className="errorMsg">{errors.religion}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Marital Status<sup>*</sup>
                    </label>
                    <select
                      className="form-select"
                      name="marital_status"
                      value={formDataSet.marital_status}
                      onChange={handleChange}
                    >
                      <option value="">Select Marital Status</option>
                      <option value="Married">Married</option>
                      <option value="Single">Single</option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                    </select>

                    {errors.marital_status && (
                      <div className="errorMsg">{errors.marital_status}</div>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <hr></hr>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>IC Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ic_num"
                      value={formDataSet.ic_num}
                      onChange={handleChange}
                      placeholder="Ex. 901022-14-5166"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>IC Copy</label>
                    <input
                      type="file"
                      className="form-control"
                      name="icCopy"
                      value={formDataSet.icCopy}
                      onChange={handleChange}
                    />

                    {/*ToDo: Show image after Choose File*/}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Passport Expired</label>
                    <input
                      type="date"
                      className="form-control"
                      name="ppt_expired"
                      value={formDataSet.ppt_expired}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>PPT Copy</label>
                    <input
                      type="file"
                      className="form-control"
                      name="pptCopy"
                      value={formDataSet.pptCopy}
                      onChange={handleChange}
                    />

                    {/*ToDo: Show image after Choose File*/}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Employee Photo</label>
                    <br />
                    <label for="uploadPhoto">
                      <div className="previw_upload_image">
                        {previewUrl && (
                          <img
                            style={{
                              height: "150px",
                              width: "150px",
                              border: "2px solid red",
                            }}
                            className="rounded-circle"
                            src={previewUrl}
                            alt="Preview"
                          />
                        )}
                        {!previewUrl && (
                          <img
                            style={{
                              height: "150px",
                              width: "150px",
                              border: "2px solid red",
                            }}
                            src={
                              require("../../assets/images/upload.png").default
                            }
                            alt="Preview"
                            className="rounded-circle"
                          />
                        )}
                        <i className="fas fa-camera"></i>
                      </div>
                    </label>
                    <br />
                    <input
                      type="file"
                      hidden
                      id="uploadPhoto"
                      name="employeePhoto"
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {employeeTab === "contact" && (
            <div className="contact_data">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Mobile Number<sup>*</sup>
                    </label>
                    <div className="d-flex">
                      <div>
                        <select
                          style={{ width: "200px", marginRight: "10px" }}
                          className="form-select"
                          name="phone_code"
                          value={formDataSet.phone_code}
                          onChange={handleChange}
                        >
                          <option value="">Phonec Code</option>
                          {countries.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.nicename} +{item.phonecode}
                            </option>
                          ))}
                        </select>

                        {errors.phone_code && (
                          <div className="errorMsg">{errors.phone_code}</div>
                        )}
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="mobile_number"
                        value={formDataSet.mobile_number}
                        onChange={handleChange}
                        placeholder="Number"
                      />
                    </div>

                    {errors.mobile_number && (
                      <div className="errorMsg">{errors.mobile_number}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formDataSet.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>House Number</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="house_number"
                      value={formDataSet.house_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Address</label>
                    <textarea
                      className="form-control"
                      name="address"
                      value={formDataSet.address}
                      onChange={handleChange}
                      placeholder="Address"
                    ></textarea>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>City</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      value={formDataSet.city}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Postcode</label>
                    <input
                      type="text"
                      className="form-control"
                      name="postcode"
                      value={formDataSet.postcode}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Country</label>
                    <select
                      className="form-select"
                      name="country"
                      value={formDataSet.country}
                      onChange={handleChange}
                    >
                      <option value="">Select Country</option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.nicename}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <h3>Emergency Contact</h3>
              <hr></hr>
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="emergency_person"
                      value={formDataSet.emergency_person}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Emergency relation</label>
                    <input
                      type="text"
                      className="form-control"
                      name="emergency_relation"
                      value={formDataSet.emergency_relation}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Mobile Number</label>

                    <div className="d-flex">
                      <select
                        style={{ width: "200px", marginRight: "10px" }}
                        className="form-select"
                        name="emergency_phone_code"
                        value={formDataSet.emergency_phone_code}
                        onChange={handleChange}
                      >
                        <option value="">Phone Code</option>
                        {countries.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.nicename} +{item.phonecode}
                          </option>
                        ))}
                      </select>
                      <input
                        type="number"
                        className="form-control"
                        name="emergency_contact"
                        value={formDataSet.emergency_contact}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {employeeTab === "employement" && (
            <div className="contact_data">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Date Joined<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="date_joined"
                      value={formDataSet.date_joined}
                      onChange={handleChange}
                    />
                    {errors.date_joined && (
                      <div className="errorMsg">{errors.date_joined}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Offer Letter</label>
                    <input
                      type="file"
                      className="form-control"
                      name="offerLetter"
                      value={formDataSet.offerLetter}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Role Permission<sup>*</sup>
                    </label>
                    <select
                      name="role_permission"
                      value={formDataSet.role_permission}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select Role Permission</option>
                      {roles.length > 0 ? (
                        roles.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.title}
                          </option>
                        ))
                      ) : (
                        <option value="0">No roles found</option>
                      )}
                    </select>
                    {errors.role_permission && (
                      <div className="errorMsg">{errors.role_permission}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Report to<sup>*</sup>
                    </label>
                    <select
                      name="report_to"
                      ref={selectRef}
                      defaultValue={formDataSet.report_to}
                      onChange={handleChange}
                      className="form-select"
                      data-placeholder="Select Report to"
                    >
                      <option value="">Select Report to</option>
                      {employees.length > 0 ? (
                        employees.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.full_name}
                          </option>
                        ))
                      ) : (
                        <option value="0">No employees found</option>
                      )}
                    </select>

                    {errors.report_to && (
                      <div className="errorMsg">{errors.report_to}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Position<sup>*</sup>
                    </label>
                    <div className="d-flex">
                      <select
                        name="position"
                        value={formDataSet.position}
                        onChange={handleChange}
                        className="form-select"
                      >
                        <option value="">Select Position</option>
                        {positions.length > 0 ? (
                          positions.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.title}
                            </option>
                          ))
                        ) : (
                          <option value="0">No positions found</option>
                        )}
                      </select>
                      <Link
                        onClick={() => setPositionModal(true)}
                        style={{ width: "100px", marginLeft: "10px" }}
                        className="btn btn-success"
                      >
                        NEW
                      </Link>
                    </div>

                    {errors.position && (
                      <div className="errorMsg">{errors.position}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Position Grade</label>
                    <select
                      name="position_grade"
                      value={formDataSet.position_grade}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select Position Grade</option>
                      <option value="A3 Sales Manager">A3 Sales Manager</option>
                      <option value="M3 Sales Executive">
                        M3 Sales Executive
                      </option>
                      <option value="F1 Officer">F1 Officer</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      By Team<sup>*</sup>
                    </label>
                    <select
                      name="team"
                      value={formDataSet.team}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select by team</option>
                      {teams.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.title}
                        </option>
                      ))}
                    </select>
                    {errors.team && (
                      <div className="errorMsg">{errors.team}</div>
                    )}
                    {/*ToDo: Make option to create team from here.*/}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Working Hours <sup>*</sup>
                    </label>
                    <div className="d-flex">
                      <select
                        name="working_hours"
                        value={formDataSet.working_hours}
                        onChange={handleChange}
                        className="form-select"
                      >
                        <option value="">Select working hours</option>
                        {shifts.length > 0 ? (
                          shifts.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.title}, {item.from_time} - {item.to_time}
                            </option>
                          ))
                        ) : (
                          <option value="0">No shifts found</option>
                        )}
                      </select>
                      <Link
                        onClick={() => setWorkingHoursModal(true)}
                        style={{ width: "100px", marginLeft: "10px" }}
                        className="btn btn-success"
                      >
                        NEW
                      </Link>
                    </div>

                    {errors.working_hours && (
                      <div className="errorMsg">{errors.working_hours}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Working Location<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="work_location"
                      value={formDataSet.work_location}
                      onChange={handleChange}
                    />
                    {errors.work_location && (
                      <div className="errorMsg">{errors.work_location}</div>
                    )}
                    {/*ToDo: Set minimum character */}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Branch Office<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="branch_office"
                      value={formDataSet.branch_office}
                      onChange={handleChange}
                    />
                    {errors.branch_office && (
                      <div className="errorMsg">{errors.branch_office}</div>
                    )}
                    {/*ToDo: Set minimum character */}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Job Status<sup>*</sup>
                    </label>
                    <select
                      name="job_status"
                      value={formDataSet.job_status}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select Job Status</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Active">Active</option>
                    </select>
                    {errors.job_status && (
                      <div className="errorMsg">{errors.job_status}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Job Type<sup>*</sup>
                    </label>
                    <select
                      name="job_type"
                      value={formDataSet.job_type}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select Job Type</option>
                      <option value="Permanent">Permanent</option>
                      <option value="Contract">Contract</option>
                      <option value="Probation">Probation</option>
                    </select>
                    {errors.job_type && (
                      <div className="errorMsg">{errors.job_type}</div>
                    )}
                  </div>
                </div>
              </div>
              <br />
              <hr />

              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Working Permit</label>

                    <input
                      type="file"
                      className="form-control"
                      name="workingPermit"
                      value={formDataSet.workingPermit}
                      onChange={handleChange}
                    />

                    {/* neeed to apply file */}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Visa No</label>
                    <input
                      type="text"
                      className="form-control"
                      name="visa_no"
                      value={formDataSet.visa_no}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Visa Issue Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="visa_issue_date"
                      value={formDataSet.visa_issue_date}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Visa Expired Date</label>
                    <input
                      type="date"
                      className="form-control"
                      name="visa_expired_date"
                      value={formDataSet.visa_expired_date}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <br />
              <br />
              <h3>Referral</h3>
              <hr></hr>
              <div className="row">
                <div className="col-lg-7">
                  <div className="form-group">
                    <div className="custom-control custom-switch">
                      <input
                        onClick={toggleReferral}
                        type="checkbox"
                        class="custom-control-input"
                        id="referral"
                        name="referral"
                        onChange={handleChange}
                      />
                      <label class="custom-control-label" for="referral">
                        With / Without Referral<sup></sup>
                      </label>
                    </div>
                  </div>
                </div>

                {referralBlock && (
                  <>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="own_employee"
                            name="own_employee"
                            onChange={handleChange}
                          />
                          <label
                            class="custom-control-label"
                            for="own_employee"
                          >
                            Nexmax Employee<sup></sup>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <label>
                          Name<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="referral_name"
                          value={formDataSet.referral_name}
                          onChange={handleChange}
                        />
                        {errors.referral_name && (
                          <div className="errorMsg">{errors.referral_name}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <label>
                          By Team<sup></sup>
                        </label>
                        <select
                          name="referral_by_team"
                          value={formDataSet.referral_by_team}
                          onChange={handleChange}
                          className="form-select"
                        >
                          <option value="">Select by team</option>
                          {teams.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.title}
                            </option>
                          ))}
                        </select>
                        {errors.referral_by_team && (
                          <div className="errorMsg">
                            {errors.referral_by_team}
                          </div>
                        )}
                        {/*ToDo: Make option to create team from here.*/}
                      </div>
                    </div>
                    <div className="col-lg-7">
                      <div className="form-group">
                        <label>
                          Contact Number<sup>*</sup>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="referral_contact"
                          value={formDataSet.referral_contact}
                          onChange={handleChange}
                        />

                        {errors.referral_contact && (
                          <div className="errorMsg">
                            {errors.referral_contact}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}

          {employeeTab === "leave" && (
            <div className="contact_data">
              <div className="row">
                <div className="col-lg-8">
                  <div className="form-group">
                    <label>
                      Rest Day<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="rest_day"
                      value={formDataSet.rest_day}
                      onChange={handleChange}
                    />
                    {errors.rest_day && (
                      <div className="errorMsg">{errors.rest_day}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      Annual Leave (AL)<sup>*</sup>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="annual_leave"
                      value={formDataSet.annual_leave}
                      onChange={handleChange}
                    />
                    {errors.annual_leave && (
                      <div className="errorMsg">{errors.annual_leave}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6"></div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      AL Start From<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="al_start_from"
                      value={formDataSet.al_start_from}
                      onChange={handleChange}
                    />
                    {errors.al_start_from && (
                      <div className="errorMsg">{errors.al_start_from}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      AL Expired On<sup>*</sup>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="al_expired_on"
                      value={formDataSet.al_expired_on}
                      onChange={handleChange}
                    />
                    {errors.al_expired_on && (
                      <div className="errorMsg">{errors.al_expired_on}</div>
                    )}
                  </div>
                </div>

                <div className="col-lg-8">
                  <div className="form-group">
                    <label>
                      <b>Flight Allowance</b>
                    </label>

                    <div className="d-flex">
                      <select
                        style={{ width: "200px", marginRight: "10px" }}
                        className="form-select"
                        name="flight_allowance_currency"
                        value={formDataSet.flight_allowance_currency}
                        onChange={handleChange}
                      >
                        <option value="">Select Currency</option>
                        {currencies.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code} ({item.name})
                          </option>
                        ))}
                      </select>

                      <input
                        type="number"
                        className="form-control"
                        name="flight_allowance"
                        value={formDataSet.flight_allowance}
                        onChange={handleChange}
                        placeholder="3900.00"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Eligible Start From</label>
                    <input
                      type="date"
                      className="form-control"
                      name="eligible_start_from"
                      value={formDataSet.eligible_start_from}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Eligible Expired On</label>
                    <input
                      type="date"
                      className="form-control"
                      name="eligible_expired_on"
                      value={formDataSet.eligible_expired_on}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>
                      <b>Meals Allowance</b>
                    </label>

                    <div className="d-flex">
                      <select
                        style={{ width: "200px", marginRight: "10px" }}
                        className="form-select"
                        name="meals_allowance_currency"
                        value={formDataSet.meals_allowance_currency}
                        onChange={handleChange}
                      >
                        <option value="">Select Currency</option>
                        {currencies.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code} ({item.name})
                          </option>
                        ))}
                      </select>

                      <input
                        type="number"
                        className="form-control"
                        name="meals_allowance"
                        value={formDataSet.meals_allowance}
                        onChange={handleChange}
                        placeholder="3900.00"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="form-group">
                    <label>
                      <b>Medical Allowance</b>
                    </label>

                    <div className="d-flex">
                      <select
                        style={{ width: "200px", marginRight: "10px" }}
                        className="form-select"
                        name="medical_allowance_currency"
                        value={formDataSet.medical_allowance_currency}
                        onChange={handleChange}
                      >
                        <option value="">Select Currency</option>
                        {currencies.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code} ({item.name})
                          </option>
                        ))}
                      </select>

                      <input
                        type="number"
                        className="form-control"
                        name="medical_allowance"
                        value={formDataSet.medical_allowance}
                        onChange={handleChange}
                        placeholder="3900.00"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Eligible Start From</label>
                    <input
                      type="date"
                      className="form-control"
                      name="medical_eligible_start_from"
                      value={formDataSet.medical_eligible_start_from}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Eligible Expired On</label>
                    <input
                      type="date"
                      className="form-control"
                      name="medical_eligible_expired_on"
                      value={formDataSet.medical_eligible_expired_on}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {employeeTab === "finance" && (
            <div className="contact_data">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Base Currency</label>
                    <select
                      name="currency"
                      value={formDataSet.currency}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select Currency</option>
                      {currencies.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.code} ({item.name})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Beneficiary Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="beneficiary"
                      value={formDataSet.beneficiary}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Bank</label>
                    <input
                      type="text"
                      className="form-control"
                      name="bank"
                      value={formDataSet.bank}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Account Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="account_number"
                      value={formDataSet.account_number}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Basic Salary</label>
                    <input
                      type="text"
                      className="form-control"
                      name="basic_salary"
                      value={formDataSet.basic_salary}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="form-group">
                    <label>KPI Bonus</label>
                    <input
                      type="text"
                      className="form-control"
                      name="kpi_bonus"
                      value={formDataSet.kpi_bonus}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal show={positionModal} onHide={closePositionModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Position</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Position Code</label>
            <input
              type="text"
              placeholder="Ak2323"
              className="form-control"
              name="code"
              value={positionForm.code}
              required
              onChange={positionFormChange}
            />
            {positionErrors.code && (
              <div className="errorMsg">{positionErrors.code}</div>
            )}
          </div>
          <div className="form-group">
            <label>Position</label>
            <input
              required
              type="text"
              placeholder="Accountant"
              className="form-control"
              name="title"
              value={positionForm.title}
              onChange={positionFormChange}
            />
            {positionErrors.title && (
              <div className="errorMsg">{positionErrors.title}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closePositionModal}>
            Close
          </Button>
          <Button variant="primary" onClick={submitPosition}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={workingHoursModal} onHide={closeWorkingHoursModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Shift</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Shift</label>
            <input
              type="text"
              placeholder="Shift A"
              className="form-control"
              name="title"
              value={shiftForm.title}
              required
              onChange={shiftFormChange}
            />
            {shiftErrors.title && (
              <div className="errorMsg">{shiftErrors.title}</div>
            )}
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>From</label>
              <input
                type="text"
                placeholder="7:00 AM"
                className="form-control"
                name="from_time"
                required
                value={shiftForm.from_time}
                onChange={shiftFormChange}
              />
              {shiftErrors.from_time && (
                <div className="errorMsg">{shiftErrors.from_time}</div>
              )}
            </div>
            <div className="col-md-6">
              <label>TO</label>
              <input
                type="text"
                placeholder="9:00 PM"
                className="form-control"
                name="to_time"
                value={shiftForm.to_time}
                onChange={shiftFormChange}
              />
              {shiftErrors.to_time && (
                <div className="errorMsg">{shiftErrors.to_time}</div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeWorkingHoursModal}>
            Close
          </Button>
          <Button variant="primary" onClick={submitShift}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={jobTypeModal} onHide={closeJobModal}>
        <Modal.Header closeButton>
          <Modal.Title>{jobTopic} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <label>Start Date</label>
              <input
                type="date"
                className="form-control"
                name="job_type_start"
                value={formDataSet.job_type_start}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-6">
              <label>End Date</label>
              <input
                type="date"
                className="form-control"
                name="job_type_end"
                value={formDataSet.job_type_end}
                onChange={handleChange}
              />
            </div>
            <div className="col-md-12">
              <br />
              <label>Remark</label>
              <textarea
                className="form-control"
                name="job_type_remark"
                value={formDataSet.job_type_remark}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeJobModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
