import React, { useState } from "react";
import { Link } from "react-router-dom";
import auth from "services/auth";
import api from "services/api";
import AppContext from "contexts/AppContext";
import Spinner from "elements/Spinner";
const LoginForm = () => {
  const { updateUserObj } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [formData, setFormData] = useState({
    login_id: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const [spinner, setSpinner] = useState(false);

  const validate = () => {
    var valid = true;
    var formErrors = {};
    if (!formData.login_id) {
      formErrors = { ...formErrors, login_id: "Please enter a Username" };
      valid = false;
    }
    if (!formData.password) {
      formErrors = { ...formErrors, password: "Please enter a Password" };
      valid = false;
    }
    setErrors(formErrors);
    return valid;
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [mainErrorMsg, setMainErrorMsg] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (isValid) {
      setSpinner(true);
      var response = await api.post("/login", formData);
      console.log(response)
      if (response.data.status === "success") {
        await auth.login(response.data.user);
        await updateUserObj(response.data.user);
        setIsAuthenticated(auth.isAuthenticated);
      } else {
        setMainErrorMsg(response.data.errorMsg);
      }
      setSpinner(false);
    }
  };
  return (
    <>
      {spinner && <Spinner />}
      <div className="card login_card">
        <div className="author">
          <img
            className="company_logo"
            alt=""
            style={{ height: "200px", width: "200px" }}
            src={require("../../assets/images/logos/sit-logo.png").default}
          />
          <div className="title">Employee Login Panel</div>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Username </label>
            <input
              type="text"
              name="login_id"
              className="form-control"
              placeholder="Username"
              value={formData.login_id}
              onChange={handleChange}
            />
            <div className="errorMsg">{errors.login_id}</div>
          </div>
          <div className="form-group">
            <label>Password </label>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Password"
              value={formData.password}
              onChange={handleChange}
            />
            <div className="errorMsg">{errors.password}</div>
          </div>

          <div className="errorMsg">{mainErrorMsg}</div>
          <br />
          <button
            className="btn btn-success width_100 margin_bottom_15"
            type="submit"
          >
            Login
          </button>

          <div className="form-check">
            <input type="checkbox" className="form-check-input" />
            <label className="form-check-label">Remember Me</label>
          </div>
          <hr />

          <small className="form-text text-muted text-center text-link">
            Forget Password ? Contact Admin
          </small>
        </form>
      </div>
    </>
  );
};

export default LoginForm;
