import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../services/api";
import moment from "moment";
import Spinner from "../../elements/Spinner";
import { Modal, Button } from "react-bootstrap";
import Pagination from "../../elements/Pagination";

export default function Employees(props) {
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);
  const onPageChange = (page) => {
    setPage(page);
  };
  const [paginationData, setPaginationData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  // filtering
  const [filterModal, setFilterModal] = useState(false);
  const openFilterModal = () => {
    setFilterModal(true);
  };
  const closeFilterModal = () => {
    setFilterModal(false);
  };
  const [filterData, setFilterData] = useState({
    status: "",
    team: "",
    nationality: "",
    role: "",
  });
  const filterChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const clearFields = () => {
    setFilterData({
      status: "",
      team: "",
      nationality: "",
      role: "",
    });
  };

  const [employees, setEmployees] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const getEmployees = async () => {
    setSpinner(true);
    var response = await api.post("/employees", {
      page: page,
      perPage: 50,
      team: filterData.team,
      status: filterData.status,
      nationality: filterData.nationality,
      role: filterData.role,
    });

    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
      setPaginationData(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [teams, setTeams] = useState([]);
  const getTeams = async () => {
    var response = await api.post("/teams");
    if (response.status === 200 && response.data) {
      setTeams(response.data.data);
    }
  };

  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setCountries(response.data);
    }
  };
  const [roles, setRoles] = useState([]);
  const getRoles = async () => {
    var response = await api.get("/roles");
    if (response.status === 200 && response.data) {
      setRoles(response.data);
    }
  };

  useEffect(async () => {
    getTeams();
    getCountries();
    getRoles();
  }, []);

  useEffect(async () => {
    getEmployees();
  }, [page]);
  useEffect(async () => {
    getEmployees();
  }, [filterData]);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">Employees</div>
        <div className="actions">
          <input
            type="search"
            onChange={(e) => setSearchValue(e.target.value)}
            // type="text"
            value={searchValue}
            className="form-control"
            placeholder="Search"
          />
          <button onClick={openFilterModal}>
            <FontAwesomeIcon icon={faEllipsisH} />
          </button>
          {props.rolePermission?.Employee?.add_edit ? (
            <Link
              to="/employees-create"
              className="btn btn-success rounded-circle"
            >
              <i className="fal fa-plus"></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="employee_lists">
        <div class="table-responsive">
          <table class="table table-bordered">
            <thead className="bg-dark text-white">
              <tr>
                <th>#</th>
                <th>Staff ID</th>
               
                <th>Team</th>
                <th>Position</th>
                <th>Position Level</th>
                <th>Nickname</th>
                <th>Full Name</th>
                <th>D.O.B</th>
                <th>Currency</th>
                <th>Basic</th>
                <th>PPT D.O.E</th>
                <th>Visa D.O.E</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {searchValue ? (
                <>
                  {employees
                    .filter((item) => {
                      if (!searchValue) return false;
                      const lowerCaseSearchValue = searchValue.toLowerCase();
                      return (
                        item.full_name
                          .toLowerCase()
                          .includes(lowerCaseSearchValue) ||
                        item.short_name
                          .toLowerCase()
                          .includes(lowerCaseSearchValue) ||
                        item.employee_id
                          .toLowerCase()
                          .includes(lowerCaseSearchValue)
                      );
                    })
                    .map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.employee_id}</td>
                       
                        <td>{item.employement_data.team_name}</td>
                        <td>{item.employee_position}</td>
                        <td>{item.employement_data.position_grade}</td>
                        <td>{item.short_name}</td>
                        <td>{item.full_name}</td>
                        <td>
                          {item.date_of_birth
                            ? moment(item.date_of_birth).format("LL")
                            : "N/A"}
                        </td>
                        <td>{item.finance_data.currency}</td>
                        <td>{item.finance_data?.basic_salary}</td>
                        <td>
                          {" "}
                          {item.ppt_expired
                            ? moment(item.ppt_expired).format("LL")
                            : "N/A"}
                        </td>
                        <td>
                          {item.employement_data?.visa_expired_date
                            ? moment(
                                item.employement_data.visa_expired_date
                              ).format("LL")
                            : "N/A"}
                        </td>
                        <td>
                          <>
                            {props.rolePermission?.Employee.add_edit ? (
                              <Link to={"/employees-edit/" + item.id}>
                                <i className="fa fa-pen"></i>
                              </Link>
                            ) : (
                              ""
                            )}
                          </>
                        </td>
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {employees.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item.employee_id}</td>
                     
                      <td>{item.employement_data.team_name}</td>
                      <td>{item.employee_position}</td>
                      <td>{item.employement_data.position_grade}</td>
                      <td>{item.short_name}</td>
                      <td>{item.full_name}</td>
                      <td>
                        {item.date_of_birth
                          ? moment(item.date_of_birth).format("LL")
                          : "N/A"}
                      </td>
                      <td>{item.finance_data.currency}</td>
                      <td>{item.finance_data?.basic_salary}</td>
                      <td>
                        {" "}
                        {item.ppt_expired
                          ? moment(item.ppt_expired).format("LL")
                          : "N/A"}
                      </td>
                      <td>
                        {item.employement_data?.visa_expired_date
                          ? moment(
                              item.employement_data.visa_expired_date
                            ).format("LL")
                          : "N/A"}
                      </td>
                      <td>
                        <Link to={"/employees-edit/" + item.id}>
                          <i className="fa fa-pen"></i>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <br />
      <br />
      <div className="text-center">
        <Pagination data={paginationData} onPageChange={onPageChange} />
      </div>

      <Modal show={filterModal} onHide={closeFilterModal}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Employee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Employement Status</label>
            <select
              onChange={filterChange}
              name="status"
              value={filterData.status}
              className="form-select"
            >
              <option value="">Select Employement Status</option>
              <option value="Inactive">Inactive</option>
              <option value="Active">Active</option>
            </select>
          </div>
          <div className="form-group">
            <label>Team</label>
            <select
              onChange={filterChange}
              value={filterData.team}
              name="team"
              className="form-select"
            >
              <option value="">Select team</option>
              {teams.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Nationality</label>
            <select
              onChange={filterChange}
              value={filterData.nationality}
              name="nationality"
              className="form-select"
            >
              <option value="">Select Nationality</option>
              {countries.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.nicename}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Role Permission</label>
            <select
              onChange={filterChange}
              value={filterData.role}
              name="role"
              className="form-select"
            >
              <option value="">Select Role</option>

              {roles.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={clearFields}>
            Clear
          </Button>
          <Button variant="primary" onClick={closeFilterModal}>
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
