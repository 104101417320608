import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import api from "../../services/api";
import moment from "moment/moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Spinner from "../../elements/Spinner";

export default function EditPayroll() {
  const history = useHistory();
  const params = useParams();

  const generatePdf = () => {
    const input = document.getElementById("pdf_container");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("salary-slip.pdf");
    });
  };

  const PrintPdf = () => {
    const input = document.getElementById("pdf_container");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "pt", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

      // Open the print dialog
      pdf.autoPrint();
      window.open(pdf.output("bloburl"), "_blank");
    });
  };

  const [financeData, setFinanceData] = useState([]);
  const [rows, setRows] = useState([]);
  const [formData, setFormData] = useState({
    user_id: "",
    position: "",
    month: "",
    first_date: "",
    last_date: "",
    currency: "",
    basic_salary: 0,
    over_time: 0,
    over_time_remark: "",
    over_time_remark_two: "",
    rest_day_over_time: 0,
    rest_day_remark: "",
    rest_day_remark_two: "",
    new_year_over_time: 0,
    new_year_remark: "",
    new_year_remark_two: "",
    kpi_bonus: 0,
    food_allowance: 0,
    night_shift: 0,
    insurance: 0,
    hold_basic_salary: 0,
    hold_basic_salary_remark: "",
    unpaid_leave: 0,
    unpaid_leave_remark: "",
    salary_advance: 0,
    mistake: 0,
    mistake_remark: "",
    penalty: 0,
    penalty_remark: "",
  });
  const getSlip = async () => {
    var response = await api.post("/payrolls-show", { id: params.id });
    if (response.status === 200 && response.data) {
      setFormData(response.data.data);
      setRows(response.data.data?.release_currency);
      setFinanceData(response.data.data?.finance_data);
    }
  };

  useEffect(async () => {
    if (params.download) {
      setTimeout(() => {
        generatePdf();
        history.goBack();
      }, 2000); // delay in milliseconds
    }
  }, [params]);

  useEffect(async () => {
    getSlip();
  }, []);
  return (
    <div className="create_edit_page">
      <div className="d-flex gap_10">
        <bvutton className="btn btn-success" onClick={generatePdf}>
          Download
        </bvutton>
        <bvutton className="btn btn-primary" onClick={PrintPdf}>
          Print
        </bvutton>
      </div>

      <div className="salary_slip preview" id="pdf_container">
        <div className="slip_heading">
          <div className="logo_item">
            <img
              className="company_logo"
              alt=""
              style={{ height: "70px", width: "70px" }}
              src={require("../../assets/images/logos/sit-logo.png").default}
            />
            <div className="company_details">
              <h3>Company Name </h3>
              <p>company address </p>
            </div>
          </div>
          <br />

          <h3>Salary Slip</h3>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-6">
                  <ul class="list-group bank_details_preview">
                    <li class="list-group-item">
                      <div className="item_1">
                        <strong>Short Name</strong>
                      </div>
                      <div className="item">{formData.user_short_name}</div>
                    </li>
                    <li class="list-group-item">
                      <div className="item_1">
                        <strong>Full Name</strong>
                      </div>
                      <div className="item">{formData.user_name}</div>
                    </li>
                    <li class="list-group-item">
                      <div className="item_1">
                        <strong>Position</strong>
                      </div>
                      <div className="item">{formData.position_name}</div>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul class="list-group bank_details_preview">
                    <li class="list-group-item">
                      <div className="item_1">
                        <strong>Month</strong>
                      </div>
                      <div className="item">{formData.month}</div>
                    </li>
                    <li class="list-group-item">
                      <div className="item_1">
                        <strong>First Date</strong>
                      </div>
                      <div className="item">
                        {" "}
                        {moment(formData.first_date).format("ll")}
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div className="item_1">
                        <strong>Last Date</strong>
                      </div>
                      <div className="item">
                        {moment(formData.last_date).format("ll")}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div class="card width_100">
              <div class="card-header text-center">Income</div>
              <div class="card-body">
                <div className="data_group">
                  <div className="item_label">Basic Salary</div>
                  <div className="inputs">
                    <select name="currency" value={formData.currency} disabled>
                      <option>{formData.currency}</option>
                    </select>
                    <input
                      disabled
                      value={formData.basic_salary}
                      type="number"
                      className="text-right"
                      placeholder="0.00"
                      name="basic_salary"
                      step=".01"
                    />
                  </div>
                </div>
                <div className="data_group">
                  <div className="item_label">Over Time</div>
                  <div className="inputs">
                    <input
                      disabled
                      name="over_time_remark"
                      value={
                        formData.over_time_remark
                          ? formData.over_time_remark
                          : ""
                      }
                      type="text"
                      placeholder="Remark"
                    />
                    <input
                      disabled
                      name="over_time_remark_two"
                      value={
                        formData.over_time_remark_two
                          ? formData.over_time_remark_two
                          : ""
                      }
                      type="text"
                      placeholder="Remark"
                    />
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="over_time"
                      value={formData.over_time}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">Rest Day Over Time</div>
                  <div className="inputs">
                    <input
                      disabled
                      name="rest_day_remark"
                      value={
                        formData.rest_day_remark ? formData.rest_day_remark : ""
                      }
                      type="text"
                      placeholder="Remark"
                    />
                    <input
                      disabled
                      name="rest_day_remark_two"
                      value={formData.rest_day_remark_two}
                      type="text"
                      placeholder="Remark"
                    />
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="rest_day_over_time"
                      value={formData.rest_day_over_time}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">New Year Over Time</div>
                  <div className="inputs">
                    <input
                      disabled
                      name="new_year_remark"
                      value={formData.new_year_remark}
                      type="text"
                      placeholder="Remark"
                    />
                    <input
                      disabled
                      name="new_year_remark_two"
                      value={formData.new_year_remark_two}
                      type="text"
                      placeholder="Remark"
                    />
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="new_year_over_time"
                      value={formData.new_year_over_time}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">KPI Bonus</div>
                  <div className="inputs">
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="kpi_bonus"
                      value={formData.kpi_bonus}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>
                <h5 className="text-underline text-uppercase">
                  <b>
                    <u>Allowances</u>
                  </b>
                </h5>

                <div className="data_group">
                  <div className="item_label">Food Allowance</div>
                  <div className="inputs">
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="food_allowance"
                      value={formData.food_allowance}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">Night Shift</div>
                  <div className="inputs">
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="night_shift"
                      value={formData.night_shift}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group d-none">
                  <div className="item_label">Insurance</div>
                  <div className="inputs">
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="insurance"
                      value={formData.insurance}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <span>Total Income</span>{" "}
                <input
                  disabled
                  name="total_income"
                  value={formData.total_income}
                  type="number"
                  className="text-right"
                  step=".01"
                />
              </div>
            </div>
          </div>

          <div className="col-6">
            <div class="card width_100">
              <div class="card-header text-center">Deduction</div>
              <div class="card-body padding_botton_auto">
                <div className="data_group">
                  <div className="item_label">Hold Basic Salary</div>
                  <div className="inputs">
                    <input
                      disabled
                      name="hold_basic_salary_remark"
                      value={formData.hold_basic_salary_remark}
                      type="text"
                      placeholder="Remark"
                    />

                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="hold_basic_salary"
                      value={formData.hold_basic_salary}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">Unpaid Leave</div>
                  <div className="inputs">
                    <input
                      disabled
                      name="unpaid_leave_remark"
                      value={formData.unpaid_leave_remark}
                      type="text"
                      placeholder="Remark"
                    />

                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="unpaid_leave"
                      value={formData.unpaid_leave}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">Salary Advance</div>
                  <div className="inputs">
                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="salary_advance"
                      value={formData.salary_advance}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">Mistake</div>
                  <div className="inputs">
                    <input
                      disabled
                      name="mistake_remark"
                      value={formData.mistake_remark}
                      type="text"
                      placeholder="Remark"
                    />

                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="mistake"
                      value={formData.mistake}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>

                <div className="data_group">
                  <div className="item_label">Penalty</div>
                  <div className="inputs">
                    <input
                      disabled
                      name="penalty_remark"
                      value={formData.penalty_remark}
                      type="text"
                      placeholder="Remark"
                    />

                    <input
                      disabled
                      type="number"
                      className="text-right"
                      name="penalty"
                      value={formData.penalty}
                      placeholder="0.00"
                      step=".01"
                    />
                  </div>
                </div>
              </div>
              <div class="card-footer text-right">
                <span>Total Deduction</span>{" "}
                <input
                  disabled
                  value={formData.total_deducation}
                  type="number"
                  className="text-right"
                  step=".01"
                />
              </div>
            </div>
          </div>

          <div className="col-12">
            <div class="card width_100">
              <div class="card-footer text-right">
                <span>Net Salary</span>{" "}
                <select name="currency" disabled>
                  <option>{formData.currency}</option>
                </select>
                <input
                  disabled
                  value={formData.net_salary}
                  type="number"
                  className="text-right"
                  step=".01"
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-6">
            <h3>Payment Details</h3>

            <ul class="list-group bank_details_preview">
              <li class="list-group-item">
                <div className="item_1">
                  <strong>Beneficiary Name</strong>
                </div>
                <div className="item">{financeData.beneficiary}</div>
              </li>
              <li class="list-group-item">
                <div className="item_1">
                  <strong>Bank Name</strong>
                </div>
                <div className="item">{financeData.bank}</div>
              </li>
              <li class="list-group-item">
                <div className="item_1">
                  <strong>Bank Account</strong>
                </div>
                <div className="item">{financeData.account_number}</div>
              </li>
            </ul>
          </div>
          <div className="col-6">
            <div class="card width_100">
              <div class="card-header text-center">
                Release Currency Payment
              </div>
              <div className="card-body">
                <div className="rate_table">
                  <div className="datarow head">
                    <div className="item">Amount</div>
                    <div className="item">Currency / Rate</div>
                    <div className="item">Total</div>
                  </div>
                  {rows.map((row, index) => (
                    <div key={index} className="datarow">
                      <div className="item">
                        <span>{index + 1} </span>
                        <input
                          disabled
                          name="amount"
                          type="number"
                          step=".01"
                          placeholder="0.00"
                          className="text-right"
                          value={row.amount}
                        />
                      </div>
                      <div className="item d-flex">
                        <select disabled value={row.currency} name="currency">
                          <option value="">Select</option>

                          <option>{row.currency}</option>
                        </select>

                        <input
                          disabled
                          name="rate"
                          type="number"
                          step=".01"
                          placeholder="0.00"
                          className="text-right"
                          value={row.rate}
                        />
                      </div>
                      <div className="item">
                        <input
                          disabled
                          name="total"
                          type="number"
                          step=".01"
                          placeholder="0.00"
                          className="text-right"
                          value={row.total}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
