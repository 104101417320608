import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../services/api";
import Spinner from "../../../elements/Spinner";
import moment from "moment";
import Pagination from "../../../elements/Pagination";
import { Modal, Button, Badge } from "react-bootstrap";

export default function LeaveActions(props) {
  const parms = useParams();
  const [activePage, setActivePage] = useState(1);
  const [summery, setSummery] = useState({});

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  // Get the number of days in the current month
  const numDaysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

  const [dayCount, setDayCount] = useState(numDaysInMonth);

  // pagination for pending
  const [pagePending, setPagePending] = useState(1);
  const onPageChangePending = (page) => {
    setPagePending(page);
  };
  const [paginationDataPending, setPaginationDataPending] = useState([]);

  // pagination for all
  const [pageAll, setPageAll] = useState(1);
  const onPageChangeAll = (page) => {
    setPageAll(page);
  };
  const [paginationDataAll, setPaginationDataAll] = useState([]);

  // pagination for attendance

  const [pageAttendance, setPageAttendance] = useState(1);
  const onPageChangeAttendance = (page) => {
    setPageAttendance(page);
  };
  const [paginationDataAttendance, setPaginationDataAttendance] = useState([]);

  // hjghfjghfj
  const [leaveTab, setLeaveTab] = useState("pending_request");
  const [spinner, setSpinner] = useState();
  const [sideNav, setSideNav] = useState(false);
  const [leaveDetails, setLeaveDetails] = useState({});
  const openSideNav = async (id) => {
    setSpinner(true);
    var response = await api.post("/leaves-show", { id: id });
    if (response.status === 200 && response.data) {
      setLeaveDetails(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
    setSideNav(true);
  };
  const closeSideNav = () => {
    setSideNav(false);
  };

  const approveLeave = async (id) => {
    setSpinner(true);
    var response = await api.post("/leaves-approve", { id: id });
    if (response.status === 200 && response.data) {
      getLeaves();
      getPendingLeaves();
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const rejectLeave = async (id) => {
    setSpinner(true);
    var response = await api.post("/leaves-reject", { id: id });
    if (response.status === 200 && response.data) {
      getLeaves();
      getPendingLeaves();
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [leaves, setLeaves] = useState([]);
  const getLeaves = async () => {
    setSpinner(true);
    var response = await api.post("/leaves-all", {
      page: pageAll,
      perPage: 50,
    });

    if (response.status === 200 && response.data) {
      setLeaves(response.data.data);
      setPaginationDataAll(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [pendingLeaves, setPendingLeaves] = useState([]);
  const getPendingLeaves = async () => {
    setSpinner(true);
    var response = await api.post("/leaves-pending", {
      page: pagePending,
      perPage: 50,
    });
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setPendingLeaves(response.data.data);
      setPaginationDataPending(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [todayLeaves, setTodayLeaves] = useState([]);
  const getTodayLeaves = async () => {
    var response = await api.get("/leaves-today");
    if (response.status === 200 && response.data) {
      setTodayLeaves(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  const [activities, setActivities] = useState([]);
  const getActivities = async () => {
    var response = await api.get("/activities");
    if (response.status === 200 && response.data) {
      setActivities(response.data.activities);
    } else {
      console.log(response.data);
    }
  };

  const [activityModal, setActivityModal] = useState(false);
  const openActivityModal = () => {
    setActivityModal(true);
  };
  const closeActivityModal = () => {
    setActivityModal(false);
  };

  // leave Modal
  const [leaveModal, setLeaveModal] = useState(false);

  const openLeaveModal = () => {
    setLeaveModal(true);
  };
  const closeLeaveModal = () => {
    setLeaveModal(false);
  };

  const [employees, setEmployees] = useState([]);
  const getEmployees = async () => {
    setSpinner(true);
    var response = await api.post("/employees");
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [attendance, setAttendance] = useState([]);
  const getAttendance = async () => {
    setSpinner(true);
    var response = await api.post("/attendance-all", {
      employee_id: filterData.employee_id,
      filter_year: filterData.filter_year,
      filter_month: filterData.filter_month,
      page: pageAttendance,
      perPage: 50,
    });
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setAttendance(response.data.attendances);
      setPaginationDataAttendance(response.data.paginationData);
      setSummery(response.data.total_summery);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };
  console.log(summery);

  const [filterData, setFilterData] = useState({
    employee_id: "",
    filter_year: "",
    filter_month: "",
  });

  const handleChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const [modalAttendance, setModalAttendance] = useState([]);
  const [modalSummery, setModalSummery] = useState({});
  const getModalAttendance = async () => {
    setSpinner(true);
    var response = await api.post("/attendance-all-with-leave", {
      filter_year: modalFilterData.filter_year,
      filter_month: modalFilterData.filter_month,
      page: 1,
      perPage: 100,
    });
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setModalAttendance(response.data.attendances);
      setModalSummery(response.data.total_leave_count);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [modalFilterData, setModalFilterData] = useState({
    filter_year: "",
    filter_month: "",
  });

  const handleModalFilterChange = (event) => {
    setModalFilterData({
      ...modalFilterData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(async () => {
    getPendingLeaves();
    getTodayLeaves();
    getActivities();
    getEmployees();
    getAttendance();
  }, []);

  useEffect(async () => {
    getPendingLeaves();
  }, [pagePending]);

  useEffect(async () => {
    getModalAttendance();
  }, [modalFilterData]);


  useEffect(async () => {
    getLeaves();
  }, [pageAll]);

  useEffect(async () => {
    getAttendance();
  }, [pageAttendance]);

  useEffect(async () => {
    getAttendance();
  }, [filterData]);

  useEffect(async () => {
    if (parms.id) {
      openSideNav(parms.id);
    }
  }, [parms]);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">Manage Leave Applications (Actions)</div>
      </div>

      {leaveTab === "pending_request" || leaveTab === "manage_all" ? (
        <div className="row">
          <div className="col-lg-5">
            <section className="activity_card">
              <div className="timeline-header">
                <span> Recent Activities</span>
                <span>
                  <FontAwesomeIcon
                    onClick={openActivityModal}
                    icon={faEllipsisH}
                  />
                </span>
              </div>
              <ul className="timeline">
                {activities.length > 0
                  ? activities.slice(0, 3).map((item, index) => (
                      <li className="timeline-item">
                        <h5 className="fw-bold">
                          {item.user_name} has {item.activity_type}
                        </h5>
                        <p className="text-muted mb-3">
                          {moment(item.created_at).format("LLL")}{" "}
                          <Link to={"/leaves-actions/" + item.leave_id}>
                            Details
                          </Link>
                        </p>
                      </li>
                    ))
                  : "No Activities Here"}
              </ul>
            </section>
          </div>
          <div className="col-lg-7">
            <section className="activity_card">
              <div className="timeline-header">
                <span>Who's on leave today?</span>
                <span>
                  <FontAwesomeIcon
                    onClick={openLeaveModal}
                    icon={faEllipsisH}
                  />
                </span>
              </div>
              <div className="leave_list">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Staff Nick Name</th>
                      <th>Leave Type</th>
                      <th>Leave Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todayLeaves.length > 0
                      ? todayLeaves.map((item, index) => (
                          <tr>
                            <td>{item.user?.full_name}</td>
                            <td
                              className={
                                item.leave_type === "Rest Day"
                                  ? "rd"
                                  : item.leave_type === "Annual Leave"
                                  ? "al"
                                  : item.leave_type === "Medical Leave"
                                  ? "ml"
                                  : item.leave_type === "Unpaid Leave"
                                  ? "ul"
                                  : "el"
                              }
                            >
                              {item.leave_type}
                            </td>
                            <td>{item.num_of_day}</td>
                          </tr>
                        ))
                      : "No Leaves Here"}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Employee</label>
              <select
                className="form-select"
                name="employee_id"
                value={filterData.employee_id}
                onChange={handleChange}
              >
                <option value="">Select Employee</option>
                {employees.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.full_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label>By Month & year</label>
              <div className="row">
                <div className="col-lg-6">
                  <select
                    name="filter_year"
                    value={filterData.filter_year}
                    onChange={handleChange}
                    className="form-select"
                  >
                    <option value="">Select Year</option>
                    {Array.from({ length: 12 }, (_, i) => {
                      const year = new Date().getFullYear() - i;
                      return (
                        <option value={String(year)} key={String(year)}>
                          {year}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-lg-6">
                  <select
                    name="filter_month"
                    onChange={handleChange}
                    value={filterData.filter_month}
                    className="form-select"
                  >
                    <option value="">Select Month</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row attendance_summery">
              {/* <div className="col-4">
                <div className="single_box">
                  <div className="item" style={{ background: "#e6e6e6" }}>
                    {summery.working}
                  </div>
                  <h5 className="text-center">Working Days</h5>
                </div>
              </div> */}
              <div className="col-4">
                <div className="single_box">
                  <div className="item" style={{ background: "#d0cee3" }}>
                    {summery.rest}
                  </div>
                  <h5 className="text-center">Rest Day</h5>
                </div>
              </div>
              <div className="col-4">
                <div className="single_box">
                  <div className="item" style={{ background: "#6d8764" }}>
                    {summery.annual}
                  </div>
                  <h5 className="text-center">Annual Leave</h5>
                </div>
              </div>
              <div className="col-4">
                <div className="single_box">
                  <div className="item" style={{ background: "#d90073" }}>
                    {summery.medical}
                  </div>
                  <h5 className="text-center">Medical Leave</h5>
                </div>
              </div>
              <div className="col-4">
                <div className="single_box">
                  <div className="item" style={{ background: "#b0ddf0" }}>
                    {summery.unpaid}
                  </div>
                  <h5 className="text-center">Unpaid Leave</h5>
                </div>
              </div>
              <div className="col-4">
                <div className="single_box">
                  <div className="item" style={{ background: "#fa6900" }}>
                    {summery.emergency}
                  </div>
                  <h5 className="text-center">Emergency Leave</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <br />
      <br />
      <div className="row">
        <hr></hr>
        <div className="col-lg-8">
          <div className="create_employee_tabs">
            <button
              className={
                leaveTab === "pending_request"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("pending_request")}
            >
              Pending Request
            </button>
            <button
              className={
                leaveTab === "manage_all"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("manage_all")}
            >
              Manage All
            </button>
            <button
              className={
                leaveTab === "attendance_record"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("attendance_record")}
            >
              Attendance Record
            </button>
          </div>
        </div>
      </div>

      {leaveTab === "pending_request" && (
        <>
          <div className="employee_lists">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>#</th>
                    <th>Application Date</th>
                    <th>Employee Full Name</th>
                    <th>Leave Type</th>
                    <th>No of Days Requested</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingLeaves.length > 0
                    ? pendingLeaves.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item.created_at).format("LL")}</td>
                          <td>{item.employee_name}</td>
                          <td>{item.leave_type}</td>
                          <td>{item.num_of_day}</td>
                          <td>{item.reason}</td>
                          <td>{item.status}</td>
                          <td>
                            <button
                              onClick={() => openSideNav(item.id)}
                              className="btn btn-success rounded-circle mr-10"
                            >
                              <i className="fal fa-eye"></i>
                            </button>

                            {props.rolePermission?.Employee?.approved_reject ? (
                              <>
                                <button
                                  onClick={() => approveLeave(item.id)}
                                  className="btn btn-success rounded-circle mr-10"
                                >
                                  <i class="fal fa-check"></i>
                                </button>
                                <button
                                  onClick={() => rejectLeave(item.id)}
                                  className="btn btn-danger rounded-circle "
                                >
                                  <i class="fal fa-times"></i>
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    : "No Pending Leaves Here"}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-center">
            <Pagination
              data={paginationDataPending}
              onPageChange={onPageChangePending}
            />
          </div>
        </>
      )}

      {leaveTab === "manage_all" && (
        <>
          <div className="employee_lists">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>#</th>
                    <th>Application Date</th>
                    <th>Employee Full Name</th>
                    <th>Leave Type</th>
                    <th>No of Days Requested</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Action By</th>
                  </tr>
                </thead>
                <tbody>
                  {leaves.length > 0
                    ? leaves.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item.created_at).format("LL")}</td>
                          <td>{item.employee_name}</td>
                          <td>{item.leave_type}</td>
                          <td>{item.num_of_day}</td>
                          <td>{item.reason}</td>
                          <td>{item.status}</td>
                          <td>{item.action_by_name}</td>
                        </tr>
                      ))
                    : "No Leaves Here"}
                </tbody>
              </table>
            </div>
          </div>
          <div className="text-center">
            <Pagination
              data={paginationDataAll}
              onPageChange={onPageChangeAll}
            />
          </div>
        </>
      )}

      {leaveTab === "attendance_record" && (
        <>
          <div className="employee_lists attendance_record_table">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>Employee Name</th>
                    {(() => {
                      const items = [];

                      for (let i = 1; i <= dayCount; i++) {
                        items.push(<th key={i}>{i}</th>);
                        // <th key={i}>{i}</th>;
                      }
                      return items;
                    })()}
                    {/* <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>26</th>
                    <th>27</th>
                    <th>28</th>
                    <th>29</th>
                    <th>30</th>
                    <th>31</th> */}
                  </tr>
                </thead>
                <tbody>
                  {attendance.map((item, index) => (
                    <tr key={index}>
                      <td>{item.full_name}</td>
                      {item?.monthData.map((leave, index2) => (
                        <td
                          className={
                            leave.type === "Medical Leave"
                              ? "ml"
                              : leave.type === "Rest Day"
                              ? "rd"
                              : leave.type === "Annual Leave"
                              ? "al"
                              : leave.type === "Unpaid Leave"
                              ? "ul"
                              : leave.type === "Emergency Leave"
                              ? "el"
                              : ""
                          }
                          key={index2}
                        >
                          {leave.type === "Medical Leave"
                            ? "ML"
                            : leave.type === "Rest Day"
                            ? "RD"
                            : leave.type === "Annual Leave"
                            ? "AL"
                            : leave.type === "Unpaid Leave"
                            ? "UL"
                            : leave.type === "Emergency Leave"
                            ? "EL"
                            : ""}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-center">
            <Pagination
              data={paginationDataAttendance}
              onPageChange={onPageChangeAttendance}
            />
          </div>
        </>
      )}

      <div className="right_sideNav">
        <div className={sideNav ? "sidenav width_full" : "sidenav"}>
          <div className="create_page_heading">
            <div className="page_name user_area">
              <div className="item">
                <img
                  className="rounded-circle"
                  alt=""
                  src={leaveDetails.user_photo}
                />
              </div>
              <div className="credentials">
                <div className="link-primary">{leaveDetails.user_name}</div>
                <div className="text-muted">
                  {moment(leaveDetails.created_at).format("LL")}
                </div>
              </div>
            </div>
            <div className="actions">
              <Link
                onClick={closeSideNav}
                className="btn btn-danger rounded-circle"
              >
                <i className="fal fa-times"></i>
              </Link>
            </div>
          </div>
          <div className="details_announcement">
            <div className="row">
              <div className="col-lg-6">
                <h5>
                  Leave from:{" "}
                  <span className="text-muted">
                    {moment(leaveDetails.from_time).format("LL")}
                  </span>
                </h5>
              </div>
              <div className="col-lg-6">
                <h5>
                  Leave to:{" "}
                  <span className="text-muted">
                    {moment(leaveDetails.to_time).format("LL")}
                  </span>
                </h5>
              </div>
              <div className="col-lg-6">
                <h5>
                  Nationality:{" "}
                  <span className="text-muted">{leaveDetails.nationality}</span>
                </h5>
              </div>
              <div className="col-lg-6">
                <h5>
                  Leave Type:
                  <span className="text-muted"> {leaveDetails.leave_type}</span>
                </h5>
              </div>
            </div>
            <br />
            <br />
            <hr></hr>

            <p className="text-muted">{leaveDetails.reason}</p>
          </div>
        </div>
      </div>

      <Modal show={activityModal} onHide={closeActivityModal}>
        <Modal.Header closeButton>
          <Modal.Title>Recent Activities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="activity_card">
            <ul className="timeline">
              {activities.length > 0
                ? activities.map((item, index) => (
                    <li className="timeline-item">
                      <h5 className="fw-bold">
                        {item.user_name} has {item.activity_type}
                      </h5>
                      <p className="text-muted mb-3">
                        {moment(item.created_at).format("LLL")}{" "}
                        <Link to={"/leaves-actions/" + item.leave_id}>
                          Details
                        </Link>
                      </p>
                    </li>
                  ))
                : "No Activities found"}
            </ul>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeActivityModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="salary_slip_modal"
        size="xl"
        show={leaveModal}
        onHide={closeLeaveModal}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>By Month & year</label>
                  <div className="row">
                    <div className="col-lg-6">
                      <select
                        name="filter_year"
                        value={modalFilterData.filter_year}
                        onChange={handleModalFilterChange}
                        className="form-select"
                      >
                        <option value="">Select Year</option>
                        {Array.from({ length: 12 }, (_, i) => {
                          const year = new Date().getFullYear() - i;
                          return (
                            <option value={String(year)} key={String(year)}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <select
                        name="filter_month"
                        onChange={handleModalFilterChange}
                        value={modalFilterData.filter_month}
                        className="form-select"
                      >
                        <option value="">Select Month</option>
                        <option value="January">January</option>
                        <option value="February">February</option>
                        <option value="March">March</option>
                        <option value="April">April</option>
                        <option value="May">May</option>
                        <option value="June">June</option>
                        <option value="July">July</option>
                        <option value="August">August</option>
                        <option value="September">September</option>
                        <option value="October">October</option>
                        <option value="November">November</option>
                        <option value="December">December</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-1"></div>
              <div className="col-lg-5">
                <div className="row attendance_summery">
                  <div className="leave_list">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Leave Type</th>
                          <th>Nu. of Employee</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="rd">Rest Day</td>
                          <td>{modalSummery.rest}</td>
                        </tr>
                        <tr>
                          <td className="al">Annual Leave</td>
                          <td>{modalSummery.annual}</td>
                        </tr>
                        <tr>
                          <td className="ml">Medical Leave</td>
                          <td>{modalSummery.medical}</td>
                        </tr>
                        <tr>
                          <td className="ul">Unpaid Leave</td>
                          <td>{modalSummery.unpaid}</td>
                        </tr>
                        <tr>
                          <td className="el">Emergency Leave</td>
                          <td>{modalSummery.emergency}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="employee_lists attendance_record_table">
              <div className="table-responsive">
                <table class="table table-bordered">
                  <thead className="bg-dark text-white">
                    <tr>
                      <th>Employee Name</th>
                      {(() => {
                        const items = [];

                        for (let i = 1; i <= dayCount; i++) {
                          items.push(<th key={i}>{i}</th>);
                        }
                        return items;
                      })()}
                    </tr>
                  </thead>
                  <tbody>
                    {modalAttendance.map((item, index) => (
                      <tr key={index}>
                        <td>{item.full_name}</td>
                        {item?.monthData.map((leave, index2) => (
                          <td
                            className={
                              leave.type === "Medical Leave"
                                ? "ml"
                                : leave.type === "Rest Day"
                                ? "rd"
                                : leave.type === "Annual Leave"
                                ? "al"
                                : leave.type === "Unpaid Leave"
                                ? "ul"
                                : leave.type === "Emergency Leave"
                                ? "el"
                                : ""
                            }
                            key={index2}
                          >
                            {leave.type === "Medical Leave"
                              ? "ML"
                              : leave.type === "Rest Day"
                              ? "RD"
                              : leave.type === "Annual Leave"
                              ? "AL"
                              : leave.type === "Unpaid Leave"
                              ? "UL"
                              : leave.type === "Emergency Leave"
                              ? "EL"
                              : ""}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="text-center">
              <Pagination
                data={paginationDataAttendance}
                onPageChange={onPageChangeAttendance}
              />
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeLeaveModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
