import React, { useState, useEffect, useContext, useRef } from "react";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";

const Header = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const { updateUserObj } = useContext(AppContext);
  const [showMenu, setShowMenu] = useState(false);

  const isAuthenticated = props.isAuthenticated;

  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };
  const notificationCount = props.notificationCount;

  return (
    <>
      <header>
        <section
          className={"header  " + (isAuthenticated ? " authenticated" : "")}
        >
          <div className="header_menu_v1">
            <div className="row align-items-center">
              <div className="col-4 col-sm-4 col-md-5">
                <div className="item d-md-inline-block">
                  <Link to="/dashboard" className="bar_icon">
                    <i className="fa fa-bars"></i>
                    {/* <img
                      className="company_logo"
                      alt=""
                      style={{ height: "50px", width: "50px" }}
                      src={
                        require("../assets/images/logos/sit-logo.png").default
                      }
                    /> */}
                  </Link>
                </div>
                <div className="d-none d-md-inline-block item"></div>
              </div>
              <div className="col-2 col-sm-4 col-md-2"></div>
              <div className="col-6 col-sm-4 col-md-5 text-end">
                <li className="user_menu_hoverable_area">
                  <div className="dropdown">
                    <button className="dropbtn">
                      <div className="user_menu_left">
                        <img alt="" src={props.userData?.profile_picture} />
                      </div>
                      <div className="user_menu_right">
                        <div className="user_name">
                          {props.userData?.short_name}
                        </div>
                        <div className="user_role">{props.userData?.role}</div>
                      </div>
                    </button>
                    <div className="dropdown-content">
                      <>
                        <Link to="/profile">
                          <i className="fa fa-user-o" aria-hidden="true"></i>
                          <span>Profile</span>
                        </Link>

                        <Link onClick={logout}>
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                          <span>Log out</span>
                        </Link>
                      </>
                    </div>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </section>
      </header>
    </>
  );
};

export default Header;
