import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";
import Spinner from "../../elements/Spinner";
import moment from "moment/moment";
import Pagination from "../../elements/Pagination";
export default function Teams(props) {
  const [fullScreen, setFullScreen] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const [page, setPage] = useState(1);
  const onPageChange = (page) => {
    setPage(page);
  };
  const [paginationData, setPaginationData] = useState([]);

  const toggleFullScreen = () => {
    if (fullScreen) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }
  };

  const [sideNav, setSideNav] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [teamDetails, setTeamDetails] = useState({});
  const openSideNav = async (id) => {
    setSpinner(true);
    var response = await api.post("/teams-show", { id: id });
    if (response.status === 200 && response.data) {
      setTeamDetails(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
    setSideNav(true);
  };
  const closeSideNav = () => {
    setSideNav(false);
  };

  const [teams, setTeams] = useState([]);
  const getTeams = async () => {
    setSpinner(true);
    var response = await api.post("/teams", {
      page: page,
      perPage: 10,
    });

    if (response.status === 200 && response.data) {
      setTeams(response.data.data);
      setPaginationData(response.data.paginationData);
      setTotalData(response.data.totalCount);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };
  useEffect(async () => {
    getTeams();
  }, [page]);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">Total: {totalData}</div>
        <div className="actions">
          {props.rolePermission?.Rolepermission?.add_edit ? (
            <Link to="/teams-create" className="btn btn-success rounded-circle">
              <i className="fal fa-plus"></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="announcements_lists">
        {teams.length > 0
          ? teams.map((item, index) => (
              <div key={index} className="single_announcement">
                <div className="item">
                  <img alt="" src={item.cover_photo} />
                </div>
                <div className="item">
                  <h4>{item.title}</h4>
                  <div className="users_group mb-10">
                    {item.employee_list
                      ? item.employee_list.map((value, index2) => (
                          <Link key={index2}>
                            <img
                              className="rounded-circle mr-10 "
                              title={value.employee_name}
                              alt={value.employee_name}
                              src={value.employee_photo}
                              style={{ height: "30px", width: "30px" }}
                            />
                          </Link>
                        ))
                      : "No team member found"}
                  </div>

                  <div className="credentials">
                    <span className="link-primary">
                      Created: {item.user_name}
                    </span>
                    <span className="margin_left_30 text-muted">
                      {moment(item.created_at).format("lll")}
                    </span>
                  </div>
                </div>
                <div className="item text-end">
                  <Link
                    onClick={() => openSideNav(item.id)}
                    className="btn btn-primary rounded-circle mr-10 d-none"
                    title="View"
                  >
                    <i className="fal fa-eye"></i>
                  </Link>
                  {props.rolePermission?.Rolepermission?.add_edit ? (
                    <Link
                      className="btn btn-warning rounded-circle mr-10"
                      to={"/teams-edit/" + item.id}
                      title="Edit"
                    >
                      <i className="fal fa-pen"></i>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))
          : "No Teams Found"}
      </div>
      <br />
      <br />
      <div className="text-center">
        <Pagination data={paginationData} onPageChange={onPageChange} />
      </div>

      <div className="right_sideNav">
        <div
          className={
            sideNav
              ? fullScreen
                ? " sidenav width_100"
                : "sidenav width_full"
              : "sidenav "
          }
        >
          <div className="create_page_heading">
            <div className="page_name user_area">
              <div className="item">
                <img
                  className="rounded-circle"
                  alt=""
                  src={teamDetails?.user_photo}
                />
              </div>
              <div className="credentials">
                <div className="link-primary">{teamDetails.user_name}</div>
                <div className="text-muted">
                  {" "}
                  {moment(teamDetails.created_at).format("lll")}
                </div>
              </div>
            </div>
            <div className="actions">
              <Link
                onClick={toggleFullScreen}
                className="btn btn-success rounded-circle"
              >
                <i
                  className={fullScreen ? "fal fa-compress" : "fal fa-expand"}
                ></i>
              </Link>

              <Link
                onClick={closeSideNav}
                className="btn btn-danger rounded-circle"
              >
                <i className="fal fa-times"></i>
              </Link>
            </div>
          </div>
          <div className="details_announcement">
            <div>
              <img
                className=""
                alt=""
                style={{ height: "150px" }}
                src={teamDetails?.cover_photo}
              />
            </div>

            <br />
            <h3>{teamDetails.title}</h3>
            <div className="attachment_list">
              {teamDetails.employee_list
                ? teamDetails.employee_list.map((value, index) => (
                    <div
                      key={index}
                      className="single_attachment align-items-center"
                    >
                      <div className="item">
                        <img
                          className="rounded-circle"
                          alt=""
                          style={{ height: "60px", width: "60px" }}
                          src={value.employee_photo}
                        />
                      </div>
                      <div className="item">
                        <div className="text-muted">{value.full_name}</div>
                      </div>
                    </div>
                  ))
                : "No team member found"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
