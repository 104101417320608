import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../services/api";
import Spinner from "../../elements/Spinner";
import moment from "moment";
import Pagination from "../../elements/Pagination";

export default function Notebooks(props) {
  const parms = useParams();
  const [fullScreen, setFullScreen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);

  const [sortBy, setShortBy] = useState("az");

  const toggleShortBy = () => {
    if (sortBy === "az") {
      setShortBy("za");
    } else {
      setShortBy("az");
    }
  };

  // sort order

  const [sortOrder, setSortOrder] = useState("newest_to_oldest");

  const toggleSortOrder = () => {
    if (sortOrder === "newest_to_oldest") {
      setSortOrder("oldest_to_newest");
    } else {
      setSortOrder("newest_to_oldest");
    }
  };

  // toggleSortLastFirst

  const [totalData, setTotalData] = useState(0);
  const onPageChange = (page) => {
    setPage(page);
  };
  const [paginationData, setPaginationData] = useState([]);

  const toggleFullScreen = () => {
    if (fullScreen) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }
  };

  const [sideNav, setSideNav] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [notebookDetails, setNotebookDetails] = useState({});
  const openSideNav = async (id) => {
    setSpinner(true);
    var response = await api.post("/notebooks-show", { id: id });
    if (response.status === 200 && response.data) {
      setNotebookDetails(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
    setSideNav(true);
  };
  const closeSideNav = () => {
    setSideNav(false);
  };

  const [notebooks, setNotebooks] = useState([]);
  const getNotebooks = async () => {
    setSpinner(true);
    var response = await api.post("/notebooks", {
      page: page,
      perPage: 10,
      sort_order: sortOrder,
      sort_by: sortBy,
    });
    if (response.status === 200 && response.data) {
      setNotebooks(response.data.data);
      setPaginationData(response.data.paginationData);
      setTotalData(response.data.totalCount);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  useEffect(async () => {
    getNotebooks();
  }, [page, sortBy, sortOrder]);

  useEffect(async () => {
    if (parms.id) {
      openSideNav(parms.id);
    }
  }, [parms]);
  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">
          Total: {totalData}{" "}
          <span
            className="d-none"
            onClick={toggleShortBy}
            style={{ marginLeft: 30, fontSize: 14, cursor: "pointer" }}
          >
            Sort by AZ <i class="fas fa-sort"></i>
          </span>
          <span
            onClick={toggleSortOrder}
            style={{ marginLeft: 30, fontSize: 14, cursor: "pointer" }}
          >
            Sort by 09 <i class="fas fa-sort"></i>
          </span>
        </div>

        <div className="actions">
          {props.rolePermission?.Notebook?.add_edit ? (
            <Link
              to="/notebooks-create"
              className="btn btn-success rounded-circle"
            >
              <i className="fal fa-plus"></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="announcements_lists">
        {notebooks.length > 0
          ? notebooks.map((item, index) => (
              <div key={index} className="single_announcement">
                <div className="item">
                  <img
                    className="rounded-circle"
                    alt=""
                    src={item.user_photo}
                  />
                </div>
                <div className="item">
                  <h4>{item.title} </h4>
                  <div className="text-muted description">
                    Attention to: {item.attention_to_username}
                  </div>
                  <br />

                  <div className="credentials">
                    <span className="link-primary">{item.user_name}</span>
                    <span className="margin_left_30 text-muted">
                      {moment(item.created_at).format("lll")}
                    </span>
                  </div>
                </div>
                <div className="item text-end">
                  <button
                    onClick={() => openSideNav(item.id)}
                    className="btn btn-success rounded-circle mr-10"
                  >
                    <i className="fal fa-eye"></i>
                  </button>
                </div>
              </div>
            ))
          : "No Notebooks Here"}
      </div>
      <br />
      <br />
      <div className="text-center">
        <Pagination data={paginationData} onPageChange={onPageChange} />
      </div>

      <div className="right_sideNav">
        <div
          className={
            sideNav
              ? fullScreen
                ? " sidenav width_100"
                : "sidenav width_full"
              : "sidenav "
          }
        >
          <div className="create_page_heading">
            <div className="page_name user_area">
              <div className="item">
                <img
                  className="rounded-circle"
                  alt=""
                  src={notebookDetails.user_photo}
                />
              </div>
              <div className="credentials">
                <div className="link-primary">{notebookDetails.user_name}</div>
                <div className="text-muted">
                  {moment(notebookDetails.created_at).format("lll")}
                </div>
              </div>
            </div>
            <div className="actions">
              <Link
                onClick={toggleFullScreen}
                className="btn btn-success rounded-circle"
              >
                <i
                  className={fullScreen ? "fal fa-compress" : "fal fa-expand"}
                ></i>
              </Link>

              <Link
                onClick={closeSideNav}
                className="btn btn-danger rounded-circle"
              >
                <i className="fal fa-times"></i>
              </Link>
            </div>
          </div>
          <div className="details_announcement">
            <h3>{notebookDetails.title}</h3>
            <div
              dangerouslySetInnerHTML={{ __html: notebookDetails.message }}
            />

            <div className="attachment_list">
              {notebookDetails &&
              notebookDetails.attachments &&
              notebookDetails.attachments.length > 0
                ? notebookDetails.attachments.map((value, index) => (
                    <div key={index} className="single_attachment">
                      {value.filename.endsWith(".txt") ? (
                        <div className="item">
                          <i class="fal fa-text"></i>
                        </div>
                      ) : value.filename.endsWith(".pdf") ? (
                        <div className="item">
                          <i class="fal fa-file-pdf"></i>
                        </div>
                      ) : value.filename.endsWith(".docx") ? (
                        <div className="item">
                          <i class="fal fa-file-word"></i>
                        </div>
                      ) : value.filename.endsWith(".doc") ? (
                        <div className="item">
                          <i class="fal fa-file-word"></i>
                        </div>
                      ) : value.filename.endsWith(".xls") ? (
                        <div className="item">
                          <i class="fal fa-file-excel"></i>
                        </div>
                      ) : value.filename.endsWith(".png") ? (
                        <div className="item">
                          <i class="fal fa-image"></i>
                        </div>
                      ) : value.filename.endsWith(".jpg") ? (
                        <div className="item">
                          <i class="fal fa-image"></i>
                        </div>
                      ) : value.filename.endsWith(".jpeg") ? (
                        <div className="item">
                          <i class="fal fa-image"></i>
                        </div>
                      ) : value.filename.endsWith(".gif") ? (
                        <div className="item">
                          <i class="fal fa-image"></i>
                        </div>
                      ) : (
                        <div className="item">
                          <i class="fal fa-file"></i>
                        </div>
                      )}
                      <a target="_blank" href={value.file_source} download>
                        <div className="item">
                          <div className="text-muted">{value.filename}</div>
                          <div className="text-muted">200 kb</div>
                        </div>
                      </a>
                    </div>
                  ))
                : "No Attachment Here"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
