import React, { useState, useEffect } from "react";
import auth from "services/auth";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Spinner from "elements/Spinner";
import swal from "sweetalert";
import api from "../services/api";
import DashboardMenu from "elements/DashboardMenu";

import { useLocation } from "react-router-dom";
const FullLayout = ({ children, ...rest }) => {
  const location = useLocation();
  const pathname = location.pathname.slice(1);

  const [rolePermission, setRolePermission] = useState({});

  const getRolePermission = async () => {
    var response = await api.get("/get_role_permission");
    if (response.status === 200 && response.data) {
      setRolePermission(response.data.data);
    } else {
      console.log(response.data.data);
    }
  };

  const [spinner, setSpinner] = useState(false);

  const showAlert = (title, message, isError, callback) => {
    swal({
      title: title,
      text: message,
      icon: isError ? "warning" : "success",
    }).then((value) => {
      if (callback) {
        callback(value);
      }
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userData, setUserData] = useState(auth.getUser);
  console.log("UserData", userData);
  const passableParams = {
    showAlert,
    isAuthenticated,
    setSpinner,
    spinner,
    userData,
    rolePermission,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  useEffect(async () => {
    if (isAuthenticated) {
      getRolePermission();
    }
  }, []);

  return (
    <>
      {spinner && <Spinner />}

      {pathname === "login" ? (
        <main>{childrenWithProps}</main>
      ) : (
        <div className="home">
          <div className="leftSide">
            <DashboardMenu {...passableParams} />
          </div>
          <div className="RightSide">
            <Header {...passableParams} />
            <div className="all_pages">
              <main>{childrenWithProps}</main>
            </div>
          </div>
        </div>
      )}

      <ScrollTo />
    </>
  );
};

export default FullLayout;
