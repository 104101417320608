import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../services/api";
import moment from "moment";
import Spinner from "../../elements/Spinner";
import Pagination from "../../elements/Pagination";
import Select from "react-select";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";

export default function Payrolls(props) {
  const [activeStep, setActiveStep] = useState(1);

  const history = useHistory();
  const [activePage, setActivePage] = useState(1);
  const [page, setPage] = useState(1);
  const onPageChange = (page) => {
    setPage(page);
  };
  const [paginationData, setPaginationData] = useState([]);
  const [deleteId, setDeleteId] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const closeModal = () => {
    setDeleteModal(false);
  };
  const openModal = (id) => {
    setDeleteId(id);
    setDeleteModal(true);
  };
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [filterData, setFilterData] = useState({
    team: "",
    nationality: "",
    currency: "",
    month: "",
    year: "",
    first_date: "",
    last_date: "",
  });
  const filterChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const [employees, setEmployees] = useState([]);
  const [spinner, setSpinner] = useState(false);

  const getEmployees = async () => {
    setSpinner(true);
    var response = await api.post("/employees");
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };
  const [teams, setTeams] = useState([]);
  const getTeams = async () => {
    var response = await api.post("/teams");
    if (response.status === 200 && response.data) {
      setTeams(response.data.data);
    }
  };

  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setCountries(response.data);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };

  const [slips, setSlips] = useState([]);
  const getSlips = async () => {
    setSpinner(true);
    var response = await api.post("/payrolls", {
      page: page,
      perPage: 50,
      employee_id: selectedEmployee,
      team: filterData.team,
      nationality: filterData.nationality,
      currency: filterData.currency,
      month: filterData.month,
      year: filterData.year,
    });
    if (response.status === 200 && response.data) {
      setSlips(response.data.data);
      setPaginationData(response.data.paginationData);
    }
    setSpinner(false);
  };

  const deleteSlip = async () => {
    setSpinner(true);
    var response = await api.post("/payrolls-delete", { id: deleteId });
    if (response.status === 200 && response.data) {
      getSlips();
    }
    setDeleteModal(false);
    setDeleteId(0);
    setSpinner(false);
  };

  //
  const [fullScreen, setFullScreen] = useState(false);
  const toggleFullScreen = () => {
    if (fullScreen) {
      setFullScreen(false);
    } else {
      setFullScreen(true);
    }
  };
  const [sideNav, setSideNav] = useState(false);

  const [slipDetails, setSlipDetails] = useState({});

  const openSideNav = async (id) => {
    setSpinner(true);
    var response = await api.post("/payrolls-show", { id: id });
    if (response.status === 200 && response.data) {
      setSlipDetails(response.data.data);
    }
    setSpinner(false);
    setSideNav(true);
  };
  const closeSideNav = () => {
    setSideNav(false);
  };

  useEffect(async () => {
    getTeams();
    getCountries();
    getCurrencies();
    getEmployees();
    getPositions();
  }, []);

  useEffect(async () => {
    getSlips();
  }, [page, filterData, selectedEmployee]);

  useEffect(async () => {
    getIds();
  }, [page, filterData, selectedEmployee]);

  const selectOptions = employees.map((employee) => ({
    value: employee.id,
    label: employee.short_name,
  }));
  const employeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
  };

  // create payroll list
  const [ids, setIds] = useState([]);
  console.log("IDS", ids);
  const getIds = async () => {
    setSpinner(true);
    var response = await api.post("/employees", {
      page: page,
      perPage: 500,
      team: filterData.team,
      nationality: filterData.nationality,
      currency: filterData.currency,
      employee_id: selectedEmployee,
    });
    if (response.status === 200 && response.data) {
      const idList = response.data.data.map((employee) => employee.id);
      setIds(idList);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [formDataSet, setFormDataSet] = useState({
    month: "",
    first_date: "",
    last_date: "",
    currency: "",
    basic_salary: "",
    over_time: "0.00",
    over_time_remark: "",
    over_time_remark_two: "",
    rest_day_over_time: "0.00",
    rest_day_remark: "",
    rest_day_remark_two: "",
    new_year_over_time: "0.00",
    new_year_remark: "",
    new_year_remark_two: "",
    kpi_bonus: "0.00",
    food_allowance: "0.00",
    night_shift: "0.00",
    insurance: "0.00",
    hold_basic_salary: "0.00",
    hold_basic_salary_remark: "",
    unpaid_leave: "0.00",
    unpaid_leave_remark: "",
    salary_advance: "0.00",
    mistake: "0.00",
    mistake_remark: "",
    penalty: "0.00",
    penalty_remark: "",
    total_income: "0.00",
    total_deducation: "0.00",
    net_salary: "0.00",
  });

  const [initialRow, setInitialRow] = useState([
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
  ]);
  const [newErrors, setNewErrors] = useState({});
  const validateFormDataSet = () => {
    let newErrors = {};
    if (!filterData.year) {
      newErrors.year = "Year is required";
    }
    if (!filterData.month) {
      newErrors.month = "Month is required";
    }
    if (!filterData.first_date) {
      newErrors.first_date = "Start Date is required";
    }

    if (!filterData.last_date) {
      newErrors.last_date = "End Date is required";
    }
    setNewErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //one shot oparetion
  const [primaryList, setPrimaryList] = useState([]);
  const handleInputChange = (index, field, value) => {
    // Clone the primaryList array to avoid mutating the state directly
    const updatedList = [...primaryList];

    // Remove unwanted characters from the input value
    const sanitizedValue = value.replace(/[^0-9.-]/g, "");

    // Convert the sanitized value to a number
    const parsedValue = parseFloat(sanitizedValue);

    // Update the field value for the specified row
    updatedList[index][field] = isNaN(parsedValue) ? 0 : parsedValue;

    // Perform the calculation
    const item = updatedList[index];
    const totalIncome =
      parseFloat(item.basic_salary) +
      parseFloat(item.over_time) +
      parseFloat(item.rest_day_over_time) +
      parseFloat(item.new_year_over_time) +
      parseFloat(item.kpi_bonus) +
      parseFloat(item.food_allowance) +
      parseFloat(item.night_shift);

    const totalDeduction =
      parseFloat(item.hold_basic_salary) +
      parseFloat(item.unpaid_leave) +
      parseFloat(item.salary_advance) +
      parseFloat(item.mistake) +
      parseFloat(item.penalty);

    const netSalary = totalIncome - totalDeduction;

    // Update the calculated values for the specified row
    updatedList[index].total_income = totalIncome.toFixed(2);
    updatedList[index].total_deducation = totalDeduction.toFixed(2);
    updatedList[index].net_salary = netSalary.toFixed(2);

    // Update the state with the modified list
    setPrimaryList(updatedList);
  };

  const finalizeSlips = async () => {
    setSpinner(true);
    const data = new FormData();
    data.append("primary_list", JSON.stringify(primaryList));
    var response = await api.post("/payrolls-finalize", data);
    if (response.data.status == "success") {
      window.location.reload(false);
      setActiveStep(1);
    } else {
      setErrors(response.data.errors);
    }
    setSpinner(false);
  };

  console.log("PrimaryList", primaryList);
  // end here

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (ids.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Empty Lists",
        text: "There is no employees inside your selection.",
      });
      return;
    } else {
      if (validateFormDataSet()) {
        setSpinner(true);
        const data = new FormData();
        data.append("selected_ids", ids);
        data.append("month", filterData.month);
        data.append("first_date", filterData.first_date);
        data.append("last_date", filterData.last_date);
        data.append("currency", formDataSet.currency);
        data.append("basic_salary", formDataSet.basic_salary);
        data.append("over_time", formDataSet.over_time);
        data.append("over_time_remark", formDataSet.over_time_remark);
        data.append("over_time_remark_two", formDataSet.over_time_remark_two);
        data.append("rest_day_over_time", formDataSet.rest_day_over_time);
        data.append("rest_day_remark", formDataSet.rest_day_remark);
        data.append("rest_day_remark_two", formDataSet.rest_day_remark_two);
        data.append("new_year_over_time", formDataSet.new_year_over_time);
        data.append("new_year_remark", formDataSet.new_year_remark);
        data.append("new_year_remark_two", formDataSet.new_year_remark_two);
        data.append("kpi_bonus", formDataSet.kpi_bonus);
        data.append("food_allowance", formDataSet.food_allowance);
        data.append("night_shift", formDataSet.night_shift);
        data.append("insurance", formDataSet.insurance);
        data.append("hold_basic_salary", formDataSet.hold_basic_salary);
        data.append(
          "hold_basic_salary_remark",
          formDataSet.hold_basic_salary_remark
        );
        data.append("unpaid_leave", formDataSet.unpaid_leave);
        data.append("unpaid_leave_remark", formDataSet.unpaid_leave_remark);
        data.append("salary_advance", formDataSet.salary_advance);
        data.append("mistake", formDataSet.mistake);
        data.append("mistake_remark", formDataSet.mistake_remark);
        data.append("penalty", formDataSet.penalty);
        data.append("penalty_remark", formDataSet.penalty_remark);
        data.append("total_income", formDataSet.total_income);
        data.append("total_deducation", formDataSet.total_deducation);
        data.append("net_salary", formDataSet.net_salary);
        data.append("release_currency", JSON.stringify(initialRow));

        var response = await api.post("/payrolls-create", data);
        if (response.data.status == "success") {
          if (response.data.created_slips.length > 0) {
            setPrimaryList(response.data.created_slips);
            setActiveStep(2);
          } else {
            Swal.fire({
              icon: "warning",
              title: "Duplicate Entry",
              text: "Already created Payslip with this month and year",
            });
          }
        } else {
          setErrors(response.data.errors);
        }
        setSpinner(false);
      }
    }
  };

  // edit Payroll

  const [editModal, setEditModal] = useState(false);
  const openEditModal = async (id) => {
    setSpinner(true);
    var response = await api.post("/payrolls-show", { id: id });
    if (response.status === 200 && response.data) {
      setFormData(response.data.data);
      setRows(response.data.data?.release_currency);
      setEditModal(true);
    }
    setSpinner(false);
  };
  const closeEditModal = () => {
    setEditModal(false);
  };

  const [positions, setPositions] = useState([]);
  const getPositions = async () => {
    var response = await api.get("/positions");
    if (response.status === 200 && response.data) {
      setPositions(response.data);
    }
  };

  // formdata

  const [formData, setFormData] = useState({
    user_id: "",
    position: "",
    month: "",
    first_date: "",
    last_date: "",
    currency: "",
    basic_salary: 0,
    over_time: 0,
    over_time_remark: "",
    over_time_remark_two: "",
    rest_day_over_time: 0,
    rest_day_remark: "",
    rest_day_remark_two: "",
    new_year_over_time: 0,
    new_year_remark: "",
    new_year_remark_two: "",
    kpi_bonus: 0,
    food_allowance: 0,
    night_shift: 0,
    insurance: 0,
    hold_basic_salary: 0,
    hold_basic_salary_remark: "",
    unpaid_leave: 0,
    unpaid_leave_remark: "",
    salary_advance: 0,
    mistake: 0,
    mistake_remark: "",
    penalty: 0,
    penalty_remark: "",
  });

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [netSalary, setNetSalary] = useState(0);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "user_id") {
      try {
        const response = await api.post("/employees-show", { id: value });
        const { data } = response;
        if (response.status === 200 && data) {
          const { currency, basic_salary, position } = data.data;
          const formDataUpdates = {};
          if (currency) {
            formDataUpdates.currency = currency;
          }
          if (basic_salary) {
            formDataUpdates.basic_salary = basic_salary;
          }
          if (position) {
            formDataUpdates.position = position;
          }
          formDataUpdates.user_id = value;
          setFormData({ ...formData, ...formDataUpdates });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  // currency table
  const [rows, setRows] = useState([]);

  const handleAmountChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].amount = parseFloat(value);
    setRows(newRows);
  };

  const handleRateChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].currency = value;
    setRows(newRows);
  };

  const calculateTotal = (amount, rate) => {
    return parseFloat(amount) * parseFloat(rate);
  };

  // end currency data

  useEffect(() => {
    const income =
      Number(formData.basic_salary) +
      Number(formData.over_time) +
      Number(formData.rest_day_over_time) +
      Number(formData.new_year_over_time) +
      Number(formData.kpi_bonus) +
      Number(formData.insurance) +
      Number(formData.food_allowance) +
      Number(formData.night_shift);

    const deduction =
      Number(formData.hold_basic_salary) +
      Number(formData.unpaid_leave) +
      Number(formData.salary_advance) +
      Number(formData.mistake) +
      Number(formData.penalty);

    const net = income - deduction;

    setTotalIncome(income.toFixed(2));
    setTotalDeduction(deduction.toFixed(2));
    setNetSalary(net.toFixed(2));
  }, [formData]);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let newErrors = {};
    if (!formData.user_id) {
      newErrors.user_id = "User to is required";
    }

    if (!formData.month) {
      newErrors.month = "Month is required";
    }
    if (!formData.first_date) {
      newErrors.first_date = "Start Date is required";
    }

    if (!formData.last_date) {
      newErrors.last_date = "End Date is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setSpinner(true);
      const data = new FormData();

      data.append("user_id", formData.user_id);
      data.append("id", formData.id);
      data.append("month", formData.month);
      data.append("first_date", formData.first_date);
      data.append("last_date", formData.last_date);

      data.append("currency", formData.currency);
      data.append("basic_salary", formData.basic_salary);
      data.append("over_time", formData.over_time);
      data.append("over_time_remark", formData.over_time_remark);
      data.append("over_time_remark_two", formData.over_time_remark_two);
      data.append("rest_day_over_time", formData.rest_day_over_time);
      data.append("rest_day_remark", formData.rest_day_remark);
      data.append("rest_day_remark_two", formData.rest_day_remark_two);
      data.append("new_year_over_time", formData.new_year_over_time);
      data.append("new_year_remark", formData.new_year_remark);
      data.append("new_year_remark_two", formData.new_year_remark_two);
      data.append("kpi_bonus", formData.kpi_bonus);
      data.append("food_allowance", formData.food_allowance);
      data.append("night_shift", formData.night_shift);
      data.append("insurance", formData.insurance);
      data.append("hold_basic_salary", formData.hold_basic_salary);
      data.append(
        "hold_basic_salary_remark",
        formData.hold_basic_salary_remark
      );
      data.append("unpaid_leave", formData.unpaid_leave);
      data.append("unpaid_leave_remark", formData.unpaid_leave_remark);
      data.append("salary_advance", formData.salary_advance);
      data.append("mistake", formData.mistake);
      data.append("mistake_remark", formData.mistake_remark);
      data.append("penalty", formData.penalty);
      data.append("penalty_remark", formData.penalty_remark);
      data.append("total_income", totalIncome);
      data.append("total_deducation", totalDeduction);
      data.append("net_salary", netSalary);
      data.append("release_currency", JSON.stringify(rows));
      var response = await api.post("/payrolls-update", data);
      if (response.data.status == "success") {
        setEditModal(false);
        getSlips();
      } else {
        setErrors(response.data.errors);
      }
      setSpinner(false);
    }
  };

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">Payroll List</div>
        <div className="actions d-none">
          {props.rolePermission?.Employee?.add_edit ? (
            <Link
              to="/payrolls-create"
              className="btn btn-success rounded-circle"
            >
              <i className="fal fa-plus"></i>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-3">
          <div className="form-group">
            <label>Short Name</label>
            <Select options={selectOptions} onChange={employeeChange} />
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Team</label>
            <select
              value={filterData.team}
              onChange={filterChange}
              name="team"
              className="form-select"
            >
              <option value="">Select Team</option>
              {teams.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Nationality</label>
            <select
              value={filterData.nationality}
              onChange={filterChange}
              name="nationality"
              className="form-select"
            >
              <option value="">Select Nationality</option>
              {countries.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.nicename}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Currency</label>
            <select
              value={filterData.currency}
              onChange={filterChange}
              name="currency"
              className="form-select"
            >
              <option value="">Select Currency</option>
              {currencies.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.code} ({item.name})
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="col-3">
          <div className="form-group">
            <label>Year</label>
            <select
              value={filterData.year}
              onChange={filterChange}
              name="year"
              className="form-select"
            >
              <option value="">Select Year</option>
              {Array.from({ length: 12 }, (_, i) => {
                const year = new Date().getFullYear() - i;
                return (
                  <option value={String(year)} key={String(year)}>
                    {year}
                  </option>
                );
              })}
            </select>
            {newErrors.year && <div className="errorMsg">{newErrors.year}</div>}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Month</label>
            <select
              value={filterData.month}
              onChange={filterChange}
              name="month"
              className="form-select"
            >
              <option value="">Select Month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
            {newErrors.month && (
              <div className="errorMsg">{newErrors.month}</div>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>Start Date</label>
            <input
              name="first_date"
              value={filterData.first_date}
              onChange={filterChange}
              type="date"
              className="form-control"
            />
            {newErrors.first_date && (
              <div className="errorMsg">{newErrors.first_date}</div>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label>End Date</label>
            <input
              name="last_date"
              value={filterData.last_date}
              onChange={filterChange}
              type="date"
              className="form-control"
            />
            {newErrors.last_date && (
              <div className="errorMsg">{newErrors.last_date}</div>
            )}
          </div>
        </div>
      </div>
      <br />

      <div
        className="button_set"
        style={{ display: "flex", justifyContent: "center", gap: 20 }}
      >
        {activeStep === 1 && (
          <Button variant="warning" onClick={handleFormSubmit}>
            Generate
          </Button>
        )}
        {activeStep === 2 && (
          <Button onClick={finalizeSlips} variant="info">
            One shot submit
          </Button>
        )}
      </div>

      {activeStep === 1 && (
        <>
          <br />
          <div className="employee_lists">
            <div class="payroll_table">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>Action</th>
                    <th>Staff ID</th>
                    <th>Date</th>
                    <th>Team</th>
                    <th>Short Name</th>
                    <th>Status</th>
                    <th>Basic Salary</th>
                    <th>Currency</th>
                    <th>Over Time</th>
                    <th>Rest Day Over Time</th>
                    <th>New Year Over Time</th>
                    <th>KPI Bonus</th>
                    <th>Food Allowance</th>
                    <th>Night Shift Allowance</th>
                    <th>Hold Salary</th>
                    <th>Unpaid Leave</th>
                    <th>Staff Advance</th>
                    <th>Staff Mistake</th>
                    <th>Penalty</th>
                    <th>Net Salary</th>
                  </tr>
                </thead>
                <tbody>
                  {slips.length > 0
                    ? slips.map((item, index) => (
                        <tr key={index}>
                          <td className="actions ">
                            <Link onClick={() => openEditModal(item.id)}>
                              <i className="fal fa-pen"></i>
                            </Link>
                            <Link onClick={() => openSideNav(item.id)}>
                              <i className="fal fa-eye text-success"></i>
                            </Link>

                            <Link onClick={() => openModal(item.id)}>
                              <i className="fal fa-times text-danger"></i>
                            </Link>
                          </td>
                          <td>{item.user?.employee_id}</td>
                          <td> {moment(item.created_at).format("ll")}</td>
                          <td>{item.team}</td>
                          <td>{item.user?.short_name}</td>
                          <td>{item.status}</td>
                          <td className="text-right">{item.basic_salary}</td>
                          <td className="text-center">{item.currency}</td>
                          <td className="text-right">{item.over_time}</td>
                          <td className="text-right">
                            {item.rest_day_over_time}
                          </td>
                          <td className="text-right">
                            {item.new_year_over_time}
                          </td>
                          <td className="text-right">{item.kpi_bonus}</td>
                          <td className="text-right">{item.food_allowance}</td>
                          <td className="text-right">{item.night_shift}</td>
                          <td className="text-right">
                            {item.hold_basic_salary}
                          </td>
                          <td className="text-right">{item.unpaid_leave}</td>
                          <td className="text-right">{item.salary_advance}</td>
                          <td className="text-right">{item.mistake}</td>
                          <td className="text-right">{item.penalty}</td>
                          <td className="text-right">{item.net_salary}</td>
                        </tr>
                      ))
                    : "No Payroll Here"}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <div className="text-center">
            <Pagination data={paginationData} onPageChange={onPageChange} />
          </div>
        </>
      )}

      {activeStep === 2 && (
        <>
          <br />
          <div className="employee_lists">
            <div class="payroll_table primary_table_area">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>Staff ID</th>
                    <th>Date</th>
                    <th>Team</th>
                    <th>Short Name</th>
                    <th>Status</th>
                    <th>Basic Salary</th>
                    <th>Currency</th>
                    <th>Over Time</th>
                    <th>Rest Day Over Time</th>
                    <th>New Year Over Time</th>
                    <th>KPI Bonus</th>
                    <th>Food Allowance</th>
                    <th>Night Shift Allowance</th>
                    <th>Hold Salary</th>
                    <th>Unpaid Leave</th>
                    <th>Staff Advance</th>
                    <th>Staff Mistake</th>
                    <th>Penalty</th>
                    <th>Net Salary</th>
                  </tr>
                </thead>
                <tbody>
                  {primaryList.length > 0 ? (
                    primaryList.map((item, index) => (
                      <tr key={index}>
                        <td>{item.employee_id}</td>
                        <td>{moment(item.created_at).format("ll")}</td>
                        <td>{item.team}</td>
                        <td>{item.short_name}</td>
                        <td>{item.status}</td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.basic_salary}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "basic_salary",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>{item.currency}</td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.over_time}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "over_time",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.rest_day_over_time}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "rest_day_over_time",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.new_year_over_time}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "new_year_over_time",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.kpi_bonus}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "kpi_bonus",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.food_allowance}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "food_allowance",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.night_shift}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "night_shift",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.hold_basic_salary}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "hold_basic_salary",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.unpaid_leave}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "unpaid_leave",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.salary_advance}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "salary_advance",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.mistake}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "mistake",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            type="number"
                            value={item.penalty}
                            onChange={(e) =>
                              handleInputChange(
                                index,
                                "penalty",
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td>
                          <input
                            className="primary_input"
                            min="0"
                            step=".01"
                            value={item.net_salary}
                            disabled
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={18}>No Payroll Here</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}

      <div className="right_sideNav">
        <div
          className={
            sideNav
              ? fullScreen
                ? " sidenav width_100"
                : "sidenav width_full"
              : "sidenav "
          }
        >
          <div className="create_page_heading">
            <div className="page_name user_area">
              <div className="item">
                <img
                  className="rounded-circle"
                  alt=""
                  src={slipDetails.user_photo}
                />
              </div>
              <div className="credentials">
                <div className="link-primary">{slipDetails.user_name}</div>
                <div className="text-muted">
                  {moment(slipDetails.created_at).format("ll")}
                </div>
              </div>
            </div>
            <div className="actions">
              <Link
                onClick={toggleFullScreen}
                className="btn btn-success rounded-circle"
              >
                <i
                  className={fullScreen ? "fal fa-compress" : "fal fa-expand"}
                ></i>
              </Link>

              <Link
                onClick={closeSideNav}
                className="btn btn-danger rounded-circle"
              >
                <i className="fal fa-times"></i>
              </Link>
            </div>
          </div>
          <div className="details_announcement">
            <div className="attachment_list">
              <ul>
                {slipDetails.all_payslip?.length > 0
                  ? slipDetails.all_payslip.map((item, index) => (
                      <li key={index}>
                        <div className="left">
                          {item.month}-{item.year}
                        </div>
                        <div className="right">
                          <Link
                            to={"/payrolls-details/" + item.id + "/" + true}
                          >
                            <i className="fa fa-download"></i>
                          </Link>
                          <Link to={"/payrolls-details/" + item.id}>
                            <i className="fa fa-eye"></i>
                          </Link>
                        </div>
                      </li>
                    ))
                  : "No Payroll Here"}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal show={deleteModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete PaySlip</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure to delete ? </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            No
          </Button>
          <Button variant="danger" onClick={deleteSlip}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="xl"
        className="salary_slip_modal"
        show={editModal}
        onHide={closeEditModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Payroll</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-2">
              <div className="form-group">
                <label>Full Name</label>
                <select
                  name="user_id"
                  value={formData.user_id}
                  className="form-select"
                  onChange={handleChange}
                >
                  <option value="">Select Employee</option>
                  {employees.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.full_name}
                    </option>
                  ))}
                </select>
                {errors.user_id && (
                  <div className="errorMsg">{errors.user_id}</div>
                )}
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Position</label>
                <select
                  name="position"
                  onChange={handleChange}
                  value={formData.position}
                  className="form-select"
                  disabled
                >
                  {positions.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Month</label>
                <select
                  onChange={handleChange}
                  value={formData.month}
                  name="month"
                  className="form-select"
                >
                  <option value="">Select Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
                {errors.month && <div className="errorMsg">{errors.month}</div>}
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Start Date</label>
                <input
                  name="first_date"
                  value={formData.first_date}
                  onChange={handleChange}
                  type="date"
                  className="form-control"
                />
                {errors.first_date && (
                  <div className="errorMsg">{errors.first_date}</div>
                )}
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>End Date</label>
                <input
                  name="last_date"
                  value={formData.last_date}
                  onChange={handleChange}
                  type="date"
                  className="form-control"
                />
                {errors.last_date && (
                  <div className="errorMsg">{errors.last_date}</div>
                )}
              </div>
            </div>
          </div>

          <div className="salary_slip">
            <div className="row">
              <div className="col-lg-4">
                <div class="card width_100">
                  <div class="card-header text-center">Income</div>
                  <div class="card-body">
                    <div className="data_group">
                      <div className="item_label">Basic Salary</div>
                      <div className="inputs">
                        <select
                          onChange={handleChange}
                          name="currency"
                          value={formData.currency}
                        >
                          {currencies.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.code}
                            </option>
                          ))}
                        </select>
                        <input
                          onChange={handleChange}
                          value={formData.basic_salary}
                          type="number"
                          className="text-right"
                          placeholder="0.00"
                          name="basic_salary"
                          step=".01"
                        />
                      </div>
                    </div>
                    <div className="data_group">
                      <div className="item_label">Over Time</div>
                      <div className="inputs">
                        <input
                          name="over_time_remark"
                          value={formData.over_time_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          name="over_time_remark_two"
                          value={formData.over_time_remark_two}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          type="number"
                          className="text-right"
                          name="over_time"
                          onChange={handleChange}
                          value={formData.over_time}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Rest Day Over Time</div>
                      <div className="inputs">
                        <input
                          name="rest_day_remark"
                          value={formData.rest_day_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          name="rest_day_remark_two"
                          value={formData.rest_day_remark_two}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          type="number"
                          className="text-right"
                          name="rest_day_over_time"
                          onChange={handleChange}
                          value={formData.rest_day_over_time}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">New Year Over Time</div>
                      <div className="inputs">
                        <input
                          name="new_year_remark"
                          value={formData.new_year_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          name="new_year_remark_two"
                          value={formData.new_year_remark_two}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          type="number"
                          className="text-right"
                          name="new_year_over_time"
                          onChange={handleChange}
                          value={formData.new_year_over_time}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">KPI Bonus</div>
                      <div className="inputs">
                        <input
                          type="number"
                          className="text-right"
                          name="kpi_bonus"
                          onChange={handleChange}
                          value={formData.kpi_bonus}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>
                    <h5 className="text-underline text-uppercase">
                      <b>
                        <u>Allowances</u>
                      </b>
                    </h5>

                    <div className="data_group">
                      <div className="item_label">Food Allowance</div>
                      <div className="inputs">
                        <input
                          type="number"
                          className="text-right"
                          name="food_allowance"
                          onChange={handleChange}
                          value={formData.food_allowance}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Night Shift</div>
                      <div className="inputs">
                        <input
                          type="number"
                          className="text-right"
                          name="night_shift"
                          onChange={handleChange}
                          value={formData.night_shift}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group d-none">
                      <div className="item_label">Insurance</div>
                      <div className="inputs">
                        <input
                          type="number"
                          className="text-right"
                          name="insurance"
                          onChange={handleChange}
                          value={formData.insurance}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-right">
                    <span>Total Income</span>{" "}
                    <input
                      name="total_income"
                      value={totalIncome}
                      type="number"
                      className="text-right"
                      step=".01"
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div class="card width_100">
                  <div class="card-header text-center">Deduction</div>
                  <div class="card-body">
                    <div className="data_group">
                      <div className="item_label">Hold Basic Salary</div>
                      <div className="inputs">
                        <input
                          name="hold_basic_salary_remark"
                          value={formData.hold_basic_salary_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          className="text-right"
                          name="hold_basic_salary"
                          onChange={handleChange}
                          value={formData.hold_basic_salary}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Unpaid Leave</div>
                      <div className="inputs">
                        <input
                          name="unpaid_leave_remark"
                          value={formData.unpaid_leave_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          className="text-right"
                          name="unpaid_leave"
                          onChange={handleChange}
                          value={formData.unpaid_leave}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Salary Advance</div>
                      <div className="inputs">
                        <input
                          type="number"
                          className="text-right"
                          name="salary_advance"
                          onChange={handleChange}
                          value={formData.salary_advance}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Mistake</div>
                      <div className="inputs">
                        <input
                          name="mistake_remark"
                          value={formData.mistake_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          className="text-right"
                          name="mistake"
                          onChange={handleChange}
                          value={formData.mistake}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Penalty</div>
                      <div className="inputs">
                        <input
                          name="penalty_remark"
                          value={formData.penalty_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          className="text-right"
                          name="penalty"
                          onChange={handleChange}
                          value={formData.penalty}
                          placeholder="0.00"
                          step=".01"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-right">
                    <span>Total Deduction</span>{" "}
                    <input
                      value={totalDeduction}
                      type="number"
                      className="text-right"
                      step=".01"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div class="card width_100">
                  <div class="card-header text-center">
                    Release Currency Payment
                  </div>
                  <div className="card-body">
                    <div className="rate_table">
                      <div className="datarow head">
                        <div className="item">Amount</div>
                        <div className="item">Currency / Rate</div>
                        <div className="item">Total</div>
                      </div>
                      {rows.map((row, index) => (
                        <div key={index} className="datarow">
                          <div className="item">
                            <span>{index + 1} </span>
                            <input
                              name="amount"
                              type="number"
                              step=".01"
                              placeholder="0.00"
                              value={row.amount}
                              className="text-right"
                              onChange={(e) =>
                                handleAmountChange(index, e.target.value)
                              }
                            />
                          </div>
                          <div className="item d-flex">
                            <select
                              value={row.currency}
                              onChange={(e) =>
                                handleRateChange(index, e.target.value)
                              }
                              name="currency"
                            >
                              <option value="">Select</option>
                              {currencies.map((item, index) => (
                                <option key={index} value={item.code}>
                                  {item.code}
                                </option>
                              ))}
                            </select>

                            <input
                              name="rate"
                              type="number"
                              step=".01"
                              placeholder="0.00"
                              value={row.rate}
                              className="text-right"
                              onChange={(e) => {
                                const newRows = [...rows];
                                newRows[index].rate = parseFloat(
                                  e.target.value
                                );
                                setRows(newRows);
                              }}
                            />
                          </div>
                          <div className="item">
                            <input
                              name="total"
                              type="number"
                              step=".01"
                              placeholder="0.00"
                              value={calculateTotal(
                                row.amount,
                                row.rate
                              ).toFixed(2)}
                              readOnly
                              className="text-right"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div class="card width_100">
                  <div class="card-footer text-right">
                    <span>Net Salary</span>{" "}
                    <select
                      onChange={handleChange}
                      name="currency"
                      value={formData.currency}
                      disabled
                    >
                      {currencies.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.code}
                        </option>
                      ))}
                    </select>
                    <input
                      value={netSalary}
                      type="number"
                      className="text-right"
                      step=".01"
                      disabled
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button variant="danger" onClick={closeEditModal}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
