import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import api from "../../services/api";

export default function EditPayroll() {
  const params = useParams();

  const history = useHistory();
  const [employees, setEmployees] = useState([]);
  const getEmployees = async () => {
    var response = await api.post("/employees");
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
    } else {
      console.log(response.data);
    }
  };
  const [positions, setPositions] = useState([]);
  const getPositions = async () => {
    var response = await api.get("/positions");
    if (response.status === 200 && response.data) {
      setPositions(response.data);
    }
  };

  const getSlip = async () => {
    var response = await api.post("/payrolls-show", { id: params.id });
    if (response.status === 200 && response.data) {
      setFormData(response.data.data);
      setRows(response.data.data?.release_currency);
    }
  };

  // formdata

  const [formData, setFormData] = useState({
    user_id: "",
    position: "",
    month: "",
    first_date: "",
    last_date: "",
    currency: "",
    basic_salary: 0,
    over_time: 0,
    over_time_remark: "",
    over_time_remark_two: "",
    rest_day_over_time: 0,
    rest_day_remark: "",
    rest_day_remark_two: "",
    new_year_over_time: 0,
    new_year_remark: "",
    new_year_remark_two: "",
    kpi_bonus: 0,
    food_allowance: 0,
    night_shift: 0,
    insurance: 0,
    hold_basic_salary: 0,
    hold_basic_salary_remark: "",
    unpaid_leave: 0,
    unpaid_leave_remark: "",
    salary_advance: 0,
    mistake: 0,
    mistake_remark: "",
    penalty: 0,
    penalty_remark: "",
  });

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [netSalary, setNetSalary] = useState(0);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    if (name === "user_id") {
      try {
        const response = await api.post("/employees-show", { id: value });
        const { data } = response;
        if (response.status === 200 && data) {
          const { currency, basic_salary, position } = data.data;
          const formDataUpdates = {};
          if (currency) {
            formDataUpdates.currency = currency;
          }
          if (basic_salary) {
            formDataUpdates.basic_salary = basic_salary;
          }
          if (position) {
            formDataUpdates.position = position;
          }
          formDataUpdates.user_id = value;
          setFormData({ ...formData, ...formDataUpdates });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  // currency table
  const [rows, setRows] = useState([]);

  const handleAmountChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].amount = parseFloat(value);
    setRows(newRows);
  };

  const handleRateChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].currency = value;
    setRows(newRows);
  };

  const calculateTotal = (amount, rate) => {
    return parseFloat(amount) * parseFloat(rate);
  };

  console.log("totalData", rows);

  console.log("formdata", formData);
  // end currency data

  useEffect(() => {
    const income =
      Number(formData.basic_salary) +
      Number(formData.over_time) +
      Number(formData.rest_day_over_time) +
      Number(formData.new_year_over_time) +
      Number(formData.kpi_bonus) +
      Number(formData.insurance) +
      Number(formData.food_allowance) +
      Number(formData.night_shift);

    const deduction =
      Number(formData.hold_basic_salary) +
      Number(formData.unpaid_leave) +
      Number(formData.salary_advance) +
      Number(formData.mistake) +
      Number(formData.penalty);

    const net = income - deduction;

    setTotalIncome(income.toFixed(2));
    setTotalDeduction(deduction.toFixed(2));
    setNetSalary(net.toFixed(2));
  }, [formData]);

  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let newErrors = {};
    if (!formData.user_id) {
      newErrors.user_id = "User to is required";
    }

    if (!formData.month) {
      newErrors.month = "Month is required";
    }
    if (!formData.first_date) {
      newErrors.first_date = "First Date is required";
    }

    if (!formData.last_date) {
      newErrors.last_date = "Last Date is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const data = new FormData();

      data.append("user_id", formData.user_id);
      data.append("id", formData.id);
      data.append("month", formData.month);
      data.append("first_date", formData.first_date);
      data.append("last_date", formData.last_date);

      data.append("currency", formData.currency);
      data.append("basic_salary", formData.basic_salary);
      data.append("over_time", formData.over_time);
      data.append("over_time_remark", formData.over_time_remark);
      data.append("over_time_remark_two", formData.over_time_remark_two);
      data.append("rest_day_over_time", formData.rest_day_over_time);
      data.append("rest_day_remark", formData.rest_day_remark);
      data.append("rest_day_remark_two", formData.rest_day_remark_two);
      data.append("new_year_over_time", formData.new_year_over_time);
      data.append("new_year_remark", formData.new_year_remark);
      data.append("new_year_remark_two", formData.new_year_remark_two);
      data.append("kpi_bonus", formData.kpi_bonus);
      data.append("food_allowance", formData.food_allowance);
      data.append("night_shift", formData.night_shift);
      data.append("insurance", formData.insurance);
      data.append("hold_basic_salary", formData.hold_basic_salary);
      data.append(
        "hold_basic_salary_remark",
        formData.hold_basic_salary_remark
      );
      data.append("unpaid_leave", formData.unpaid_leave);
      data.append("unpaid_leave_remark", formData.unpaid_leave_remark);
      data.append("salary_advance", formData.salary_advance);
      data.append("mistake", formData.mistake);
      data.append("mistake_remark", formData.mistake_remark);
      data.append("penalty", formData.penalty);
      data.append("penalty_remark", formData.penalty_remark);
      data.append("total_income", totalIncome);
      data.append("total_deducation", totalDeduction);
      data.append("net_salary", netSalary);
      data.append("release_currency", JSON.stringify(rows));
      var response = await api.post("/payrolls-update", data);
      if (response.data.status == "success") {
        history.push("/payrolls");
      } else {
        setErrors(response.data.errors);
      }
    }
  };
  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };

  useEffect(async () => {
    getEmployees();
    getPositions();
    getCurrencies();
    getSlip();
  }, []);
  return (
    <div className="create_edit_page">
      <form onSubmit={handleSubmit}>
        <div className="create_page_heading">
          <div className="page_name">Edit Payroll</div>
          <div className="actions">
            <button type="supmit" className="publish_btn btn btn-success">
              Update
            </button>
            <Link to="/payrolls" className="btn btn-danger rounded-circle">
              <i className="fal fa-times"></i>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="d-flex justify-content-between gap_10">
            <div className="form-group">
              <label>Full Name</label>
              <select
                name="user_id"
                value={formData.user_id}
                className="form-select"
                onChange={handleChange}
              >
                <option value="">Select Employee</option>
                {employees.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.full_name}
                  </option>
                ))}
              </select>
              {errors.user_id && (
                <div className="errorMsg">{errors.user_id}</div>
              )}
            </div>

            <div className="form-group">
              <label>Position</label>
              <select
                name="position"
                onChange={handleChange}
                value={formData.position}
                className="form-select"
                disabled
              >
                {positions.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Month</label>
              <select
                onChange={handleChange}
                value={formData.month}
                name="month"
                className="form-select"
              >
                <option value="">Select Month</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
              {errors.month && <div className="errorMsg">{errors.month}</div>}
            </div>
            <div className="form-group">
              <label>First Date</label>
              <input
                name="first_date"
                value={formData.first_date}
                onChange={handleChange}
                type="date"
                className="form-control"
              />
              {errors.first_date && (
                <div className="errorMsg">{errors.first_date}</div>
              )}
            </div>
            <div className="form-group">
              <label>Last Date</label>
              <input
                name="last_date"
                value={formData.last_date}
                onChange={handleChange}
                type="date"
                className="form-control"
              />
              {errors.last_date && (
                <div className="errorMsg">{errors.last_date}</div>
              )}
            </div>
          </div>
        </div>
        <br />
        <br />
        <div className="salary_slip">
          <div className="row">
            <div className="col-lg-6">
              <div class="card width_100">
                <div class="card-header text-center">Income</div>
                <div class="card-body">
                  <div className="data_group">
                    <div className="item_label">Basic Salary</div>
                    <div className="inputs">
                      <select
                        onChange={handleChange}
                        name="currency"
                        value={formData.currency}
                      >
                        {currencies.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.code}
                          </option>
                        ))}
                      </select>
                      <input
                        onChange={handleChange}
                        value={formData.basic_salary}
                        type="number"
                        className="text-right"
                        placeholder="0.00"
                        name="basic_salary"
                        step=".01"
                      />
                    </div>
                  </div>
                  <div className="data_group">
                    <div className="item_label">Over Time</div>
                    <div className="inputs">
                      <input
                        name="over_time_remark"
                        value={formData.over_time_remark}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />
                      <input
                        name="over_time_remark_two"
                        value={formData.over_time_remark_two}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />
                      <input
                        type="number"
                        className="text-right"
                        name="over_time"
                        onChange={handleChange}
                        value={formData.over_time}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                  <div className="data_group">
                    <div className="item_label">Rest Day Over Time</div>
                    <div className="inputs">
                      <input
                        name="rest_day_remark"
                        value={formData.rest_day_remark}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />
                      <input
                        name="rest_day_remark_two"
                        value={formData.rest_day_remark_two}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />
                      <input
                        type="number"
                        className="text-right"
                        name="rest_day_over_time"
                        onChange={handleChange}
                        value={formData.rest_day_over_time}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                  <div className="data_group">
                    <div className="item_label">New Year Over Time</div>
                    <div className="inputs">
                      <input
                        name="new_year_remark"
                        value={formData.new_year_remark}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />
                      <input
                        name="new_year_remark_two"
                        value={formData.new_year_remark_two}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />
                      <input
                        type="number"
                        className="text-right"
                        name="new_year_over_time"
                        onChange={handleChange}
                        value={formData.new_year_over_time}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                  <div className="data_group">
                    <div className="item_label">KPI Bonus</div>
                    <div className="inputs">
                      <input
                        type="number"
                        className="text-right"
                        name="kpi_bonus"
                        onChange={handleChange}
                        value={formData.kpi_bonus}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                  <h5 className="text-underline text-uppercase">
                    <u>Allowances</u>
                  </h5>{" "}
                  <h5 className="text-underline text-uppercase">
                    <b>
                      <u>Allowances</u>
                    </b>
                  </h5>
                  <div className="data_group">
                    <div className="item_label">Food Allowance</div>
                    <div className="inputs">
                      <input
                        type="number"
                        className="text-right"
                        name="food_allowance"
                        onChange={handleChange}
                        value={formData.food_allowance}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                  <div className="data_group">
                    <div className="item_label">Night Shift</div>
                    <div className="inputs">
                      <input
                        type="number"
                        className="text-right"
                        name="night_shift"
                        onChange={handleChange}
                        value={formData.night_shift}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                  <div className="data_group d-none">
                    <div className="item_label">Insurance</div>
                    <div className="inputs">
                      <input
                        type="number"
                        className="text-right"
                        name="insurance"
                        onChange={handleChange}
                        value={formData.insurance}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <span>Total Income</span>{" "}
                  <input
                    name="total_income"
                    value={totalIncome}
                    type="number"
                    className="text-right"
                    step=".01"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div class="card width_100">
                <div class="card-header text-center">Deduction</div>
                <div class="card-body">
                  <div className="data_group">
                    <div className="item_label">Hold Basic Salary</div>
                    <div className="inputs">
                      <input
                        name="hold_basic_salary_remark"
                        value={formData.hold_basic_salary_remark}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />

                      <input
                        type="number"
                        className="text-right"
                        name="hold_basic_salary"
                        onChange={handleChange}
                        value={formData.hold_basic_salary}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>

                  <div className="data_group">
                    <div className="item_label">Unpaid Leave</div>
                    <div className="inputs">
                      <input
                        name="unpaid_leave_remark"
                        value={formData.unpaid_leave_remark}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />

                      <input
                        type="number"
                        className="text-right"
                        name="unpaid_leave"
                        onChange={handleChange}
                        value={formData.unpaid_leave}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>

                  <div className="data_group">
                    <div className="item_label">Salary Advance</div>
                    <div className="inputs">
                      <input
                        type="number"
                        className="text-right"
                        name="salary_advance"
                        onChange={handleChange}
                        value={formData.salary_advance}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>

                  <div className="data_group">
                    <div className="item_label">Mistake</div>
                    <div className="inputs">
                      <input
                        name="mistake_remark"
                        value={formData.mistake_remark}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />

                      <input
                        type="number"
                        className="text-right"
                        name="mistake"
                        onChange={handleChange}
                        value={formData.mistake}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>

                  <div className="data_group">
                    <div className="item_label">Penalty</div>
                    <div className="inputs">
                      <input
                        name="penalty_remark"
                        value={formData.penalty_remark}
                        onChange={handleChange}
                        type="text"
                        placeholder="Remark"
                      />

                      <input
                        type="number"
                        className="text-right"
                        name="penalty"
                        onChange={handleChange}
                        value={formData.penalty}
                        placeholder="0.00"
                        step=".01"
                      />
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <span>Total Deduction</span>{" "}
                  <input
                    value={totalDeduction}
                    type="number"
                    className="text-right"
                    step=".01"
                    disabled
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div class="card width_100">
                <div class="card-footer text-right">
                  <span>Net Salary</span>{" "}
                  <select
                    onChange={handleChange}
                    name="currency"
                    value={formData.currency}
                    disabled
                  >
                    {currencies.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.code}
                      </option>
                    ))}
                  </select>
                  <input
                    value={netSalary}
                    type="number"
                    className="text-right"
                    step=".01"
                    disabled
                  />{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <div class="card width_100">
                <div class="card-header text-center">
                  Release Currency Payment
                </div>
                <div className="card-body">
                  <div className="rate_table">
                    <div className="datarow head">
                      <div className="item">Amount</div>
                      <div className="item">Currency / Rate</div>
                      <div className="item">Total</div>
                    </div>
                    {rows.map((row, index) => (
                      <div key={index} className="datarow">
                        <div className="item">
                          <span>{index + 1} </span>
                          <input
                            name="amount"
                            type="number"
                            step=".01"
                            placeholder="0.00"
                            className="text-right"
                            value={row.amount}
                            onChange={(e) =>
                              handleAmountChange(index, e.target.value)
                            }
                          />
                        </div>
                        <div className="item d-flex">
                          <select
                            value={row.currency}
                            onChange={(e) =>
                              handleRateChange(index, e.target.value)
                            }
                            name="currency"
                          >
                            <option value="">Select</option>
                            {currencies.map((item, index) => (
                              <option key={index} value={item.code}>
                                {item.code}
                              </option>
                            ))}
                          </select>

                          <input
                            name="rate"
                            type="number"
                            step=".01"
                            placeholder="0.00"
                            value={row.rate}
                            className="text-right"
                            onChange={(e) => {
                              const newRows = [...rows];
                              newRows[index].rate = parseFloat(e.target.value);
                              setRows(newRows);
                            }}
                          />
                        </div>
                        <div className="item">
                          <input
                            name="total"
                            type="number"
                            step=".01"
                            placeholder="0.00"
                            value={calculateTotal(row.amount, row.rate).toFixed(
                              2
                            )}
                            readOnly
                            className="text-right"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
