import React, { useState, useEffect, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../elements/Spinner";
import api from "../../services/api";

export default function CreateAnnouncement(props) {
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    visibility: "",
    pinned: 0,
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
  const handleFileSelection = (event) => {
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };
  const handleFileDelete = (index) => {
    const newSelectedFiles = [...selectedFiles];
    newSelectedFiles.splice(index, 1);
    setSelectedFiles(newSelectedFiles);
  };

  const [message, setMessage] = useState("");
  const handleMsgChange = (value) => {
    setMessage(value);
  };

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    if (event.target.name === "pinned") {
      const value = event.target.checked ? 1 : 0;
      setFormData({ ...formData, [event.target.name]: value });
    } else {
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) {
      newErrors.title = "Title is required";
    }
    // if (!formData.visibility) {
    //   newErrors.visibility = "Visibility is required";
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // select multiple Team
  const [searchValue, setSearchValue] = useState("");
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const selectTeam = (event) => {
    var updatedList = [...selectedTeams];
    var idList = [...selectedIds];
    var team = myTeams.find((team) => {
      return team.id == event.target.value;
    });

    if (selectedIds.includes(event.target.value)) {
      idList.splice(selectedIds.indexOf(event.target.value), 1);
      updatedList.splice(selectedIds.indexOf(event.target.value), 1);
    } else {
      idList = [...selectedIds, event.target.value];
      updatedList = [...selectedTeams, team];
    }

    setSelectedIds(idList);
    setSelectedTeams(updatedList);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      var data = new FormData();
      data.append("title", formData.title);
      data.append("message", message);
      for (let i = 0; i < selectedFiles.length; i++) {
        data.append("attatchments[]", selectedFiles[i]);
      }
      data.append("visibility", selectedIds);
      data.append("pinned", formData.pinned);

      console.log(data);
      setSpinner(true);
      var response = await api.post("/announcements-create", data);
      if (response.status === 200 && response.data) {
        history.push("/announcements");
      } else {
        console.log(response.data.errors);
      }
      setSpinner(false);
    }
  };

  const [myTeams, setMyTeams] = useState([]);
  const getMyTeams = async () => {
    setSpinner(true);
    var response = await api.get("/get_teams_with_user");
    if (response.status === 200 && response.data) {
      setMyTeams(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  useEffect(async () => {
    getMyTeams();
  }, []);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <form onSubmit={handleSubmit}>
        <div className="create_page_heading">
          <div className="page_name">Create New Announcement</div>
          <div className="actions">
            <button type="submit" className="publish_btn btn btn-success">
              Publish
            </button>
            <Link to="/announcements" className="btn btn-danger rounded-circle">
              <i className="fal fa-times"></i>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-8">
            <div className="form-group">
              <label>Role Permission:</label>
              <input
                className="form-control"
                disabled
                value={props.userData?.role}
              />
            </div>

            <div className="form-group">
              <label htmlFor="team">Teams:</label>
              {myTeams.length > 0
                ? myTeams.map((item, index) => (
                    <span className="text-muted" key={index}>
                      {" "}
                      {item.title},
                    </span>
                  ))
                : "No team found"}
            </div>
            <hr></hr>
            <div className="form-group">
              <label htmlFor="title">Title:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="form-control"
              />
              {errors.title && <div className="errorMsg">{errors.title}</div>}
            </div>
          </div>
          <div className="form-group">
            <label>Message:</label>
            <div id="editor"></div>
            <ReactQuill className="text_area" onChange={handleMsgChange} />
          </div>
          <div className="col-lg-8">
            <div className="form-group">
              <label htmlFor="attachments">attachments:</label>
              <small className="text-muted">
                {" "}
                (PDF,Word,Excel,JPEG,PNG file.Recommend minimum file 200px *
                200px.)
              </small>
              <div className="d-flex mb-10">
                <input
                  type="file"
                  className="form-control"
                  multiple
                  onChange={handleFileSelection}
                  id="input_files"
                />
                <div className="d-flex margin_left_30">
                  <label
                    for="input_files"
                    className="btn btn-success rounded-circle btn-xs"
                  >
                    <i className="fal fa-plus"></i>
                  </label>
                </div>
              </div>

              {selectedFiles.map((file, index) => (
                <div key={file.name} className="d-flex mb-10">
                  <input className="form-control" disabled value={file.name} />
                  <div className="d-flex">
                    <Link
                      onClick={() => handleFileDelete(index)}
                      className="btn btn-danger rounded-circle margin_left_15 btn-xs"
                    >
                      <i className="fa fa-times"></i>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <br />
            {/* <div className="form-group">
              <label htmlFor="visibility">Visibility:</label>
              <select
                name="visibility"
                value={formData.visibility}
                onChange={handleChange}
                className="form-select"
              >
                <option value="0">Select Team</option>
                {myTeams.length > 0 ? (
                  myTeams.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))
                ) : (
                  <option value="0">No team found</option>
                )}
              </select>
              {errors.visibility && (
                <div className="errorMsg">{errors.visibility}</div>
              )}
            </div> */}

            <div className="form-group">
              <label>
                Visibility:{" "}
                <span className="text-muted">(search with team name)</span>
              </label>
              <input
                placeholder="Search Team...."
                onChange={(e) => setSearchValue(e.target.value)}
                type="text"
                value={searchValue}
                className="form-control"
              />
            </div>
            <div className="employee_list">
              <div className="all_employee">
                {searchValue ? (
                  <>
                    {myTeams
                      .filter((item) => {
                        if (!searchValue) return false;
                        if (item.title.includes(searchValue)) {
                          return true;
                        }
                      })
                      .map((team) => (
                        <li>
                          <label
                            key={team.id}
                            for={"employee_" + team.id}
                          >
                            <Fragment>
                              <div className="single_employee">
                                <div className="color_title">
                                  {team.title}
                                </div>
                                <input
                                  id={"employee_" + team.id}
                                  type="checkbox"
                                  onChange={selectTeam}
                                  value={team.id}
                                  hidden
                                />
                              </div>
                            </Fragment>
                          </label>
                        </li>
                      ))}
                  </>
                ) : (
                  <>
                    {myTeams.map((team) => (
                      <li>
                        <label
                          key={team.id}
                          for={"employee_" + team.id}
                        >
                          <Fragment>
                            <div className="single_employee">
                              <div className="color_title">
                                {team.title}
                              </div>
                              <input
                                id={"employee_" + team.id}
                                type="checkbox"
                                onChange={selectTeam}
                                value={team.id}
                                hidden
                              />
                            </div>
                          </Fragment>
                        </label>
                      </li>
                    ))}
                  </>
                )}
              </div>
              <div className="selected_employees">
                <h5>Selected Employees</h5>
                {selectedTeams.map((team) => (
                  <li>
                    <label key={team.id} for={"employee_" + team.id}>
                      <Fragment>
                        <div className="single_employee">
                          <div className="color_title">
                            {team.title}{" "}
                            <i className="fa fa-times text-danger"></i>
                          </div>
                          <input
                            id={"employee_" + team.id}
                            type="checkbox"
                            onChange={selectTeam}
                            value={team.id}
                            hidden
                          />
                        </div>
                      </Fragment>
                    </label>
                  </li>
                ))}
              </div>
            </div>

            <div className="form-group">
              <label>Pinned:</label>
              <br />
              <label className="switch">
                <input
                  name="pinned"
                  type="checkbox"
                  className="singleCheckbox"
                  onChange={handleChange}
                />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
