import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../services/api";
import Select from "react-select";
import Spinner from "../../elements/Spinner";
import { Modal, Button } from "react-bootstrap";
import Swal from "sweetalert2";

export default function CreatePayroll() {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const getEmployees = async () => {
    var response = await api.post("/employees");
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  const [rows, setRows] = useState([
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
    { amount: 0, currency: "", rate: 0, total: 0 },
  ]);

  const handleAmountChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].amount = parseFloat(value);
    setRows(newRows);
  };

  const handleRateChange = (index, value) => {
    const newRows = [...rows];
    newRows[index].currency = value;
    setRows(newRows);
  };

  const calculateTotal = (amount, rate) => {
    return parseFloat(amount) * parseFloat(rate);
  };

  const [totalIncome, setTotalIncome] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [netSalary, setNetSalary] = useState(0);

  const [filterData, setFilterData] = useState({
    status: "",
    team: "",
    nationality: "",
    currency: "",
  });
  const filterChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const selectOptions = employees.map((employee) => ({
    value: employee.id,
    label: employee.full_name,
  }));

  const employeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
  };
  // formdata
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    month: "",
    first_date: "",
    last_date: "",
    currency: "",
    basic_salary: "0.00",
    over_time: "0.00",
    over_time_remark: "",
    over_time_remark_two: "",
    rest_day_over_time: "0.00",
    rest_day_remark: "",
    rest_day_remark_two: "",
    new_year_over_time: "0.00",
    new_year_remark: "",
    new_year_remark_two: "",
    kpi_bonus: "0.00",
    food_allowance: "0.00",
    night_shift: "0.00",
    insurance: "0.00",
    hold_basic_salary: "0.00",
    hold_basic_salary_remark: "",
    unpaid_leave: "0.00",
    unpaid_leave_remark: "",
    salary_advance: "0.00",
    mistake: "0.00",
    mistake_remark: "",
    penalty: "0.00",
    penalty_remark: "",
  });
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const validateForm = () => {
    let newErrors = {};
    if (!formData.month) {
      newErrors.month = "Month is required";
    }
    if (!formData.first_date) {
      newErrors.first_date = "First Date is required";
    }

    if (!formData.last_date) {
      newErrors.last_date = "Last Date is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const income =
      Number(formData.basic_salary) +
      Number(formData.over_time) +
      Number(formData.rest_day_over_time) +
      Number(formData.new_year_over_time) +
      Number(formData.kpi_bonus) +
      Number(formData.insurance) +
      Number(formData.food_allowance) +
      Number(formData.night_shift);

    const deduction =
      Number(formData.hold_basic_salary) +
      Number(formData.unpaid_leave) +
      Number(formData.salary_advance) +
      Number(formData.mistake) +
      Number(formData.penalty);

    const net = income - deduction;

    setTotalIncome(income.toFixed(2));
    setTotalDeduction(deduction.toFixed(2));
    setNetSalary(net.toFixed(2));
  }, [formData]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      setSpinner(true);
      const data = new FormData();
      data.append("selected_ids", ids);
      data.append("month", formData.month);
      data.append("first_date", formData.first_date);
      data.append("last_date", formData.last_date);
      data.append("currency", formData.currency);
      data.append("basic_salary", formData.basic_salary);
      data.append("over_time", formData.over_time);
      data.append("over_time_remark", formData.over_time_remark);
      data.append("over_time_remark_two", formData.over_time_remark_two);
      data.append("rest_day_over_time", formData.rest_day_over_time);
      data.append("rest_day_remark", formData.rest_day_remark);
      data.append("rest_day_remark_two", formData.rest_day_remark_two);
      data.append("new_year_over_time", formData.new_year_over_time);
      data.append("new_year_remark", formData.new_year_remark);
      data.append("new_year_remark_two", formData.new_year_remark_two);
      data.append("kpi_bonus", formData.kpi_bonus);
      data.append("food_allowance", formData.food_allowance);
      data.append("night_shift", formData.night_shift);
      data.append("insurance", formData.insurance);
      data.append("hold_basic_salary", formData.hold_basic_salary);
      data.append(
        "hold_basic_salary_remark",
        formData.hold_basic_salary_remark
      );
      data.append("unpaid_leave", formData.unpaid_leave);
      data.append("unpaid_leave_remark", formData.unpaid_leave_remark);
      data.append("salary_advance", formData.salary_advance);
      data.append("mistake", formData.mistake);
      data.append("mistake_remark", formData.mistake_remark);
      data.append("penalty", formData.penalty);
      data.append("penalty_remark", formData.penalty_remark);
      data.append("total_income", totalIncome);
      data.append("total_deducation", totalDeduction);
      data.append("net_salary", netSalary);
      data.append("release_currency", JSON.stringify(rows));

      var response = await api.post("/payrolls-create", data);
      if (response.data.status == "success") {
        history.push("/payrolls");
      } else {
        setErrors(response.data.errors);
      }
      setSpinner(false);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };
  const [teams, setTeams] = useState([]);
  const getTeams = async () => {
    var response = await api.post("/teams");
    if (response.status === 200 && response.data) {
      setTeams(response.data.data);
    }
  };

  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setCountries(response.data);
    }
  };
  const [ids, setIds] = useState([]);

  const [employeeList, setEmployeeList] = useState([]);
  const getFilterdEmployees = async () => {
    setSpinner(true);
    var response = await api.post("/employees", {
      page: page,
      perPage: 500,
      team: filterData.team,
      status: filterData.status,
      nationality: filterData.nationality,
      employee_id: selectedEmployee,
      currency: filterData.currency,
    });

    if (response.status === 200 && response.data) {
      setEmployeeList(response.data.data);
      const idList = response.data.data.map((employee) => employee.id);
      setIds(idList);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const openModal = () => {
    if (ids.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "Empty Lists",
        text: "There is no employees in your selection.",
      });
      return;
    } else {
      setShowModal(true);
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(async () => {
    getFilterdEmployees();
  }, [filterData, selectedEmployee]);

  useEffect(async () => {
    getEmployees();
    getCurrencies();
    getTeams();
    getCountries();
  }, []);
  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">New Payroll</div>
        <div className="actions">
          <button
            onClick={openModal}
            type="supmit"
            className="publish_btn btn btn-success"
          >
            Generate
          </button>
          <Link to="/payrolls" className="btn btn-danger rounded-circle">
            <i className="fal fa-times"></i>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <h3>Filter Employees</h3>
          <hr />
          <div className="row">
            <div className="col-2">
              <div className="form-group">
                <label>Full Name</label>
                <Select options={selectOptions} onChange={employeeChange} />
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Status</label>
                <select
                  value={filterData.status}
                  onChange={filterChange}
                  name="status"
                  className="form-select"
                >
                  <option value="">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Team</label>
                <select
                  value={filterData.team}
                  onChange={filterChange}
                  name="team"
                  className="form-select"
                >
                  <option value="">Select Team</option>
                  {teams.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Select Nationality</label>
                <select
                  value={filterData.nationality}
                  onChange={filterChange}
                  name="nationality"
                  className="form-select"
                >
                  <option value="">Nationality</option>
                  {countries.map((item, index) => (
                    <option key={index} value={item.code}>
                      {item.nicename}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label>Currency</label>
                <select
                  value={filterData.currency}
                  onChange={filterChange}
                  name="currency"
                  className="form-select"
                >
                  <option value="">Select Currency</option>
                  {currencies.map((item, index) => (
                    <option key={index} value={item.code}>
                      {item.code} ({item.name})
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <h3>Employees</h3>
      <div className="row">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Full Name</th>
              <th scope="col">Staff ID</th>
              <th scope="col">Status</th>
              <th scope="col">Team</th>
              <th scope="col">Nationality</th>
              <th scope="col">Currency</th>
              <th scope="col">Basic Salary</th>
            </tr>
          </thead>
          <tbody>
            {employeeList.map((item, index) => (
              <tr key={index}>
                <td scope="row">{item.full_name}</td>
                <td>{item.employee_id}</td>
                <td>{item.employement_data?.job_status}</td>
                <td>{item.employement_data?.team_name} </td>
                <td>{item.nationality_name}</td>
                <td>{item.finance_data?.currency} </td>
                <td>{item.finance_data?.basic_salary}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal
        size="xl"
        className="salary_slip_modal"
        show={showModal}
        onHide={closeModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill Up Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-4">
                  <div className="form-group">
                    <label>Month</label>
                    <select
                      onChange={handleChange}
                      value={formData.month}
                      name="month"
                      className="form-select"
                    >
                      <option value="">Select Month</option>
                      <option value="January">January</option>
                      <option value="February">February</option>
                      <option value="March">March</option>
                      <option value="April">April</option>
                      <option value="May">May</option>
                      <option value="June">June</option>
                      <option value="July">July</option>
                      <option value="August">August</option>
                      <option value="September">September</option>
                      <option value="October">October</option>
                      <option value="November">November</option>
                      <option value="December">December</option>
                    </select>
                    {errors.month && (
                      <div className="errorMsg">{errors.month}</div>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>First Date</label>
                    <input
                      name="first_date"
                      value={formData.first_date}
                      onChange={handleChange}
                      type="date"
                      className="form-control"
                    />
                    {errors.first_date && (
                      <div className="errorMsg">{errors.first_date}</div>
                    )}
                  </div>
                </div>
                <div className="col-4">
                  <div className="form-group">
                    <label>Last Date</label>
                    <input
                      name="last_date"
                      value={formData.last_date}
                      onChange={handleChange}
                      type="date"
                      className="form-control"
                    />
                    {errors.last_date && (
                      <div className="errorMsg">{errors.last_date}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="salary_slip">
            <div className="row">
              <div className="col-lg-4">
                <div class="card width_100">
                  <div class="card-header text-center">Income</div>
                  <div class="card-body">
                    <div className="data_group">
                      <div className="item_label">Basic Salary</div>
                      <div className="inputs">
                        <select
                          onChange={handleChange}
                          name="currency"
                          value={formData.currency}
                        >
                          <option value="">Select</option>
                          {currencies.map((item, index) => (
                            <option key={index} value={item.code}>
                              {item.code}
                            </option>
                          ))}
                        </select>
                        <input
                          onChange={handleChange}
                          value={formData.basic_salary}
                          type="number"
                          min="0"
                          placeholder="0.00"
                          name="basic_salary"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>
                    <div className="data_group">
                      <div className="item_label">Over Time</div>
                      <div className="inputs">
                        <input
                          name="over_time_remark"
                          value={formData.over_time_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          name="over_time_remark_two"
                          value={formData.over_time_remark_two}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          type="number"
                          min="0"
                          name="over_time"
                          onChange={handleChange}
                          value={formData.over_time}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Rest Day Over Time</div>
                      <div className="inputs">
                        <input
                          name="rest_day_remark"
                          value={formData.rest_day_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          name="rest_day_remark_two"
                          value={formData.rest_day_remark_two}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          type="number"
                          min="0"
                          name="rest_day_over_time"
                          onChange={handleChange}
                          value={formData.rest_day_over_time}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">New Year Over Time</div>
                      <div className="inputs">
                        <input
                          name="new_year_remark"
                          value={formData.new_year_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          name="new_year_remark_two"
                          value={formData.new_year_remark_two}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />
                        <input
                          type="number"
                          min="0"
                          name="new_year_over_time"
                          onChange={handleChange}
                          value={formData.new_year_over_time}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">KPI Bonus</div>
                      <div className="inputs">
                        <input
                          type="number"
                          min="0"
                          name="kpi_bonus"
                          onChange={handleChange}
                          value={formData.kpi_bonus}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>
                    <h5 className="text-underline text-uppercase">
                      <b>
                        <u>Allowances</u>
                      </b>
                    </h5>

                    <div className="data_group">
                      <div className="item_label">Food Allowance</div>
                      <div className="inputs">
                        <input
                          type="number"
                          min="0"
                          name="food_allowance"
                          onChange={handleChange}
                          value={formData.food_allowance}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Night Shift</div>
                      <div className="inputs">
                        <input
                          type="number"
                          min="0"
                          name="night_shift"
                          onChange={handleChange}
                          value={formData.night_shift}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group d-none">
                      <div className="item_label">Insurance</div>
                      <div className="inputs">
                        <input
                          type="number"
                          min="0"
                          name="insurance"
                          onChange={handleChange}
                          value={formData.insurance}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-right">
                    <span>Total Income</span>{" "}
                    <input
                      name="total_income"
                      value={totalIncome}
                      type="number"
                      min="0"
                      step=".01"
                      disabled
                      className="text-right"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div class="card width_100">
                  <div class="card-header text-center">Deduction</div>
                  <div class="card-body">
                    <div className="data_group">
                      <div className="item_label">Hold Basic Salary</div>
                      <div className="inputs">
                        <input
                          name="hold_basic_salary_remark"
                          value={formData.hold_basic_salary_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          min="0"
                          name="hold_basic_salary"
                          onChange={handleChange}
                          value={formData.hold_basic_salary}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Unpaid Leave</div>
                      <div className="inputs">
                        <input
                          name="unpaid_leave_remark"
                          value={formData.unpaid_leave_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          min="0"
                          name="unpaid_leave"
                          onChange={handleChange}
                          value={formData.unpaid_leave}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Salary Advance</div>
                      <div className="inputs">
                        <input
                          type="number"
                          min="0"
                          name="salary_advance"
                          onChange={handleChange}
                          value={formData.salary_advance}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Mistake</div>
                      <div className="inputs">
                        <input
                          name="mistake_remark"
                          value={formData.mistake_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          min="0"
                          name="mistake"
                          onChange={handleChange}
                          value={formData.mistake}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>

                    <div className="data_group">
                      <div className="item_label">Penalty</div>
                      <div className="inputs">
                        <input
                          name="penalty_remark"
                          value={formData.penalty_remark}
                          onChange={handleChange}
                          type="text"
                          placeholder="Remark"
                        />

                        <input
                          type="number"
                          min="0"
                          name="penalty"
                          onChange={handleChange}
                          value={formData.penalty}
                          placeholder="0.00"
                          step=".01"
                          className="text-right"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="card-footer text-right">
                    <span>Total Deduction</span>{" "}
                    <input
                      value={totalDeduction}
                      type="number"
                      min="0"
                      step=".01"
                      disabled
                      className="text-right"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div class="card width_100">
                  <div class="card-header text-center">
                    Release Currency Payment
                  </div>
                  <div className="card-body">
                    <div className="rate_table">
                      <div className="datarow head">
                        <div className="item">Amount</div>
                        <div className="item">Currency / Rate</div>
                        <div className="item">Total</div>
                      </div>
                      {rows.map((row, index) => (
                        <div key={index} className="datarow">
                          <div className="item">
                            <span>{index + 1} </span>
                            <input
                              name="amount"
                              type="number"
                              min="0"
                              step=".01"
                              placeholder="0.00"
                              className="text-right"
                              value={row.amount}
                              onChange={(e) =>
                                handleAmountChange(index, e.target.value)
                              }
                            />
                          </div>
                          <div className="item d-flex">
                            <select
                              value={row.currency}
                              onChange={(e) =>
                                handleRateChange(index, e.target.value)
                              }
                              name="currency"
                            >
                              <option value="">Select</option>
                              {currencies.map((item, index) => (
                                <option key={index} value={item.code}>
                                  {item.code}
                                </option>
                              ))}
                            </select>

                            <input
                              name="rate"
                              type="number"
                              min="0"
                              step=".01"
                              placeholder="0.00"
                              value={row.rate}
                              className="text-right"
                              onChange={(e) => {
                                const newRows = [...rows];
                                newRows[index].rate = parseFloat(
                                  e.target.value
                                );
                                setRows(newRows);
                              }}
                            />
                          </div>
                          <div className="item">
                            <input
                              name="total"
                              type="number"
                              min="0"
                              step=".01"
                              placeholder="0.00"
                              value={calculateTotal(
                                row.amount,
                                row.rate
                              ).toFixed(2)}
                              readOnly
                              className="text-right"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div class="card width_100">
                  <div class="card-footer text-right">
                    <span>Net Salary</span>{" "}
                    <select
                      onChange={handleChange}
                      name="currency"
                      value={formData.currency}
                      disabled
                    >
                      <option value="">Select</option>
                      {currencies.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.code}
                        </option>
                      ))}
                    </select>
                    <input
                      className="text-right"
                      value={netSalary}
                      type="number"
                      min="0"
                      step=".01"
                      disabled
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button variant="danger" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="success" onClick={handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
