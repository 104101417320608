import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import moment from "moment";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../../elements/Spinner";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";

export default function CreateLeaves() {
  const [spinner, setSpinner] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    from_time: "",
    to_time: "",
    nationality: "",
    leave_type: "",
    reason: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    var valid = true;
    var newErrors = {};
    if (!formData.from_time) {
      newErrors = { ...newErrors, from_time: "From is required" };
      valid = false;
    }
    if (!formData.to_time) {
      newErrors = { ...newErrors, to_time: "To is required" };
      valid = false;
    }
    if (!formData.nationality) {
      newErrors = { ...newErrors, nationality: "Nationality is required" };
      valid = false;
    }
    if (!formData.leave_type) {
      newErrors = { ...newErrors, leave_type: "Leave Type is required" };
      valid = false;
    }
    if (!formData.reason) {
      newErrors = { ...newErrors, reason: "Reason is required" };
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setSpinner(true);
      var response = await api.post("/leaves-create", formData);
      console.log(response.data);
      if (response.data.status == "success") {
        history.push("/leaves");
      } else {
        setErrors(response.data.errors);
      }

      setSpinner(false);
    }
  };

  const [entitlement, setEntitlement] = useState([]);
  const getEntitlement = async () => {
    var response = await api.get("/leaves-entitlement");
    if (response.status === 200 && response.data) {
      setEntitlement(response.data.data);
    } else {
      console.log(response.data);
    }
  };
  useEffect(async () => {
    getEntitlement();
  }, []);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <form onSubmit={handleSubmit}>
        <div className="create_page_heading">
          <div className="page_name">Create Leave Application</div>
          <div className="actions">
            <button type="submit" className="publish_btn btn btn-success">
              Submit
            </button>
            <Link to="/leaves" className="btn btn-danger rounded-circle">
              <i className="fal fa-times"></i>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="employee_lists leave_list">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead className="bg-success text-white">
                    <tr>
                      <th>Entitlement</th>
                      <th>Day</th>
                      <th>Start From</th>
                      <th>Valid To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entitlement.length > 0
                      ? entitlement.slice(0, 3).map((item, index) => (
                          <tr>
                            <td>{item.leave_type}</td>
                            <td>{item.num_of_day}</td>
                            <td>{moment(item.from_time).format("ll")}</td>
                            <td>{moment(item.to_time).format("ll")}</td>
                          </tr>
                        ))
                      : "No Entitlement Here"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <hr></hr>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="rolePermission">From:</label>
              <input
                type="date"
                name="from_time"
                value={formData.from_time}
                onChange={handleChange}
                className="form-control"
              />
              {errors.from_time && (
                <div className="errorMsg">{errors.from_time}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="rolePermission">To:</label>
              <input
                type="date"
                name="to_time"
                value={formData.to_time}
                onChange={handleChange}
                className="form-control"
              />
              {errors.to_time && (
                <div className="errorMsg">{errors.to_time}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="team">Nationality:</label>
              <select
                name="nationality"
                value={formData.nationality}
                onChange={handleChange}
                className="form-select"
              >
                <option value="">Select nationality</option>
                <option value="Full Day">Full Day</option>
                <option value="Half Day">Half Day</option>
              </select>
              {errors.nationality && (
                <div className="errorMsg">{errors.nationality}</div>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="team">Leave Type:</label>
              <select
                name="leave_type"
                value={formData.leave_type}
                onChange={handleChange}
                className="form-select"
              >
                <option value="">Select leave Type</option>
                <option value="Rest Day">Rest Day</option>
                <option value="Annual Leave">Annual Leave</option>
                <option value="Medical Leave">Medical Leave</option>
                <option value="Unpaid Leave">Unpaid Leave</option>
                <option value="Emergency Leave">Emergency Leave</option>
              </select>
              {errors.leave_type && (
                <div className="errorMsg">{errors.leave_type}</div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="message">Reason:</label>

              <textarea
                placeholder="Write Reason"
                className="form-control"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
              ></textarea>
              {errors.reason && <div className="errorMsg">{errors.reason}</div>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
