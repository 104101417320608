import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import moment from "moment";
import Spinner from "../../../elements/Spinner";
import Pagination from "../../../elements/Pagination";

export default function SalaryAdvances(props) {
  const [page, setPage] = useState(1);
  const onPageChange = (page) => {
    setPage(page);
  };
  const [paginationData, setPaginationData] = useState([]);

  const [sortOrder, setSortOrder] = useState("newest_to_oldest");

  const toggleSortOrder = () => {
    if (sortOrder === "newest_to_oldest") {
      setSortOrder("oldest_to_newest");
    } else {
      setSortOrder("newest_to_oldest");
    }
  };

  // dffdfdfd
  const [pendingPage, setPendingPage] = useState(1);
  const onPendingPageChange = (page) => {
    setPendingPage(page);
  };
  const [paginationDataPending, setPaginationDataPending] = useState([]);

  const [filterData, setFilterData] = useState({
    filter_year: "",
    filter_month: "",
  });

  const handleChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const [spinner, setSpinner] = useState(false);
  const [leaveTab, setLeaveTab] = useState("application_history");

  const [salaries, setSalaries] = useState([]);

  const getAdvanceSalaries = async () => {
    setSpinner(true);
    var response = await api.post("/salary-advances", {
      page: page,
      perPage: 10,
      filter_year: filterData.filter_year,
      filter_month: filterData.filter_month,
      sort_order: sortOrder,
    });

    if (response.status === 200 && response.data) {
      setSalaries(response.data.data);
      setPaginationData(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [pendingSalaries, setPendingSalaries] = useState([]);

  const getPendingSalaries = async () => {
    setSpinner(true);
    var response = await api.post("/salary-advances-pending", {
      page: pendingPage,
      perPage: 10,
      filter_year: filterData.filter_year,
      filter_month: filterData.filter_month,
      sort_order: sortOrder,
    });

    if (response.status === 200 && response.data) {
      setPendingSalaries(response.data.data);
      setPaginationDataPending(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  useEffect(async () => {
    getAdvanceSalaries();
  }, [page, filterData, sortOrder]);

  useEffect(async () => {
    getPendingSalaries();
  }, [pendingPage, filterData, sortOrder]);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">Manage Salary Advance Applications</div>
        <div className="actions">
          <Link
            to="/salary-advances-create"
            className="btn btn-success rounded-circle"
          >
            <i className="fal fa-plus"></i>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>By Month & Year</label>
            <div className="row">
              <div className="col-lg-6">
                <select
                  name="filter_year"
                  value={filterData.filter_year}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="">Select Year</option>
                  {Array.from({ length: 12 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option value={String(year)} key={String(year)}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-lg-6">
                <select
                  name="filter_month"
                  onChange={handleChange}
                  value={filterData.filter_month}
                  className="form-select"
                >
                  <option value="">Select Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-lg-8">
          <div className="create_employee_tabs">
            <button
              className={
                leaveTab === "application_history"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("application_history")}
            >
              Pending Request
            </button>
            <button
              className={
                leaveTab === "attendance_record"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("attendance_record")}
            >
              Applications History
            </button>
          </div>
        </div>
      </div>

      {leaveTab === "application_history" && (
        <>
          <div className="employee_lists">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>#</th>
                    <th onClick={toggleSortOrder} style={{ cursor: "pointer" }}>
                      <i className="fa fa-sort"></i> Application Date
                    </th>

                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingSalaries.length > 0
                    ? pendingSalaries.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item.date).format("LL")}</td>
                          <td>{item.currency}</td>
                          <td>{item.amount}</td>
                          <td>{item.status}</td>
                          <td>{item.reason}</td>
                        </tr>
                      ))
                    : "No Data found"}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <br />
          <div className="text-center">
            <Pagination
              data={paginationDataPending}
              onPageChange={onPendingPageChange}
            />
          </div>
        </>
      )}

      {leaveTab === "attendance_record" && (
        <>
          <div className="employee_lists">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>#</th>
                    <th onClick={toggleSortOrder} style={{ cursor: "pointer" }}>
                      <i className="fa fa-sort"></i> Application Date
                    </th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Approved/Reject Date</th>
                    <th>Status</th>
                    <th>Reason</th>
                    <th>Action By</th>
                  </tr>
                </thead>
                <tbody>
                  {salaries.length > 0
                    ? salaries.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item.date).format("LL")}</td>
                          <td>{item.currency}</td>
                          <td>{item.amount}</td>
                          <td>
                            {item.action_date
                              ? moment(item.action_date).format("LL")
                              : "N/A"}
                          </td>
                          <td>{item.status}</td>
                          <td>{item.reason}</td>
                          <td>
                            {item.action_by_name ? item.action_by_name : "N/A"}
                          </td>
                        </tr>
                      ))
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <br />
          <div className="text-center">
            <Pagination data={paginationData} onPageChange={onPageChange} />
          </div>
        </>
      )}
    </div>
  );
}
