import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Spinner from "../../../elements/Spinner";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";

export default function CreateSalaryAdvance(props) {
  const [spinner, setSpinner] = useState(false);
  const history = useHistory();
  const [formData, setFormData] = useState({
    date: "",
    currency: "",
    amount: "",
    reason: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    var valid = true;
    var newErrors = {};

    if (!formData.amount) {
      newErrors = { ...newErrors, amount: "Amount is required" };
      valid = false;
    }
    if (!formData.currency) {
      newErrors = { ...newErrors, currency: "Currency is required" };
      valid = false;
    }

    if (!formData.reason) {
      newErrors = { ...newErrors, reason: "Reason is required" };
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setSpinner(true);
      var response = await api.post("/salary-advances-create", formData);
      console.log(response.data);
      if (response.data.status == "success") {
        history.push("/salary-advances");
      } else {
        setErrors(response.data.errors);
      }

      setSpinner(false);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };
  useEffect(async () => {
    getCurrencies();
  }, []);
  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <form onSubmit={handleSubmit}>
        <div className="create_page_heading">
          <div className="page_name">Salary Advance Application</div>
          <div className="actions">
            <button type="submit" className="publish_btn btn btn-success">
              Submit
            </button>
            <Link to="/leaves" className="btn btn-danger rounded-circle">
              <i className="fal fa-times"></i>
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group">
              <label>Short Name:</label>
              <input
                type="text"
                value={props.userData.short_name}
                className="form-control"
                readOnly
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Date:</label>
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Team:</label>
              <input
                className="form-control"
                type="text"
                readOnly
                value={props.userData?.team}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Request Amount:</label>
              <input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                className="form-control"
                step=".01"
              />

              {errors.amount && <div className="errorMsg">{errors.amount}</div>}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <label>Currency:</label>

              <select
                className="form-select"
                onChange={handleChange}
                name="currency"
                value={formData.currency}
              >
                <option value="">Select Currency</option>
                {currencies.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code} ({item.name})
                  </option>
                ))}
              </select>

              {errors.currency && (
                <div className="errorMsg">{errors.currency}</div>
              )}
            </div>
          </div>

          <div className="col-lg-12">
            <div className="form-group">
              <label htmlFor="message">Reason:</label>
              <textarea
                placeholder="Write Reason"
                className="form-control"
                name="reason"
                value={formData.reason}
                onChange={handleChange}
              ></textarea>
              {errors.reason && <div className="errorMsg">{errors.reason}</div>}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
