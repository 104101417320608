import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faLock,
  faCog,
  faBullhorn,
  faUsers,
  faBook,
  faFileAlt,
  faTools,
} from "@fortawesome/free-solid-svg-icons";

const DashboardMenu = (props) => {
  const location = useLocation();
  const pathname = location.pathname.slice(1);

  const [showAnnouncementSubmenu, setShowAnnouncementSubmenu] = useState(false);
  const [showEmployeeSubmenu, setShowEmployeeSubmenu] = useState(false);
  const [showNotebookSubmenu, setShowNotebookSubmenu] = useState(false);
  const [showRolePermissionSubmenu, setShowRolePermissionSubmenu] =
    useState(false);
  const [showReportSubmenu, setShowReportSubmenu] = useState(false);
  const [showSettingsSubmenu, setShowSettingsSubmenu] = useState(false);

  return (
    <ul className="dashboard-menu ">
      <li>
        <div className="author">
          <img alt="" src={props.userData?.profile_picture} />
          <div className="title">
            <Link to="/profile">{props.userData.short_name}</Link>
          </div>
          <div className="title role">{props.userData.role}</div>
        </div>
      </li>
      <li
        className={
          pathname === "dashboard"
            ? "dashboard-menu-item active"
            : "dashboard-menu-item"
        }
      >
        <Link to="/dashboard">
          <FontAwesomeIcon icon={faTools} /> Dashboard
        </Link>
      </li>
      <li
        className={
          pathname === "announcements" || pathname === "announcements-create"
            ? "dashboard-menu-item with-submenu active"
            : "dashboard-menu-item with-submenu"
        }
      >
        <a onClick={() => setShowAnnouncementSubmenu(!showAnnouncementSubmenu)}>
          <FontAwesomeIcon icon={faBullhorn} /> Announcement
        </a>
        {showAnnouncementSubmenu && (
          <ul className="submenu ">
            {props.rolePermission?.Announcement?.view_download ? (
              <li
                className={
                  pathname === "announcements"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/announcements">View</Link>
              </li>
            ) : (
              ""
            )}
            {props.rolePermission?.Announcement?.add_edit ? (
              <li
                className={
                  pathname === "announcements-create"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/announcements-create">Create</Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </li>
      <li
        className={
          pathname === "employees" ||
          pathname === "employees-create" ||
          pathname === "employees-edit" ||
          pathname === "leaves" ||
          pathname === "leaves-create" ||
          pathname === "leaves-actions" ||
          pathname === "salary-advances" ||
          pathname === "salary-advances-action"
            ? "dashboard-menu-item with-submenu active"
            : "dashboard-menu-item with-submenu"
        }
      >
        <a onClick={() => setShowEmployeeSubmenu(!showEmployeeSubmenu)}>
          <FontAwesomeIcon icon={faUsers} /> Employees
        </a>
        {showEmployeeSubmenu && (
          <ul className="submenu ">
            {props.rolePermission?.Employee?.view_download ? (
              <li
                className={
                  pathname === "employees" ||
                  pathname === "employees-create" ||
                  pathname == "employees-edit"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/employees">Manage</Link>
              </li>
            ) : (
              ""
            )}

            <li
              className={
                pathname === "leaves" || pathname === "leaves-create"
                  ? "submenu-item active"
                  : "submenu-item"
              }
            >
              <Link to="/leaves">Leave</Link>
            </li>

            {props.rolePermission?.Employee?.view_download ? (
              <li
                className={
                  pathname === "leaves-actions"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/leaves-actions">Leave (Actions)</Link>
              </li>
            ) : (
              ""
            )}

            <li
              className={
                pathname === "salary-advances" ||
                pathname === "salary-advances-create"
                  ? "submenu-item active"
                  : "submenu-item"
              }
            >
              <Link to="/salary-advances">Salary Advance</Link>
            </li>
            {props.rolePermission?.Employee?.view_download ? (
              <li
                className={
                  pathname === "salary-advances-action"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/salary-advances-action">
                  Salary Advance (Actions)
                </Link>
              </li>
            ) : (
              ""
            )}

            <li
              className={
                pathname === "payrolls" ? "submenu-item active" : "submenu-item"
              }
            >
              <Link to="/payrolls">Payroll</Link>
            </li>
          </ul>
        )}
      </li>
      <li
        className={
          pathname === "notebooks" || pathname === "notebooks-create"
            ? "dashboard-menu-item with-submenu active"
            : "dashboard-menu-item with-submenu"
        }
      >
        <a onClick={() => setShowNotebookSubmenu(!showNotebookSubmenu)}>
          <FontAwesomeIcon icon={faBook} /> Note Books
        </a>
        {showNotebookSubmenu && (
          <ul className="submenu ">
            {props.rolePermission?.Notebook?.view_download ? (
              <li
                className={
                  pathname === "notebooks"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/notebooks">View</Link>
              </li>
            ) : (
              ""
            )}
            {props.rolePermission?.Notebook?.add_edit ? (
              <li
                className={
                  pathname === "notebooks-create"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/notebooks-create">Create</Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </li>
      {props.rolePermission?.Rolepermission?.view_download ? (
        <li
          className={
            pathname === "roles" ||
            pathname === "roles-create" ||
            pathname === "roles-edit" ||
            pathname === "teams" ||
            pathname === "teams-create" ||
            pathname === "teams-edit"
              ? "dashboard-menu-item with-submenu active"
              : "dashboard-menu-item with-submenu"
          }
        >
          <a
            onClick={() =>
              setShowRolePermissionSubmenu(!showRolePermissionSubmenu)
            }
          >
            <FontAwesomeIcon icon={faLock} /> Role Permission
          </a>
          {showRolePermissionSubmenu && (
            <ul className="submenu ">
              <li
                className={
                  pathname === "roles" ||
                  pathname === "roles-create" ||
                  pathname === "roles-edit"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/roles">Manage Role</Link>
              </li>
              <li
                className={
                  pathname === "teams" ||
                  pathname === "teams-create" ||
                  pathname === "teams-edit"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/teams">Manage Team</Link>
              </li>
            </ul>
          )}
        </li>
      ) : (
        ""
      )}

      <li
        className={
          pathname === "reports"
            ? "dashboard-menu-item with-submenu active"
            : "dashboard-menu-item with-submenu"
        }
      >
        <a onClick={() => setShowReportSubmenu(!showReportSubmenu)}>
          <FontAwesomeIcon icon={faFileAlt} /> Report
        </a>
        {showReportSubmenu && (
          <ul className="submenu">
            {props.rolePermission?.Report?.view_download ? (
              <li className="submenu-item">
                <Link to="/dashboard/report/view">View</Link>
              </li>
            ) : (
              ""
            )}
            {props.rolePermission?.Report?.add_edit ? (
              <li className="submenu-item">
                <Link to="/dashboard/report/create">Create</Link>
              </li>
            ) : (
              ""
            )}
          </ul>
        )}
      </li>

      {props.rolePermission?.Settings?.view_download ? (
        <li
          className={
            pathname === "settings" || pathname === "import-list"
              ? "dashboard-menu-item with-submenu active"
              : "dashboard-menu-item with-submenu"
          }
        >
          <a onClick={() => setShowSettingsSubmenu(!showSettingsSubmenu)}>
            <FontAwesomeIcon icon={faCog} /> Settings
          </a>
          {showSettingsSubmenu && (
            <ul className="submenu ">
              <li
                className={
                  pathname === "import-list"
                    ? "submenu-item active"
                    : "submenu-item"
                }
              >
                <Link to="/import-list">Import List</Link>
              </li>
            </ul>
          )}
        </li>
      ) : (
        ""
      )}
    </ul>
  );
};

export default DashboardMenu;
