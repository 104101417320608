import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "../../services/api";
import Spinner from "../../elements/Spinner";
import { Fragment } from "react";

export default function CreateTeam(props) {
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);

  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    };
    reader.readAsDataURL(selectedFile);
  };

  const [formData, setFormData] = useState({
    title: "",
    team_lead: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.title) {
      newErrors.title = "Title is required";
    }
    if (!formData.team_lead) {
      newErrors.team_lead = "Team Lead is required";
    }
    if (!selectedIds) {
      newErrors.employees = "Employees is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const data = new FormData();
      data.append("title", formData.title);
      data.append("team_lead", formData.team_lead);
      data.append("employees", selectedIds);
      data.append("photo", file);
      setSpinner(true);
      var response = await api.post("/teams-create", data);
      console.log(response.data);
      if (response.data.status == "success") {
        history.push("/teams");
      } else {
        setErrors(response.data.errors);
      }

      setSpinner(false);

      // Submit the form data to the server
    }
  };

  const [employees, setEmployees] = useState([]);

  const getEmployees = async () => {
    setSpinner(true);
    var response = await api.post("/employees");
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  // select employees

  const [searchValue, setSearchValue] = useState("");

  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  const selectEmployee = (event) => {
    var updatedList = [...selectedEmployees];
    var idList = [...selectedIds];
    var employee = employees.find((employee) => {
      return employee.id == event.target.value;
    });
    // if (event.target.checked) {
    //   updatedList = [...selectedEmployees, employee];
    //   var idList = [...selectedIds, event.target.value];
    // } else {
    //   updatedList.splice(selectedEmployees.indexOf(employee), 1);

    //   idList.splice(selectedIds.indexOf(event.target.value), 1);
    // }

    if (selectedIds.includes(event.target.value)) {    
      idList.splice(selectedIds.indexOf(event.target.value), 1);
      updatedList.splice(selectedIds.indexOf(event.target.value), 1);
    } else {
      idList = [...selectedIds, event.target.value];
      updatedList = [...selectedEmployees, employee];
    }

    setSelectedIds(idList);
    setSelectedEmployees(updatedList);
  };

  useEffect(async () => {
    getEmployees();
  }, []);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <form onSubmit={handleSubmit}>
        <div className="create_page_heading">
          <div className="page_name">Create New Team</div>
          <div className="actions">
            <button type="submit" className="publish_btn btn btn-success">
              Publish
            </button>
            <Link to="/teams" className="btn btn-danger rounded-circle">
              <i className="fal fa-times"></i>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <div className="form-group">
              <label>Cover Photo</label>
              <br />
              <label for="uploadPhoto">
                <div className="previw_upload_image">
                  {previewUrl && (
                    <img
                      style={{ height: "150px", width: "200px" }}
                      src={previewUrl}
                      alt="Preview"
                    />
                  )}
                  {!previewUrl && (
                    <img
                      style={{ height: "150px", width: "200px" }}
                      src={require("../../assets/images/upload.png").default}
                      alt="Preview"
                    />
                  )}
                  <i className="fas fa-camera"></i>
                </div>
              </label>
              <br />
              <input
                type="file"
                hidden
                id="uploadPhoto"
                onChange={handleFileChange}
              />
            </div>
            <br />
            <div className="form-group">
              <label>Team:</label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="form-control"
              />
              {errors.title && <div className="errorMsg">{errors.title}</div>}
            </div>

            <div className="form-group">
              <label htmlFor="team">Team Lead:</label>
              <select
                name="team_lead"
                value={formData.team_lead}
                onChange={handleChange}
                className="form-select"
              >
                <option value="">Select a Team Leader</option>
                {employees &&
                  employees.map((value, key) => (
                    <option value={value.id}>{value.full_name}</option>
                  ))}
              </select>
              {errors.team_lead && (
                <div className="errorMsg">{errors.team_lead}</div>
              )}
            </div>

            <div className="form-group">
              <label>
                Invite Employees:{" "}
                <span className="text-muted">
                  (search with full name, short name or staff Id)
                </span>
              </label>
              <input
                placeholder="Search Employee...."
                onChange={(e) => setSearchValue(e.target.value)}
                type="text"
                value={searchValue}
                className="form-control"
              />
              {errors.employees && (
                <div className="errorMsg">{errors.employees}</div>
              )}
            </div>
            <div className="employee_list">
              <div className="all_employee">
                {searchValue ? (
                  <>
                    {employees
                      .filter((item) => {
                        if (!searchValue) return false;
                        if (
                          item.full_name.includes(searchValue) ||
                          item.short_name.includes(searchValue) ||
                          item.employee_id.includes(searchValue)
                        ) {
                          return true;
                        }
                      })
                      .map((employee) => (
                        <li>
                          <label
                            key={employee.id}
                            for={"employee_" + employee.id}
                          >
                            <Fragment>
                              <div className="single_employee">
                                <div className="color_title">
                                  {employee.full_name}
                                </div>
                                <input
                                  id={"employee_" + employee.id}
                                  type="checkbox"
                                  onChange={selectEmployee}
                                  value={employee.id}
                                  hidden
                                />
                              </div>
                            </Fragment>
                          </label>
                        </li>
                      ))}
                  </>
                ) : (
                  <>
                    {employees.map((employee) => (
                      <li>
                        <label
                          key={employee.id}
                          for={"employee_" + employee.id}
                        >
                          <Fragment>
                            <div className="single_employee">
                              <div className="color_title">
                                {employee.full_name}
                              </div>
                              <input
                                id={"employee_" + employee.id}
                                type="checkbox"
                                onChange={selectEmployee}
                                value={employee.id}
                                hidden
                              />
                            </div>
                          </Fragment>
                        </label>
                      </li>
                    ))}
                  </>
                )}
              </div>
              <div className="selected_employees">
                <h5>Selected Employees</h5>
                {selectedEmployees.map((employee) => (
                  <li>
                    <label key={employee.id} for={"employee_" + employee.id}>
                      <Fragment>
                        <div className="single_employee">
                          <div className="color_title">
                            {employee.full_name}{" "}
                            <i className="fa fa-times text-danger"></i>
                          </div>
                          <input
                            id={"employee_" + employee.id}
                            type="checkbox"
                            onChange={selectEmployee}
                            value={employee.id}
                            hidden
                          />
                        </div>
                      </Fragment>
                    </label>
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}
