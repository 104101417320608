import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import api from "../../../services/api";
import moment from "moment";
import Spinner from "../../../elements/Spinner";
import Pagination from "../../../elements/Pagination";

export default function Leaves(props) {
  const [page, setPage] = useState(1);
  const onPageChange = (page) => {
    setPage(page);
  };
  const [paginationData, setPaginationData] = useState([]);

  const [spinner, setSpinner] = useState(false);
  const [leaveTab, setLeaveTab] = useState("application_history");

  const [leaves, setLeaves] = useState([]);

  const getLeaves = async () => {
    setSpinner(true);
    var response = await api.post("/leaves", {
      page: page,
      perPage: 10,
    });

    if (response.status === 200 && response.data) {
      setLeaves(response.data.data);
      setPaginationData(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [filterData, setFilterData] = useState({
    filter_year: "",
    filter_month: "",
  });

  const handleChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const [summery, setSummery] = useState({});
  const [attendance, setAttendance] = useState([]);
  const getAttendance = async () => {
    setSpinner(true);
    var response = await api.post("/my-attendance", {
      filter_year: filterData.filter_year,
      filter_month: filterData.filter_month,
    });
    if (response.status === 200 && response.data) {
      setAttendance(response.data.data);
      setSummery(response.data.total_summery);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [entitlement, setEntitlement] = useState([]);
  const getEntitlement = async () => {
    var response = await api.get("/leaves-entitlement");
    if (response.status === 200 && response.data) {
      setEntitlement(response.data.data);
    } else {
      console.log(response.data);
    }
  };
  

  useEffect(async () => {
    getEntitlement();
  }, []);

  useEffect(async () => {
    getAttendance();
  }, [filterData]);

  useEffect(async () => {
    getLeaves();
  }, [page]);

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">Manage Leave Applications</div>
        <div className="actions">
          <Link to="/leaves-create" className="btn btn-success rounded-circle">
            <i className="fal fa-plus"></i>
          </Link>
        </div>
      </div>

      <div className="row">
        {leaveTab === "application_history" && (
          <div className="col-lg-6">
            <div className="employee_lists leave_list">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead className="bg-success text-white">
                    <tr>
                      <th>Entitlement</th>
                      <th>Day</th>
                      <th>Start From</th>
                      <th>Valid To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entitlement.length > 0
                      ? entitlement.slice(0, 3).map((item, index) => (
                          <tr>
                            <td>{item.leave_type}</td>
                            <td>{item.num_of_day}</td>
                            <td>{moment(item.from_time).format("ll")}</td>
                            <td>{moment(item.to_time).format("ll")}</td>
                          </tr>
                        ))
                      : "No Entitlement Here"}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {leaveTab === "attendance_record" && (
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group">
                <label>By Month & Year *</label>
                <div className="row">
                  <div className="col-lg-6">
                    <select
                      name="filter_month"
                      onChange={handleChange}
                      value={filterData.filter_month}
                      className="form-select"
                    >
                      <option value="">Select Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                    <select
                      name="filter_year"
                      value={filterData.filter_year}
                      onChange={handleChange}
                      className="form-select"
                    >
                      <option value="">Select Year</option>
                      {Array.from({ length: 12 }, (_, i) => {
                        const year = new Date().getFullYear() - i;
                        return (
                          <option value={String(year)} key={String(year)}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row attendance_summery">
                <div className="col-4">
                  <div className="single_box">
                    <div className="item" style={{ background: "#e6e6e6" }}>
                      {summery.working}
                    </div>
                    <h5 className="text-center">Working Days</h5>
                  </div>
                </div>
                <div className="col-4">
                  <div className="single_box">
                    <div className="item" style={{ background: "#d0cee3" }}>
                      {summery.rest}
                    </div>
                    <h5 className="text-center">Rest Day</h5>
                  </div>
                </div>
                <div className="col-4">
                  <div className="single_box">
                    <div className="item" style={{ background: "#6d8764" }}>
                      {summery.annual}
                    </div>
                    <h5 className="text-center">Annual Leave</h5>
                  </div>
                </div>
                <div className="col-4">
                  <div className="single_box">
                    <div className="item" style={{ background: "#d90073" }}>
                      {summery.medical}
                    </div>
                    <h5 className="text-center">Medical Leave</h5>
                  </div>
                </div>
                <div className="col-4">
                  <div className="single_box">
                    <div className="item" style={{ background: "#b0ddf0" }}>
                      {summery.unpaid}
                    </div>
                    <h5 className="text-center">Unpaid Leave</h5>
                  </div>
                </div>
                <div className="col-4">
                  <div className="single_box">
                    <div className="item" style={{ background: "#fa6900" }}>
                      {summery.emergency}
                    </div>
                    <h5 className="text-center">Emergency Leave</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <br />
      <br />
      <div className="row">
        <hr></hr>
        <div className="col-lg-8">
          <div className="create_employee_tabs">
            <button
              className={
                leaveTab === "application_history"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("application_history")}
            >
              Application History
            </button>
            <button
              className={
                leaveTab === "attendance_record"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("attendance_record")}
            >
              Attendance Record
            </button>
          </div>
        </div>
      </div>

      {leaveTab === "application_history" && (
        <>
          <div className="employee_lists">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>#</th>
                    <th>Leave Type</th>
                    <th>Apply Date</th>
                    <th>Approved/Reject Date</th>
                    <th>Action By</th>
                    <th>Status</th>
                    <th>Reason</th>
                    {/* <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {leaves.length > 0
                    ? leaves.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.leave_type}</td>
                          <td>{moment(item.created_at).format("LL")}</td>
                          <td>{moment(item.action_date).format("LL")}</td>
                          <td>{item.action_by_name}</td>
                          <td>{item.status}</td>
                          <td>{item.reason}</td>
                          {/* <td>
                          <Link to="/leaves-edit">
                            <i className="fa fa-pen"></i>
                          </Link>
                        </td> */}
                        </tr>
                      ))
                    : "No Leaves Here"}
                </tbody>
              </table>
            </div>
          </div>
          <br />
          <br />
          <div className="text-center">
            <Pagination data={paginationData} onPageChange={onPageChange} />
          </div>
        </>
      )}

      {leaveTab === "attendance_record" && (
        <div className="employee_lists attendance_record_table">
          <div className="table-responsive">
            <table class="table table-bordered">
              <thead className="bg-dark text-white">
                <tr>
                  <th>Month</th>
                  <th>1</th>
                  <th>2</th>
                  <th>3</th>
                  <th>4</th>
                  <th>5</th>
                  <th>6</th>
                  <th>7</th>
                  <th>8</th>
                  <th>9</th>
                  <th>10</th>
                  <th>11</th>
                  <th>12</th>
                  <th>13</th>
                  <th>14</th>
                  <th>15</th>
                  <th>16</th>
                  <th>17</th>
                  <th>18</th>
                  <th>19</th>
                  <th>20</th>
                  <th>21</th>
                  <th>22</th>
                  <th>23</th>
                  <th>24</th>
                  <th>25</th>
                  <th>26</th>
                  <th>27</th>
                  <th>28</th>
                  <th>29</th>
                  <th>30</th>
                  <th>31</th>
                </tr>
              </thead>
              <tbody>
                {attendance.map((item, index) => (
                  <tr key={index}>
                    <td>{item.month}</td>
                    {item?.date_list.map((leave, index2) => (
                      <td
                        className={
                          leave.type === "Medical Leave"
                            ? "ml"
                            : leave.type === "Rest Day"
                            ? "rd"
                            : leave.type === "Annual Leave"
                            ? "al"
                            : leave.type === "Unpaid Leave"
                            ? "ul"
                            : leave.type === "Emergency Leave"
                            ? "el"
                            : ""
                        }
                        key={index2}
                      >
                        {leave.type === "Medical Leave"
                          ? "ML"
                          : leave.type === "Rest Day"
                          ? "RD"
                          : leave.type === "Annual Leave"
                          ? "AL"
                          : leave.type === "Unpaid Leave"
                          ? "UL"
                          : leave.type === "Emergency Leave"
                          ? "EL"
                          : ""}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
