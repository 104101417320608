import React, { useState, useEffect, useContext } from "react";
import { faEllipsisH, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";
import moment from "moment";
import auth from "../services/auth";
import { Modal, Button, Badge } from "react-bootstrap";
import Swal from "sweetalert2";
import AppContext from "../contexts/AppContext";

export default function Dashboard(props) {
  const history = useHistory();

  const { updateUserObj } = useContext(AppContext);
  const [unreadAnnouncements, setUnreadAnnouncements] = useState(0);
  const [announcements, setAnnouncements] = useState([]);
  const getAnnouncements = async () => {
    var response = await api.post("/announcements");
    if (response.status === 200 && response.data) {
      setAnnouncements(response.data.sent_announcements);
      setUnreadAnnouncements(response.data.unread_announcements);
    } else {
      console.log(response.data);
    }
  };

  const [activityModal, setActivityModal] = useState(false);
  const openActivityModal = () => {
    setActivityModal(true);
  };
  const closeActivityModal = () => {
    setActivityModal(false);
  };

  const [pendingLeaves, setPendingLeaves] = useState([]);
  const getPendingLeaves = async () => {
    var response = await api.post("/leaves-pending");
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setPendingLeaves(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  const [notebooks, setNotebooks] = useState([]);
  const getNotebooks = async () => {
    var response = await api.post("/notebooks");
    if (response.status === 200 && response.data) {
      setNotebooks(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  const [todayLeaves, setTodayLeaves] = useState([]);
  const getTodayLeaves = async () => {
    var response = await api.get("/leaves-today");
    if (response.status === 200 && response.data) {
      setTodayLeaves(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  const [activities, setActivities] = useState([]);
  const getActivities = async () => {
    var response = await api.get("/activities");
    if (response.status === 200 && response.data) {
      setActivities(response.data.activities);
    } else {
      console.log(response.data);
    }
  };

  const [entitlement, setEntitlement] = useState([]);
  const getEntitlement = async () => {
    var response = await api.get("/leaves-entitlement");
    if (response.status === 200 && response.data) {
      setEntitlement(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  useEffect(async () => {
    getPendingLeaves();
    getAnnouncements();
    getNotebooks();
    getTodayLeaves();
    getActivities();
    getEntitlement();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (props.userData?.role !== "Boss & HR") {
        Swal.fire({
          icon: "error",
          title: "Access Denied",
          text: "This System is exclusively for Bosses & HR. Please contact your administrator for access.",
          confirmButtonText: "Logout",
          reverseButtons: true,
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await auth.logout();
            await updateUserObj();
          }
        });
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [history, props.userData?.role]);

  return (
    <div className="create_edit_page">
      <div className="create_page_heading">
        <div className="page_name">Dashboard</div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <section className="activity_card">
            <div className="timeline-header">
              <span> Overview</span>
            </div>
            <ul className="overview">
              <li>
                Last Login :{" "}
                <span className="text-muted">
                  {moment(props.userData?.last_login_at).format("LLL")}
                </span>
              </li>
              <li>
                Position :{" "}
                <span className="text-muted">{props.userData?.position}</span>
              </li>
              <li>
                Position Grade :{" "}
                <span className="text-muted">
                  {props.userData?.employement_data?.position_grade}
                </span>
              </li>
              <li>
                Role Permission :{" "}
                <span className="text-muted">{props.userData.role}</span>
              </li>
            </ul>
          </section>
        </div>

        <div className="col-md-6">
          <section className="activity_card">
            <div className="timeline-header">
              <span>
                {" "}
                Announcement{" "}
                {unreadAnnouncements ? (
                  <Badge>
                    Unread{" "}
                    <span
                      className="round_count"
                      style={{
                        background: "red",
                        padding: "2px 6px",
                        // width: "15px",
                        borderRadius: "50%",
                        position: "absolute",
                        top: "10px",
                      }}
                    >
                      {unreadAnnouncements}
                    </span>
                  </Badge>
                ) : (
                  ""
                )}
              </span>
              <span>
                <Link to="/announcements">
                  <FontAwesomeIcon icon={faAngleRight} />
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </span>
            </div>

            <div className="announcements_lists">
              {announcements.length > 0
                ? announcements.slice(0, 3).map((item, index) => (
                    <div className="single_announcement small">
                      <div className="item">
                        <img
                          className="rounded-circle"
                          alt=""
                          src={item.announcement.user_photo}
                        />
                      </div>
                      <div className="item">
                        <h5>
                          {item.announcement?.title}{" "}
                          {item.is_read > 0 ? "" : <Badge>New</Badge>}
                        </h5>
                        <div className="credentials">
                          <span className="link-primary">
                            {item.announcement?.user_name}
                          </span>
                          <span className="margin_left_30 text-muted">
                            {moment(item.announcement?.created_at).format(
                              "lll"
                            )}
                          </span>
                        </div>
                      </div>
                      <div className="item text-end">
                        {item.announcement?.pinned > 0 ? (
                          <button
                            title="Pinned"
                            className="btn btn-info rounded-circle mr-10"
                          >
                            <i class="fal fa-thumbtack"></i>
                          </button>
                        ) : (
                          ""
                        )}
                        <Link
                          to={"/announcements/" + item.announcement?.id}
                          className="btn btn-primary rounded-circle"
                        >
                          <i class="fal fa-paperclip"></i>
                        </Link>
                      </div>
                    </div>
                  ))
                : "No Announcements Here"}
            </div>
          </section>
        </div>
        <div className="col-md-8">
          <section className="activity_card">
            <div className="timeline-header">
              <span>Leave Entitlement</span>
              <span>
                <Link
                  to="/leaves-create"
                  className="btn btn-success rounded-circle mr-10"
                >
                  <i className="fal fa-plus"></i>
                </Link>
                <Link to="/leaves">
                  <FontAwesomeIcon icon={faAngleRight} />
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </span>
            </div>
            <div className="employee_lists leave_list">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead className="bg-success text-white">
                    <tr>
                      <th>Entitlement</th>
                      <th>Day</th>
                      <th>Start From</th>
                      <th>Valid To</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entitlement.length > 0
                      ? entitlement.slice(0, 3).map((item, index) => (
                          <tr>
                            <td>{item.leave_type}</td>
                            <td>{item.num_of_day}</td>
                            <td>{moment(item.from_time).format("ll")}</td>
                            <td>{moment(item.to_time).format("ll")}</td>
                          </tr>
                        ))
                      : "No Entitlement Here"}
                  </tbody>
                </table>
              </div>
            </div>
          </section>
        </div>
        {props.rolePermission?.Employee?.approved_reject ? (
          <div className="col-md-6">
            <section className="activity_card">
              <div className="timeline-header">
                <span> Recent Activities</span>
                <span>
                  <FontAwesomeIcon
                    onClick={openActivityModal}
                    icon={faEllipsisH}
                  />
                </span>
              </div>
              <ul className="timeline">
                {activities.length > 0
                  ? activities.slice(0, 3).map((item, index) => (
                      <li className="timeline-item">
                        <h5 className="fw-bold">
                          {item.user_name} has {item.activity_type}
                        </h5>
                        <p className="text-muted mb-3">
                          {moment(item.created_at).format("LLL")}{" "}
                          <Link to={"/leaves-actions/" + item.leave_id}>
                            Details
                          </Link>
                        </p>
                      </li>
                    ))
                  : "No Activities found"}
              </ul>
            </section>
          </div>
        ) : (
          ""
        )}
        {props.rolePermission?.Employee?.approved_reject ? (
          <div className="col-md-6">
            <section className="activity_card">
              <div className="timeline-header">
                <span>Who's on leave today?</span>
                <span>
                  <Link to="/leaves-actions">
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </Link>
                </span>
              </div>
              <div className="leave_list">
                <table class="table text-center">
                  <thead>
                    <tr>
                      <th>Staff Nick Name</th>
                      <th>Leave Type</th>
                      <th>Leave Duration</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todayLeaves.length > 0
                      ? todayLeaves.map((item, index) => (
                          <tr>
                            <td>{item.user?.full_name}</td>
                            <td
                              className={
                                item.leave_type === "Rest Day"
                                  ? "rd"
                                  : item.leave_type === "Annual Leave"
                                  ? "al"
                                  : item.leave_type === "Medical Leave"
                                  ? "ml"
                                  : item.leave_type === "Unpaid Leave"
                                  ? "ul"
                                  : "el"
                              }
                            >
                              {item.leave_type}
                            </td>
                            <td>{item.num_of_day}</td>
                          </tr>
                        ))
                      : "No Leaves Here"}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        ) : (
          ""
        )}

        {props.rolePermission?.Employee?.approved_reject ? (
          <div className="col-md-6">
            <section className="activity_card">
              <div className="timeline-header">
                <span>
                  {" "}
                  Pending Requests
                  {/* <Badge>New</Badge> */}
                </span>
                <span>
                  <Link to="/leaves-actions">
                    <FontAwesomeIcon icon={faAngleRight} />
                    <FontAwesomeIcon icon={faAngleRight} />
                  </Link>
                </span>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Name</th>
                      <th>Reason</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingLeaves.length > 0
                      ? pendingLeaves.slice(0, 4).map((item, index) => (
                          <tr>
                            <td>{moment(item.created_at).format("LL")}</td>
                            <td>{item.employee_name}</td>
                            <td>{item.reason}</td>
                            <td>{item.status}</td>
                          </tr>
                        ))
                      : "No Pending Leaves Here"}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        ) : (
          ""
        )}

        <div className="col-md-6">
          <section className="activity_card">
            <div className="timeline-header">
              <span>
                {" "}
                Note Book
                {/* <Badge>New</Badge> */}
              </span>
              <span>
                <Link to="/notebooks">
                  <FontAwesomeIcon icon={faAngleRight} />
                  <FontAwesomeIcon icon={faAngleRight} />
                </Link>
              </span>
            </div>

            <div className="announcements_lists">
              {notebooks.length > 0
                ? notebooks.slice(0, 3).map((item, index) => (
                    <div className="single_announcement small">
                      <div className="item">
                        <img
                          className="rounded-circle"
                          alt=""
                          src={item.user_photo}
                        />
                      </div>
                      <div className="item">
                        <h5>{item.title} </h5>
                        <div className="credentials">
                          <span className="link-primary">
                            Attention to: {item.attention_to_username}
                          </span>
                          <span className="margin_left_30 text-muted">
                            {moment(item.created_at).format("lll")}
                          </span>
                        </div>
                      </div>
                      <div className="item text-end">
                        <Link
                          to={"/notebooks/" + item.id}
                          className="btn btn-primary rounded-circle"
                        >
                          <i class="fal fa-paperclip"></i>
                        </Link>
                      </div>
                    </div>
                  ))
                : "No Notebooks Here"}
            </div>
          </section>
        </div>
      </div>

      <Modal show={activityModal} onHide={closeActivityModal}>
        <Modal.Header closeButton>
          <Modal.Title>Recent Activities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="activity_card">
            <ul className="timeline">
              {activities.length > 0
                ? activities.map((item, index) => (
                    <li className="timeline-item">
                      <h5 className="fw-bold">
                        {item.user_name} has {item.activity_type}
                      </h5>
                      <p className="text-muted mb-3">
                        {moment(item.created_at).format("LLL")}{" "}
                        <Link to={"/leaves-actions/" + item.leave_id}>
                          Details
                        </Link>
                      </p>
                    </li>
                  ))
                : "No Activities found"}
            </ul>
          </section>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeActivityModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
