// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //#########Stage config##########
  staging: {
    appUrl: "http://hrms.dotedging.com",
    apiUrl: "https://hrmsapi.dotedging.com/public/api",
    staticUrl: "https://hrmsapi.dotedging.com/public/api",
    debug: true,
  },


  production: {
    appUrl: "http://hr.alnajah.com.my",
    apiUrl: "https://hr.alnajah.com.my/hrmsapi/public/api",
    staticUrl: "https://hr.alnajah.com.my/hrmsapi/public/api",
    debug: true,
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost:3000",
    apiUrl: "http://localhost/hrmsapi/public/api",
    staticUrl: "http://localhost/hrmsapi/public/api",
    debug: true,
  },
};

var finalConfig = config[configEnv];

export default finalConfig;
