import React, { useState } from "react";
import { Link } from "react-router-dom";
import api from "../../services/api";
import swal from "sweetalert";

export default function ImportList() {
  const [step, setStep] = useState(1);

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileSelect = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  const [errorMessage, setErrorMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", selectedFile);
    var response = await api.post("/settings-store", formData);
    if (response.status === 200 && response.data) {

      console.log(response.data);
      swal({
        title: "Upload Success",
        icon: "success",
      });
      window.location.reload(false);
    } else {
      setErrorMessage(response.data.errors.file);
    }
  };

  return (
    <div className="create_edit_page">
      <div className="create_page_heading">
        <div className="page_name">Import Employee</div>
        <div className="actions">
          <Link to="/" className="btn btn-danger rounded-circle">
            <i className="fal fa-times"></i>
          </Link>
        </div>
      </div>
      <div className="step-completion">
        <div className="step">
          <div className={step === 1 ? "icon active" : "icon"}>
            <i className="fal fa-question"></i>
          </div>
          <h5>Guide</h5>
        </div>
        <div className="step-divider"></div>
        <div className="step">
          <div className={step === 2 ? "icon active" : "icon"}>
            <i className="fal fa-paperclip"></i>
          </div>
          <h5>Import</h5>
        </div>
      </div>
      <hr />
      <br />

      {step === 1 && (
        <>
          <div className="row">
            <div className="col-lg-6 offset-lg-3 text-center">
              <ul>
                <li>
                  <h4>
                    <Link to="/how-to-import" className="text-primary">
                      1. Click here to read the details instructions
                    </Link>
                  </h4>
                </li>
                <li>
                  <h4>
                    <Link to="/how-to-import" className="text-primary">
                      2. Click here to download Excel Template
                    </Link>
                  </h4>
                </li>
                <li>
                  <h4>3. Enter your data in Excel Template</h4>
                </li>
                <li>
                  <h4>4. Select the completed Excel file to upload</h4>
                </li>
              </ul>
              <br />
              <div className="next_btns text-center">
                <button onClick={() => setStep(2)} className="btn btn-primary">
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <div className="row">
          <form onSubmit={handleSubmit}>
            <div className="col-lg-6 offset-lg-3 text-center">
              <label>Upload Excel File</label>
              <input
                onChange={handleFileSelect}
                className="form-control"
                type="file"
                placeholder="Upload Excel File"
              />
              {errorMessage && <div className="errorMsg">{errorMessage}</div>}
              <br />
              <div className="next_btns">
                <button type="submit" className="btn btn-primary">
                  IMPORT
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}
