import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { faEllipsisH, faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import api from "../../../services/api";
import Spinner from "../../../elements/Spinner";
import moment from "moment";
import Pagination from "../../../elements/Pagination";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";

export default function SalaryAdvanceAction(props) {
  const parms = useParams();
  const [activePage, setActivePage] = useState(1);

  // pagination for pending
  const [pagePending, setPagePending] = useState(1);
  const onPageChangePending = (page) => {
    setPagePending(page);
  };
  const [paginationDataPending, setPaginationDataPending] = useState([]);

  // pagination for all
  const [pageAll, setPageAll] = useState(1);
  const onPageChangeAll = (page) => {
    setPageAll(page);
  };
  const [paginationDataAll, setPaginationDataAll] = useState([]);

  // pagination for attendance

  const [employees, setEmployees] = useState([]);
  const getEmployees = async () => {
    setSpinner(true);
    var response = await api.post("/employees");
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };
  // hjghfjghfj
  const [leaveTab, setLeaveTab] = useState("pending_request");

  const [spinner, setSpinner] = useState();
  const [sideNav, setSideNav] = useState(false);
  const [salaryDetails, setSalaryDetails] = useState({});
  const openSideNav = async (id) => {
    setSpinner(true);
    var response = await api.post("/salary-advances-show", { id: id });
    if (response.status === 200 && response.data) {
      setSalaryDetails(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
    setSideNav(true);
  };
  const closeSideNav = () => {
    setSideNav(false);
  };

  const approveSalary = async (id) => {
    setSpinner(true);
    var response = await api.post("/salary-advances-approve", { id: id });
    if (response.status === 200 && response.data) {
      getSalaries();
      getPendingSalaries();
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const rejectSalary = async (id) => {
    setSpinner(true);
    var response = await api.post("/salary-advances-reject", { id: id });
    if (response.status === 200 && response.data) {
      getSalaries();
      getPendingSalaries();
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [sortOrder, setSortOrder] = useState("newest_to_oldest");

  const toggleSortOrder = () => {
    if (sortOrder === "newest_to_oldest") {
      setSortOrder("oldest_to_newest");
    } else {
      setSortOrder("newest_to_oldest");
    }
  };
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [filterData, setFilterData] = useState({
    filter_year: "",
    filter_month: "",
  });

  const handleChange = (event) => {
    setFilterData({ ...filterData, [event.target.name]: event.target.value });
  };

  const [salaries, setSalaries] = useState([]);
  const getSalaries = async () => {
    setSpinner(true);
    var response = await api.post("/salary-advances-all", {
      page: pageAll,
      perPage: 50,
      employee_id: selectedEmployee,
      filter_year: filterData.filter_year,
      filter_month: filterData.filter_month,
      sort_order: sortOrder,
    });

    if (response.status === 200 && response.data) {
      setSalaries(response.data.data);
      setPaginationDataAll(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };

  const [pendingSalaries, setPendingSalaries] = useState([]);
  const getPendingSalaries = async () => {
    setSpinner(true);
    var response = await api.post("/salary-advances-pending-all", {
      page: pagePending,
      perPage: 50,
      employee_id: selectedEmployee,
      filter_month: filterData.filter_month,
      filter_year: filterData.filter_year,
    });
    console.log(response.data);
    if (response.status === 200 && response.data) {
      setPendingSalaries(response.data.data);
      setPaginationDataPending(response.data.paginationData);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
  };
  // edit function

  const [openModal, setOpenModal] = useState(false);

  const openEditModal = async (id) => {
    setSpinner(true);
    var response = await api.post("/salary-advances-show", { id: id });
    if (response.status === 200 && response.data) {
      setFormData(response.data.data);
    } else {
      console.log(response.data);
    }
    setSpinner(false);
    setOpenModal(true);
  };
  const closeEditModal = () => {
    setOpenModal(false);
  };

  const [formData, setFormData] = useState({
    currency: "",
    amount: "",
    reason: "",
    status: "",
    id: "",
  });
  const [errors, setErrors] = useState({});
  const handleFormChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    var valid = true;
    var newErrors = {};

    if (!formData.amount) {
      newErrors = { ...newErrors, amount: "Amount is required" };
      valid = false;
    }
    if (!formData.currency) {
      newErrors = { ...newErrors, currency: "Currency is required" };
      valid = false;
    }

    if (!formData.reason) {
      newErrors = { ...newErrors, reason: "Reason is required" };
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      setSpinner(true);
      var response = await api.post("/salary-advances-update", formData);
      console.log(response.data);
      if (response.data.status == "success") {
        getSalaries();
        setOpenModal(false);
      } else {
        setErrors(response.data.errors);
      }

      setSpinner(false);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };

  useEffect(async () => {
    getCurrencies();
  }, []);

  useEffect(async () => {
    getEmployees();
  }, []);

  useEffect(async () => {
    getPendingSalaries();
  }, [pagePending, filterData, selectedEmployee]);

  useEffect(async () => {
    getSalaries();
  }, [pageAll, filterData, sortOrder, selectedEmployee]);

  useEffect(async () => {
    if (parms.id) {
      openSideNav(parms.id);
    }
  }, [parms]);

  const selectOptions = employees.map((employee) => ({
    value: employee.id,
    label: employee.short_name,
  }));

  const employeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption.value);
  };

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">
          Manage Salary Advance Applications (Actions)
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label>Short Name</label>
            <Select options={selectOptions} onChange={employeeChange} />
            
          </div>
          <div className="form-group">
            <label>By Month & Year</label>
            <div className="row">
              <div className="col-lg-6">
                <select
                  name="filter_year"
                  value={filterData.filter_year}
                  onChange={handleChange}
                  className="form-select"
                >
                  <option value="">Select Year</option>
                  {Array.from({ length: 12 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option value={String(year)} key={String(year)}>
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-lg-6">
                <select
                  name="filter_month"
                  onChange={handleChange}
                  value={filterData.filter_month}
                  className="form-select"
                >
                  <option value="">Select Month</option>
                  <option value="January">January</option>
                  <option value="February">February</option>
                  <option value="March">March</option>
                  <option value="April">April</option>
                  <option value="May">May</option>
                  <option value="June">June</option>
                  <option value="July">July</option>
                  <option value="August">August</option>
                  <option value="September">September</option>
                  <option value="October">October</option>
                  <option value="November">November</option>
                  <option value="December">December</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div className="row">
        <div className="col-lg-8">
          <div className="create_employee_tabs">
            <button
              className={
                leaveTab === "pending_request"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("pending_request")}
            >
              Pending Request
            </button>
            <button
              className={
                leaveTab === "manage_all"
                  ? "btn btn-default btn-info"
                  : "btn btn-default"
              }
              onClick={() => setLeaveTab("manage_all")}
            >
              Manage All
            </button>
          </div>
        </div>
      </div>

      {leaveTab === "pending_request" && (
        <>
          <div className="employee_lists">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Short Name</th>
                    <th>Team</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Reason</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pendingSalaries.length > 0
                    ? pendingSalaries.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item.date).format("LL")}</td>
                          <td>{item.employee_name}</td>
                          <td>{item.team}</td>
                          <td>{item.currency}</td>
                          <td>{item.amount}</td>
                          <td>{item.reason}</td>
                          <td>{item.status}</td>
                          <td>
                            <button
                              onClick={() => openSideNav(item.id)}
                              className="btn btn-success rounded-circle mr-10"
                            >
                              <i className="fal fa-eye"></i>
                            </button>

                            {props.rolePermission?.Employee?.approved_reject ? (
                              <>
                                <button
                                  onClick={() => approveSalary(item.id)}
                                  className="btn btn-success rounded-circle mr-10"
                                >
                                  <i class="fal fa-check"></i>
                                </button>
                                <button
                                  onClick={() => rejectSalary(item.id)}
                                  className="btn btn-danger rounded-circle "
                                >
                                  <i class="fal fa-times"></i>
                                </button>
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-center">
            <Pagination
              data={paginationDataPending}
              onPageChange={onPageChangePending}
            />
          </div>
        </>
      )}

      {leaveTab === "manage_all" && (
        <>
          <div className="employee_lists">
            <div className="table-responsive">
              <table class="table table-bordered">
                <thead className="bg-dark text-white">
                  <tr>
                    <th>#</th>
                    <th onClick={toggleSortOrder} style={{ cursor: "pointer" }}>
                      <i className="fa fa-sort"></i> Date
                    </th>
                    <th>Short Name</th>
                    <th>Team</th>
                    <th>Currency</th>
                    <th>Amount</th>
                    <th>Reason</th>
                    <th>Action Date</th>
                    <th>Status</th>
                    <th>Action By</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {salaries.length > 0
                    ? salaries.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{moment(item.date).format("LL")}</td>
                          <td>{item.employee_name}</td>
                          <td>{item.team}</td>
                          <td>{item.currency}</td>
                          <td>{item.amount}</td>
                          <td>{item.reason}</td>
                          <td>
                            {item.action_date
                              ? moment(item.action_date).format("LL")
                              : "N/A"}
                          </td>
                          <td>{item.status}</td>
                          <td>
                            {item.action_by_name ? item.action_by_name : "N/A"}
                          </td>
                          <td>
                            <button
                              onClick={() => openEditModal(item.id)}
                              className="btn btn-warning rounded-circle mr-10"
                            >
                              <i className="fal fa-pen"></i>
                            </button>

                            {props.rolePermission?.Employee?.approved_reject ? (
                              <>
                                {item.status === "Pending" ? (
                                  <>
                                    <button
                                      onClick={() => approveSalary(item.id)}
                                      className="btn btn-success rounded-circle mr-10"
                                    >
                                      <i class="fal fa-check"></i>
                                    </button>
                                    <button
                                      onClick={() => rejectSalary(item.id)}
                                      className="btn btn-danger rounded-circle "
                                    >
                                      <i class="fal fa-times"></i>
                                    </button>
                                  </>
                                ) : item.status === "Approved" ? (
                                  <>
                                    <button
                                      onClick={() => rejectSalary(item.id)}
                                      className="btn btn-danger rounded-circle "
                                    >
                                      <i class="fal fa-times"></i>
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      onClick={() => approveSalary(item.id)}
                                      className="btn btn-success rounded-circle mr-10"
                                    >
                                      <i class="fal fa-check"></i>
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              ""
                            )}
                          </td>
                        </tr>
                      ))
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </div>
          <div className="text-center">
            <Pagination
              data={paginationDataAll}
              onPageChange={onPageChangeAll}
            />
          </div>
        </>
      )}

      <div className="right_sideNav">
        <div className={sideNav ? "sidenav width_full" : "sidenav"}>
          <div className="create_page_heading">
            <div className="page_name user_area">
              <div className="item">
                <img
                  className="rounded-circle"
                  alt=""
                  src={salaryDetails.user_photo}
                />
              </div>
              <div className="credentials">
                <div className="link-primary">{salaryDetails.user_name}</div>
                <div className="text-muted">
                  {moment(salaryDetails.date).format("LL")}
                </div>
              </div>
            </div>
            <div className="actions">
              <Link
                onClick={closeSideNav}
                className="btn btn-danger rounded-circle"
              >
                <i className="fal fa-times"></i>
              </Link>
            </div>
          </div>
          <div className="details_announcement">
            <div className="row">
              <div className="col-lg-6">
                <h5>
                  Currency:{" "}
                  <span className="text-muted">{salaryDetails.currency}</span>
                </h5>
              </div>
              <div className="col-lg-6">
                <h5>
                  Requested Amount:{" "}
                  <span className="text-muted">{salaryDetails.amount}</span>
                </h5>
              </div>
            </div>
            <br />
            <br />
            <hr></hr>

            <p className="text-muted">{salaryDetails.reason}</p>
          </div>
        </div>
      </div>

      <Modal show={openModal} onHide={closeEditModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Salary Advance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Short Name:</label>
                  <input
                    type="text"
                    value={formData.user_name}
                    className="form-control"
                    readOnly
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Currency:</label>

                  <select
                    className="form-select"
                    onChange={handleFormChange}
                    name="currency"
                    value={formData.currency}
                  >
                    <option value="">Select Currency</option>
                    {currencies.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.code}
                      </option>
                    ))}
                  </select>

                  {errors.currency && (
                    <div className="errorMsg">{errors.currency}</div>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Request Amount:</label>
                  <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleFormChange}
                    className="form-control"
                    step=".01"
                  />

                  {errors.amount && (
                    <div className="errorMsg">{errors.amount}</div>
                  )}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label>Status:</label>

                  <select
                    className="form-select"
                    onChange={handleFormChange}
                    name="status"
                    value={formData.status}
                  >
                    <option value="">Select Status</option>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </select>

                  {errors.status && (
                    <div className="errorMsg">{errors.status}</div>
                  )}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label htmlFor="message">Reason:</label>
                  <textarea
                    placeholder="Write Reason"
                    className="form-control"
                    name="reason"
                    value={formData.reason}
                    onChange={handleFormChange}
                  ></textarea>
                  {errors.reason && (
                    <div className="errorMsg">{errors.reason}</div>
                  )}
                </div>
              </div>
            </div>

            <br />
            <div className="actions text-center">
              <button type="submit" className="publish_btn btn btn-success">
                Update
              </button>
              <br />
              <br />
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
