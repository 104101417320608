import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Modal, Button, Badge } from "react-bootstrap";
import api from "services/api";
import swal from "sweetalert";
import Spinner from "../../elements/Spinner";

export default function Profile(props) {
  const [spinner, setSpinner] = useState(false);
  const [employeeTab, setEmployeeTab] = useState("personal_info");

  // retrive data
  const getProfile = async () => {
    setSpinner(true);
    var response = await api.get("/profile");
    if (response.status === 200 && response.data) {
      setFormDataSet(response.data.data);
    }
    setSpinner(false);
  };

  const [positions, setPositions] = useState([]);
  const getPositions = async () => {
    var response = await api.get("/positions");
    if (response.status === 200 && response.data) {
      setPositions(response.data);
    }
  };
  const [roles, setRoles] = useState([]);
  const getRoles = async () => {
    var response = await api.get("/roles");
    if (response.status === 200 && response.data) {
      setRoles(response.data);
    }
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const [employees, setEmployees] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(0);
  const getEmployees = async () => {
    var response = await api.post("/employees");
    if (response.status === 200 && response.data) {
      setEmployees(response.data.data);
      setEmployeeCount(zeroPad(response.data?.data.length + 1, 3));
    }
  };

  const [teams, setTeams] = useState([]);
  const getTeams = async () => {
    var response = await api.post("/teams");
    if (response.status === 200 && response.data) {
      setTeams(response.data.data);
    }
  };

  const [shifts, setShifts] = useState([]);
  const getShifts = async () => {
    var response = await api.get("/shifts");
    if (response.status === 200 && response.data) {
      setShifts(response.data);
    }
  };

  const [countries, setCountries] = useState([]);
  const getCountries = async () => {
    var response = await api.get("/countries");
    if (response.status === 200 && response.data) {
      setCountries(response.data);
    }
  };

  const [currencies, setCurrencies] = useState([]);
  const getCurrencies = async () => {
    var response = await api.get("/currencies");
    if (response.status === 200 && response.data) {
      setCurrencies(response.data);
    }
  };

  useEffect(async () => {
    getPositions();
    getRoles();
    getEmployees();
    getTeams();
    getShifts();
    getCountries();
    getCurrencies();
    getProfile();
  }, []);

  const [formDataSet, setFormDataSet] = useState({
    // personal_info
    full_name: "",
    short_name: "",
    login_id: "",
    password: "",
    nationality: "",
    employee_id: "",
    gender: "",
    date_of_birth: "",
    race: "",
    religion: "",
    marital_status: "",
    ic_num: "",
    ppt_expired: "",

    // contact info
    phone_code: "",
    emergency_person: "",
    emergency_contact: "",
    email: "",
    mobile_number: "",
    house_number: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
    emergency_relation: "",
    emergency_phone_code: "",

    // emploment_info

    date_joined: "",

    role_permission: "",
    report_to: "",
    position: "",
    position_grade: "",
    team: "",
    working_hours: "",
    work_location: "",
    branch_office: "",
    job_status: "",
    job_type: "",

    visa_no: "",
    visa_issue_date: "",
    visa_expired_date: "",
    referral: "",
    own_employee: "",
    referral_name: "",
    referral_by_team: "",
    referral_contact: "",

    // leave data

    rest_day: "",
    annual_leave: "",
    al_start_from: "",
    al_expired_on: "",
    eligible_start_from: "",
    eligible_expired_on: "",
    flight_allowance: "",
    flight_allowance_currency: "",
    meals_allowance: "",
    meals_allowance_currency: "",
    medical_allowance: "",
    medical_allowance_currency: "",
    medical_eligible_start_from: "",
    medical_eligible_expired_on: "",

    // finance data

    currency: "",
    beneficiary: "",
    bank: "",
    account_number: "",
    basic_salary: "",
    kpi_bonus: "",
  });

  // update profile

  const [file, setFile] = useState(null);

  const handleFileChange = async (event) => {
    setFile(event.target.files[0]);
    const data = new FormData();
    data.append("photo", event.target.files[0]);
    var response = await api.post("/update-profile-picture", data);
    if (response.status === 200 && response.data) {
      getProfile();
      swal({
        title: "Updated Success",
        icon: "success",
      });
    }
  };

  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!newPassword || !confirmNewPassword) {
      setErrorMessage("Please fill in all fields.");
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New password and confirm password do not match.");
      return;
    }

    const formData = new FormData();
    formData.append("new_password", newPassword);
    formData.append("confirm_password", confirmNewPassword);

    var response = await api.post("/profile-update", formData);
    if (response.status === 200 && response.data) {
      setNewPassword("");
      setConfirmNewPassword("");
      setErrorMessage("");
      swal({
        title: "Updated Success",
        icon: "success",
      });
    } else {
      setErrorMessage(response.data.errors);
    }
  };

  return (
    <div className="create_edit_page">
      {spinner && <Spinner />}
      <div className="create_page_heading">
        <div className="page_name">My Profile</div>
        <div className="actions">
          <button
            onClick={handleSubmit}
            type="supmit"
            className="publish_btn btn btn-success"
          >
            Update
          </button>
          <Link to="/" className="btn btn-danger rounded-circle">
            <i className="fal fa-times"></i>
          </Link>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="create_employee_tabs">
          <Link
            className={
              employeeTab === "personal_info"
                ? "btn btn-default btn-info"
                : "btn btn-default"
            }
            onClick={() => setEmployeeTab("personal_info")}
          >
            Personal Information
          </Link>
          <Link
            className={
              employeeTab === "contact"
                ? "btn btn-default btn-info"
                : "btn btn-default"
            }
            onClick={() => setEmployeeTab("contact")}
          >
            Contact
          </Link>
          <Link
            className={
              employeeTab === "employement"
                ? "btn btn-default btn-info"
                : "btn btn-default"
            }
            onClick={() => setEmployeeTab("employement")}
          >
            Employment
          </Link>
          <Link
            className={
              employeeTab === "leave"
                ? "btn btn-default btn-info"
                : "btn btn-default"
            }
            onClick={() => setEmployeeTab("leave")}
          >
            Leave & Allowance
          </Link>
          <Link
            className={
              employeeTab === "finance"
                ? "btn btn-default btn-info"
                : "btn btn-default"
            }
            onClick={() => setEmployeeTab("finance")}
          >
            Finance
          </Link>
        </div>
        <hr></hr>
        <br />

        {employeeTab === "personal_info" && (
          <div className="personal_data">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Photo</label>
                  <br />
                  <label for="uploadPhoto">
                    <div className="previw_upload_image">
                      <img
                        style={{
                          height: "150px",
                          width: "150px",
                          border: "2px solid red",
                        }}
                        src={formDataSet.profile_picture}
                        alt="Preview"
                        className="rounded-circle"
                      />

                      <i className="fas fa-camera"></i>
                    </div>
                  </label>
                  <br />
                  <input
                    type="file"
                    hidden
                    id="uploadPhoto"
                    name="employeePhoto"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <label>New Password:</label>
                <input
                  type="password"
                  value={newPassword}
                  className="form-control"
                  onChange={(event) => setNewPassword(event.target.value)}
                />
                <br />

                <label>Confirm New Password:</label>
                <input
                  type="password"
                  value={confirmNewPassword}
                  className="form-control"
                  onChange={(event) =>
                    setConfirmNewPassword(event.target.value)
                  }
                />
                {errorMessage && <div className="errorMsg">{errorMessage}</div>}
              </div>
            </div>

            <br />
            <hr />
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Full Name <sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="full_name"
                    value={formDataSet.full_name}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Short Name <sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="short_name"
                    value={formDataSet.short_name}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    User ID <sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="login_id"
                    value={formDataSet.login_id}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Password <sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="password"
                    className="form-control"
                    name="password"
                    value={formDataSet.password}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Nationality<sup>*</sup>
                  </label>
                  <select
                    disabled
                    className="form-select"
                    name="nationality"
                    value={formDataSet.nationality}
                  >
                    <option value="">Select Nationality</option>
                    {countries.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.nicename}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Employee ID<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="employee_id"
                    value={formDataSet.employee_id}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Gender <sup>*</sup>
                  </label>
                  <select
                    disabled
                    className="form-select"
                    name="gender"
                    value={formDataSet.gender}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Date of Birth <sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="date_of_birth"
                    value={formDataSet.date_of_birth}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Race<sup>*</sup>
                  </label>
                  <select
                    disabled
                    className="form-select"
                    name="race"
                    value={formDataSet.race}
                  >
                    <option value="">Select Race</option>
                    <option value="Chinese">Chinese</option>
                    <option value="India">India</option>
                    <option value="Malay">Malay</option>
                    <option value="Filipino">Filipino</option>
                    <option value="Indonesian">Indonesian</option>
                    <option value="Cambodia">Cambodia</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Religion<sup>*</sup>
                  </label>
                  <select
                    disabled
                    className="form-select"
                    name="religion"
                    value={formDataSet.religion}
                  >
                    <option value="">Select Religion</option>
                    <option value="Islam">Islam</option>
                    <option value="Hindu">Hindu</option>
                    <option value="Christian">Christian</option>
                    <option value="Buddhist">Buddhist</option>
                    <option value="Others">Others</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Marital Status<sup>*</sup>
                  </label>
                  <select
                    disabled
                    className="form-select"
                    name="marital_status"
                    value={formDataSet.marital_status}
                  >
                    <option value="">Select Marital Status</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                  </select>
                </div>
              </div>
            </div>
            <br />
            <hr></hr>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>IC Num</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="ic_num"
                    value={formDataSet.ic_num}
                    placeholder="Ex. 901022-14-5166"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>IC Copy</label>
                  <input
                    disabled
                    type="file"
                    className="form-control"
                    name="icCopy"
                    value={formDataSet.icCopy}
                  />
                  {formDataSet.ic_copy ? (
                    <a target="blank" href={formDataSet.ic_copy_file}>
                      <Badge>{formDataSet.ic_copy}</Badge>
                    </a>
                  ) : (
                    ""
                  )}

                  {/*ToDo: Show image after Choose File*/}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Passport Expired</label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="ppt_expired"
                    value={formDataSet.ppt_expired}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>PPT Copy</label>
                  <input
                    disabled
                    type="file"
                    className="form-control"
                    name="pptCopy"
                    value={formDataSet.pptCopy}
                  />
                  {formDataSet.ppt_copy ? (
                    <a target="blank" href={formDataSet.ppt_copy_file}>
                      <Badge>{formDataSet.ppt_copy}</Badge>
                    </a>
                  ) : (
                    ""
                  )}

                  {/*ToDo: Show image after Choose File*/}
                </div>
              </div>
            </div>
          </div>
        )}
        {employeeTab === "contact" && (
          <div className="contact_data">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Mobile Number<sup>*</sup>
                  </label>
                  <div className="d-flex">
                    <div>
                      <select
                        disabled
                        style={{ width: "200px", marginRight: "10px" }}
                        className="form-select"
                        name="phone_code"
                        value={formDataSet.phone_code}
                      >
                        <option value="">Phone Code</option>
                        {countries.map((item, index) => (
                          <option key={index} value={item.code}>
                            {item.nicename} +{item.phonecode}
                          </option>
                        ))}
                      </select>
                    </div>
                    <input
                      disabled
                      type="tel"
                      className="form-control"
                      name="mobile_number"
                      value={formDataSet.mobile_number}
                      placeholder="Number"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>House Number</label>
                  <input
                    disabled
                    type="tel"
                    className="form-control"
                    name="house_number"
                    value={formDataSet.house_number}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    disabled
                    type="email"
                    className="form-control"
                    name="email"
                    value={formDataSet.email}
                  />
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <label>Address</label>
                  <textarea
                    disabled
                    className="form-control"
                    name="address"
                    value={formDataSet.address}
                    placeholder="Address"
                  ></textarea>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>City</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="city"
                    value={formDataSet.city}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Postcode</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="postcode"
                    value={formDataSet.postcode}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Country</label>
                  <select
                    disabled
                    className="form-select"
                    name="country"
                    value={formDataSet.country}
                  >
                    <option value="">Select Country</option>
                    {countries.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.nicename}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <br />
            <br />
            <h3>Emergency Contact</h3>
            <hr></hr>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Name</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="emergency_person"
                    value={formDataSet.emergency_person}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Emergency relation</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="emergency_relation"
                    value={formDataSet.emergency_relation}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Mobile Number</label>

                  <div className="d-flex">
                    <select
                      disabled
                      style={{ width: "200px", marginRight: "10px" }}
                      className="form-select"
                      name="emergency_phone_code"
                      value={formDataSet.emergency_phone_code}
                    >
                      <option value="">Phone Code</option>
                      {countries.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.nicename} +{item.phonecode}
                        </option>
                      ))}
                    </select>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      name="emergency_contact"
                      value={formDataSet.emergency_contact}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {employeeTab === "employement" && (
          <div className="contact_data">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Date Joined<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="date_joined"
                    value={formDataSet.date_joined}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Offer Letter</label>
                  <input
                    disabled
                    type="file"
                    className="form-control"
                    name="offerLetter"
                    value={formDataSet.offerLetter}
                  />
                  {formDataSet.offer_letter ? (
                    <a target="blank" href={formDataSet.offer_letter_file}>
                      <Badge>{formDataSet.offer_letter}</Badge>
                    </a>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Role Permission<sup>*</sup>
                  </label>
                  <select
                    disabled
                    name="role_permission"
                    value={formDataSet.role_permission}
                    className="form-select"
                  >
                    <option value="">Select Role Permission</option>
                    {roles.length > 0 ? (
                      roles.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.title}
                        </option>
                      ))
                    ) : (
                      <option value="0">No roles found</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Report to<sup>*</sup>
                  </label>
                  <select
                    disabled
                    name="report_to"
                    value={formDataSet.report_to}
                    className="form-select"
                  >
                    <option value="">Select Report to</option>
                    {employees.length > 0 ? (
                      employees.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.full_name}
                        </option>
                      ))
                    ) : (
                      <option value="0">No employees found</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Position<sup>*</sup>
                  </label>

                  <select
                    disabled
                    name="position"
                    value={formDataSet.position}
                    className="form-select"
                  >
                    <option value="">Select Position</option>
                    {positions.length > 0 ? (
                      positions.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.title}
                        </option>
                      ))
                    ) : (
                      <option value="0">No positions found</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Position Grade</label>
                  <select
                    disabled
                    name="position_grade"
                    value={formDataSet.position_grade}
                    className="form-select"
                  >
                    <option value="">Select Position Grade</option>
                    <option value="A3 Sales Manager">A3 Sales Manager</option>
                    <option value="M3 Sales Executive">
                      M3 Sales Executive
                    </option>
                    <option value="F1 Officer">F1 Officer</option>
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    By Team<sup>*</sup>
                  </label>
                  <select
                    disabled
                    name="team"
                    value={formDataSet.team}
                    className="form-select"
                  >
                    <option value="">Select by team</option>
                    {teams.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>

                  {/*ToDo: Make option to create team from here.*/}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Working Hours <sup>*</sup>
                  </label>

                  <select
                    disabled
                    name="working_hours"
                    value={formDataSet.working_hours}
                    className="form-select"
                  >
                    <option value="">Select working hours</option>
                    {shifts.length > 0 ? (
                      shifts.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.title}, {item.from_time} - {item.to_time}
                        </option>
                      ))
                    ) : (
                      <option value="0">No shifts found</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Working Location<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="work_location"
                    value={formDataSet.work_location}
                  />

                  {/*ToDo: Set minimum character */}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Branch Office<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="branch_office"
                    value={formDataSet.branch_office}
                  />

                  {/*ToDo: Set minimum character */}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Job Status<sup>*</sup>
                  </label>
                  <select
                    disabled
                    name="job_status"
                    value={formDataSet.job_status}
                    className="form-select"
                  >
                    <option value="">Select Job Status</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Active">Active</option>
                  </select>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Job Type<sup>*</sup>
                  </label>
                  <select
                    disabled
                    name="job_type"
                    value={formDataSet.job_type}
                    className="form-select"
                  >
                    <option value="">Select Job Type</option>
                    <option value="Permanent">Permanent</option>
                    <option value="Contract">Contract</option>
                    <option value="Probation">Probation</option>
                  </select>
                </div>
              </div>
            </div>
            <br />
            <hr />

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Working Permit</label>

                  <input
                    disabled
                    type="file"
                    className="form-control"
                    name="workingPermit"
                    value={formDataSet.workingPermit}
                  />
                  {formDataSet.work_permit ? (
                    <a target="blank" href={formDataSet.work_permit_file}>
                      <Badge>{formDataSet.work_permit}</Badge>
                    </a>
                  ) : (
                    ""
                  )}
                  {/* neeed to apply file */}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Visa No</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="visa_no"
                    value={formDataSet.visa_no}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Visa Issue Date</label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="visa_issue_date"
                    value={formDataSet.visa_issue_date}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Visa Expired Date</label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="visa_expired_date"
                    value={formDataSet.visa_expired_date}
                  />
                </div>
              </div>
            </div>
            <br />
            <br />
            <h3>Referral</h3>
            <hr></hr>
            <div className="row">
              <div className="col-lg-7">
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      disabled
                      type="checkbox"
                      class="custom-control-input"
                      id="referral"
                      name="referral"
                      checked={formDataSet.referral ? 1 : 0}
                    />
                    <label class="custom-control-label" for="referral">
                      With / Without Referral<sup>*</sup>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="form-group">
                  <div className="custom-control custom-switch">
                    <input
                      disabled
                      type="checkbox"
                      class="custom-control-input"
                      id="own_employee"
                      name="own_employee"
                      checked={formDataSet.own_employee ? 1 : 0}
                    />
                    <label class="custom-control-label" for="own_employee">
                      Nexmax Employee<sup>*</sup>
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="form-group">
                  <label>
                    Name<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="referral_name"
                    value={formDataSet.referral_name}
                  />
                </div>
              </div>

              <div className="col-lg-7">
                <div className="form-group">
                  <label>
                    By Team<sup>*</sup>
                  </label>
                  <select
                    disabled
                    name="referral_by_team"
                    value={formDataSet.referral_by_team}
                    className="form-select"
                  >
                    <option value="">Select by team</option>
                    {teams.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>

                  {/*ToDo: Make option to create team from here.*/}
                </div>
              </div>

              <div className="col-lg-7">
                <div className="form-group">
                  <label>
                    Contact Number<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="referral_contact"
                    value={formDataSet.referral_contact}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {employeeTab === "leave" && (
          <div className="contact_data">
            <div className="row">
              <div className="col-lg-8">
                <div className="form-group">
                  <label>
                    Rest Day<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="rest_day"
                    value={formDataSet.rest_day}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    Annual Leave (AL)<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="annual_leave"
                    value={formDataSet.annual_leave}
                  />
                </div>
              </div>

              <div className="col-lg-6"></div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    AL Start From<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="al_start_from"
                    value={formDataSet.al_start_from}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    AL Expired On<sup>*</sup>
                  </label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="al_expired_on"
                    value={formDataSet.al_expired_on}
                  />
                </div>
              </div>

              <div className="col-lg-8">
                <div className="form-group">
                  <label>
                    <b>Flight Allowance</b>
                  </label>

                  <div className="d-flex">
                    <select
                      disabled
                      style={{ width: "200px", marginRight: "10px" }}
                      className="form-select"
                      name="flight_allowance_currency"
                      value={formDataSet.flight_allowance_currency}
                    >
                      <option value="">Select Currency</option>
                      {currencies.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.code} ({item.name})
                        </option>
                      ))}
                    </select>

                    <input
                      disabled
                      type="text"
                      className="form-control"
                      name="flight_allowance"
                      value={formDataSet.flight_allowance}
                      placeholder="3900.00"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Eligible Start From</label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="eligible_start_from"
                    value={formDataSet.eligible_start_from}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Eligible Expired On</label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="eligible_expired_on"
                    value={formDataSet.eligible_expired_on}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>
                    <b>Meals Allowance</b>
                  </label>

                  <div className="d-flex">
                    <select
                      disabled
                      style={{ width: "200px", marginRight: "10px" }}
                      className="form-select"
                      name="meals_allowance_currency"
                      value={formDataSet.meals_allowance_currency}
                    >
                      <option value="">Currency</option>
                      {currencies.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.code} ({item.name})
                        </option>
                      ))}
                    </select>

                    <input
                      disabled
                      type="text"
                      className="form-control"
                      name="meals_allowance"
                      value={formDataSet.meals_allowance}
                      placeholder="3900.00"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="form-group">
                  <label>
                    <b>Medical Allowance</b>
                  </label>

                  <div className="d-flex">
                    <select
                      disabled
                      style={{ width: "200px", marginRight: "10px" }}
                      className="form-select"
                      name="medical_allowance_currency"
                      value={formDataSet.medical_allowance_currency}
                    >
                      <option value="">Currency</option>
                      {currencies.map((item, index) => (
                        <option key={index} value={item.code}>
                          {item.code} ({item.name})
                        </option>
                      ))}
                    </select>

                    <input
                      disabled
                      type="text"
                      className="form-control"
                      name="medical_allowance"
                      value={formDataSet.medical_allowance}
                      placeholder="3900.00"
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Eligible Start From</label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="medical_eligible_start_from"
                    value={formDataSet.medical_eligible_start_from}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Eligible Expired On</label>
                  <input
                    disabled
                    type="date"
                    className="form-control"
                    name="medical_eligible_expired_on"
                    value={formDataSet.medical_eligible_expired_on}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {employeeTab === "finance" && (
          <div className="contact_data">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Base Currency</label>
                  <select
                    disabled
                    name="currency"
                    value={formDataSet.currency}
                    className="form-select"
                  >
                    <option value="">Select Currency</option>
                    {currencies.map((item, index) => (
                      <option key={index} value={item.code}>
                        {item.code} ({item.name})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Beneficiary Name</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="beneficiary"
                    value={formDataSet.beneficiary}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Bank</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="bank"
                    value={formDataSet.bank}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Account Number</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="account_number"
                    value={formDataSet.account_number}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>Basic Salary</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="basic_salary"
                    value={formDataSet.basic_salary}
                  />
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label>KPI Bonus</label>
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    name="kpi_bonus"
                    value={formDataSet.kpi_bonus}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
